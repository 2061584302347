import { Injectable } from '@angular/core';
import { Outlet, Voucher } from '../models/interfaces';
import { DatabaseService } from './database.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserChangeService } from './user-change.service';
import { User, sendEmailVerification } from 'firebase/auth';
import { CloudfunctriggersService } from './cloudfunctriggers.service';
// import { AlertService } from './alert.service';
// import { ToastServiceService } from './toast-service.service';
// import { TranslationService } from './translation.service';
// import { AuthService } from './auth.service';
// import { ModalController } from '@ionic/angular';
// import { LandingPage } from '../pages/landing/landing.page';


@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  userVouchers$:BehaviorSubject<Voucher[]> = new BehaviorSubject([])
  private _vouchers:Voucher[] = []
  get vouchers(){
    return this._vouchers
  }
  private _user:User
  private vouchersSubscription:Subscription
  constructor(
    private database: DatabaseService,
    private userChangeService:UserChangeService,
    private cloudFunctionTriggers:CloudfunctriggersService
    // private alertService:AlertService,
    // private modalCtrl:ModalController,
    // private ts:TranslationService,
    // private authService:AuthService,
    // private toasts:ToastServiceService
  ) { 
    this.userChangeService.user$.subscribe((user) => {
      if(!user) return;
      this._user = user
      this.listenToUserVouchers(true);
    });
  }

  async listenToUserVouchers(force=false){
    if(force && this.vouchersSubscription){
      this.vouchersSubscription.unsubscribe();
    }
    if(!this.vouchersSubscription||this.vouchersSubscription.closed){
      this.database.listenToUserVouchers(this.userVouchers$);
      this.vouchersSubscription = this.userVouchers$.subscribe((vouchers)=>{
        this._vouchers = vouchers
      })
    }else{
      console.log('Already listening. subscribe to the active subject instead');
    }
  }

  claimVoucher(voucher_id:string){
      return this.cloudFunctionTriggers.claimVoucher(voucher_id);
  }

  async redeemVoucher(voucher: Voucher,lat:number,lng:number,outlet:Outlet){
    let voucherRedeemed = false
    let userToCheck = this._user

    // This needs to be uncommented at some point. currently there is a problem when trying to link a user.
    // also once the popup has shown but email selected was already in use - the popup woint show again. frustrating...
    // if(this._user.isAnonymous || !this._user.emailVerified){
    //   await this._user.reload();
    //   userToCheck = this.authService.giveCurrentUser()
    // }

    // if(userToCheck.isAnonymous){
    //   const modal = await this.modalCtrl.create({
    //     component: LandingPage,
    //     mode: 'ios',
    //     componentProps:{
    //       title:'Log in te redeem voucher.'
    //     },
    //     initialBreakpoint: 0.7,
    //     breakpoints: [0, 0.7],
    //     handle: false,
    //   });
    //   await modal.present();
    //   return voucherRedeemed
    // }

    // if (!userToCheck.isAnonymous && !userToCheck.emailVerified) {
    //   await this.alertService.yumDealzAlertWithCustomButtons(
    //     '',
    //     `To redeem your voucher please verify your email by following the verification link sent to ${this._user.email || 'your email'}`,
    //     [
    //       {
    //         text: this.ts.getLocalizedValue('DEAL_LANE_CARD_COMPONENT.RESEND_LINK'),
    //         handler: () => {
    //           sendEmailVerification(this._user).then(async () => {
    //             await this.toasts.showToast(`Link sent to ${this._user.email || 'your email'}`, '')
    //           }).catch(async () => {
    //             await this.toasts.showToast(`Could not send link to ${this._user.email || 'your email'}`, '')
    //           })
    //         }
    //       },
    //       {
    //         text: this.ts.getLocalizedValue('DEAL_LANE_CARD_COMPONENT.EDIT_EMAIL'),
    //         handler: async () => {
    //           await this.authService.updateUserEmailAlert();
    //         }
    //       }
    //     ],
    //     true
    //   )
    //   return voucherRedeemed
    // }
  
    let date = new Date();
    let thisDay = date.getUTCDate();
    let thisYear = date.getUTCFullYear();
    let thisMonth = date.getUTCMonth();
    let utc_end_of_day_unix = Date.UTC(thisYear,thisMonth,thisDay,23,59,59,999);
    await this.database.redeemVoucher(voucher,utc_end_of_day_unix,lat,lng,outlet)
    voucherRedeemed = true;
    return voucherRedeemed
  };

}
