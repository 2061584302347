<ion-badge color="secondary" mode="ios"> 
  {{voucher.yum_points_cost}}
  <ion-icon name="ribbon"></ion-icon>
</ion-badge>
<ion-card mode="ios" class="deal-card" [style.height]="cardHeight">

    <ion-grid fixed id="main-grid">
      <ion-row id="main-row">
        <ion-col id="main-col">
          <div id="img-container" class="fade-in">
            <img  
            [style.height]="cardHeight"
            id="background-image"
            [defaultImage]="assetLinksService.noImage" 
            [lazyLoad]="assetLinksService.voucherReward" 
            [errorImage]="assetLinksService.noImage"
            onerror="this.src='assets/favicon.png'">
          </div>
        </ion-col>
      </ion-row>
      <div id="text-container">
        <ion-row>
          <ion-col>
          <p id="reward"> {{voucher.name}}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col >
            <p id="description"> {{voucher.description}}</p>
          </ion-col>
        </ion-row>

        <ion-fab edge="true" vertical="top" horizontal="end" shape="round" slot="fixed">

            <ion-fab-button *ngIf="!isButtonLoading && userService.getUser().yum_points < voucher.yum_points_cost"  color="dark" >
              <ion-icon color="light" name="lock-closed"></ion-icon>
            </ion-fab-button>


            <ion-fab-button *ngIf="!isButtonLoading && userService.getUser().yum_points >= voucher.yum_points_cost" (click)="askToBuyVoucher()" color="primary" >
              <ion-icon color="light" name="ribbon"></ion-icon>
            </ion-fab-button>
    
    
            <ion-fab-button *ngIf="isButtonLoading;"  color="primary">
              <ion-spinner name="bubbles"></ion-spinner>
             </ion-fab-button>
        </ion-fab>
      </div>
    </ion-grid>
</ion-card>

