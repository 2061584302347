import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Outlet, Shop, Story } from 'src/app/models/interfaces';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { OutletPage } from 'src/app/pages/outlet/outlet.page';
import { DateTimeService } from 'src/app/services/date-time.service';
import { OutletDealzService } from 'src/app/services/outlet-dealz.service';
import { StoryService } from 'src/app/services/story.service';

@Component({
  selector: 'app-story-avatar',
  templateUrl: './story-avatar.component.html',
  styleUrls: ['./story-avatar.component.scss'],
})
export class StoryAvatarComponent  implements OnInit {
  @Input() shop:Shop
  @Input() stories:Story[];
  viewed=false
  constructor(
    private modalCtrl:ModalController,
    public assetLinksService:AssetlinksService,
  ) { }

  ngOnInit() {    
  }

  async viewAction(){
    try {
      const modal = await this.modalCtrl.create({
        component: StoryViewerComponent,
        initialBreakpoint: 1,
        breakpoints: [0,1],
        handle:false,
        mode:'ios',
        componentProps:{
          shop:this.shop,
          stories:this.stories
        }
      });
      await modal.present();
      modal.onDidDismiss().then(shop=>{
        // display as now viewed
        this.viewed = true;
      })
    } catch (error) {
      console.log(error);
    }
  }

}
