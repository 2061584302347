import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import { Location, YumDealzUser } from '../models/interfaces';
import { DatabaseService } from './database.service';
import { GisService } from './gis.service';
import { UserChangeService } from './user-change.service';
import { DocumentData } from 'firebase/firestore';


@Injectable({
  providedIn: 'root'
})
export class YumdealzUserService {
  user$: BehaviorSubject<YumDealzUser> = new BehaviorSubject<YumDealzUser>(null);
  private user:YumDealzUser
  constructor(
    private dbService:DatabaseService,
    private gis:GisService,
    private userChangeService:UserChangeService
  ) {
    this.userChangeService.user$.subscribe(async (userId)=>{
      if(userId){
        await this.refreshUser()
      }
    })
   }


  editUser(data:DocumentData){
    return this.dbService.editUser(data)
  }

  async refreshUser(){
     this.user = await this.dbService.refreshUser()
     this.user$.next(this.user)
     return this.user
  }

  async updateUserLastSearchLocation(locationData:Location){
      const locationDataClone = {...locationData};
      locationDataClone.readableAddress = locationDataClone.readableAddress.includes('(Previous session)') ? locationDataClone.readableAddress : `(Previous session) ${locationDataClone.readableAddress }`
      const data ={
        lastSearchLocation : locationDataClone,
        notificationArea:{
          geohash:this.makeGeoHashFromLatLng({lat:locationDataClone.lat,lng:locationDataClone.lng}),
          radius:locationDataClone.radius,
          lat:locationDataClone.lat,
          lng:locationDataClone.lng
        }
      }
      this.editUser(data).then(async()=>{
        await this.refreshUser()
      }).catch((err)=>{
        console.log(err);
      })
  }

  getUser(){
      return this.user
  }

  private makeGeoHashFromLatLng(locationData:{
    lat:number,
    lng:number}){
      return this.gis.makeGeoHashFromLatLng(locationData)
    }

  handleError(error){
    console.log(error);
  }
}
