import { Injectable } from '@angular/core';
import {
  Auth,
  deleteUser,
  EmailAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updateProfile,
  sendEmailVerification
} from 'firebase/auth';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { PreferencesService } from './preferences.service';
import { DatabaseService } from './database.service';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FirebaseService } from './firebase.service';
import { PushNotificationsService } from './pushnotifications.service';
import { ToastServiceService } from './toast-service.service';
import { YumdealzUserService } from './yumdealz-user.service';
import { LoadingService } from './loading.service';
import { AlertController } from '@ionic/angular';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isUserNew: boolean = true;
  private firebaseAuth: Auth
  constructor(
    private firebaseService: FirebaseService,
    private alertService: AlertService,
    private alertController: AlertController,
    private preferences: PreferencesService,
    private dbService: DatabaseService,
    private toasts: ToastServiceService,
    private notificationService: PushNotificationsService,
    private loadingService: LoadingService,
    private yumdealzUserService: YumdealzUserService,
    private ts: TranslationService,
  ) {
    this.firebaseAuth = this.firebaseService.getAuth()
  }

  async guestSignIn() {
    return await signInAnonymously(this.firebaseAuth)
  }

  async googleSignIn() {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle();
    // 2. Sign in on the web layer using the id token
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    const googleUser = await signInWithCredential(this.firebaseAuth, credential);


    // const provider =  new GoogleAuthProvider();
    // const googleUser = await signInWithPopup(this.firebaseAuth, provider)
    //const credential = GoogleAuthProvider.credentialFromResult(googleUser); // not sure what to do with this
    return googleUser
  }

  async appleSignIn() {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    const appleUser = await signInWithCredential(this.firebaseAuth, credential);
    return appleUser
  }

  async emailSignIn(email: string, pw: string) {
    return await signInWithEmailAndPassword(this.firebaseAuth, email, pw)
  }

  async updateUserEmail(newEmail) {
    await updateEmail(this.firebaseAuth.currentUser, newEmail),
    await sendEmailVerification(this.firebaseAuth.currentUser);
    await this.dbService.initUser(this.firebaseAuth.currentUser);
    await this.toasts.showToast(
      this.ts.getLocalizedValue('AUTH_SERVICE.REMEMBER_TO_RELOAD_APP_AFTER_VERIFYING_NEW_EMAIL'),
      this.ts.getLocalizedValue('AUTH_SERVICE.NEW_LINK_SENT_TO') + ' ' + newEmail
    )
  }

  async updateUserEmailAlert() {
    const alert = await this.alertController.create({
      message: this.ts.getLocalizedValue('ERRORS.PLEASE_SUPPLY_NEW_EMAIL_ADDRESS'),
      // cssClass:`custom-alert-shape`,
      //   mode:'ios',
      inputs: [{
        type: 'text',
      }],
      buttons: [{
        text: this.ts.getLocalizedValue('AUTH_SERVICE.CANCEL'),
        role: 'cancel'
      },
      {
        text: this.ts.getLocalizedValue('AUTH_SERVICE.ACCEPT'),
        handler: async (input) => {
          await this.loadingService.showOrContinueShowingLoading()
          try {
            await this.updateUserEmail(input[0])
            await this.loadingService.hideLoading()
          } catch (error) {
            console.log(error);
            await this.loadingService.hideLoading()
            await this.toasts.showErrorToast(error.message)
          }
        }
      }]
    });

    await alert.present();

  }

  isAnonUser() {
    return this.firebaseAuth.currentUser.isAnonymous
  }

  giveFirebaseAuth() {
    return this.firebaseAuth
  }

  giveCurrentUser() {
    return this.firebaseAuth.currentUser
  }

  async deleteUser() {
    await this.dbService.deleteUser();
    await this.notificationService.deleteToken();
    await deleteUser(this.firebaseAuth.currentUser);
    await this.guestSignIn();
  }

  async resetPw(email: string) {
    await sendPasswordResetEmail(this.firebaseAuth, email);
  }

  async signOut() {
    await this.loadingService.showOrContinueShowingLoading();
    await this.notificationService.deleteToken().then(async () => {
      // 1. Sign out on the native layer
      await FirebaseAuthentication.signOut();
      // 2. Sign out on the web layer
      await signOut(this.firebaseAuth);

      await this.preferences.clear();
      await this.loadingService.hideLoading()
    }).catch(async (err) => {
      console.log(err);
      await this.loadingService.hideLoading()
      await this.alertService.yumDealzDefaultAlert(
        'Oops...',
        this.ts.getLocalizedValue('ERRORS.THERE_WAS_A_PROBLEM_SIGNING_OUT'),
        false,
        true
      )
    })

    // await this.router.navigateByUrl('',{
    //   replaceUrl:true
    // });
    // location.reload();
  }

  /**
   * The sign up method as user will always at least be an anon user
   * @returns 
   */
  async linkWithGoogle() {
    const result = await FirebaseAuthentication.signInWithGoogle({
      skipNativeAuth: true,
    });
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    const googleUserCred = await linkWithCredential(this.firebaseAuth.currentUser, credential);
    await updateProfile(googleUserCred.user, { displayName: googleUserCred.user.displayName, photoURL: googleUserCred.user.photoURL });
    //await this.dbService.initUser(googleUserCred.user);
    return googleUserCred;
  }

  /**
   * The sign up method as user will always at least be an anon user
   * @returns 
   */
  async linkWithApple() {
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    const appleUserCred = await linkWithCredential(this.firebaseAuth.currentUser, credential);
    await updateProfile(appleUserCred.user, { displayName: appleUserCred.user.displayName, photoURL: appleUserCred.user.photoURL });
    //await this.dbService.initUser(appleUserCred.user);
    return appleUserCred
  }

  /**
   * The sign up method as user will always at least be an anon user
   * @returns 
   */
  async linkWithEmail(email: string, password: string, displayName: string) {
    const credential = EmailAuthProvider.credential(email, password);
    const userCred = await linkWithCredential(this.firebaseAuth.currentUser, credential);
    await updateProfile(userCred.user, { displayName });
    //await this.dbService.initUser(userCred.user);
    return userCred
  }

  async handleAuthErrors(error) {
    const errorCode = error.code;
    let errorMessage = error.message;
    switch (errorCode) {
      case 'auth/app-deleted':
        errorMessage = this.ts.getLocalizedValue('ERRORS.FIREBASE_PROJECT_WITH_AUTH_HAS_BEEN_DELETED');
        break;
      case 'auth/app-not-authorized':
        errorMessage = this.ts.getLocalizedValue('ERRORS.APP_NOT_AUTHORIZED_USE_FIREBASE_WITH_PROVIDED_API_KEY');
        break;
      case 'auth/argument-error':
        errorMessage = this.ts.getLocalizedValue('ERRORS.INVALID_ARGUMENT_WAS_PROVIDED_TO_FIREBASE_AUTH_METHOD');
        break;
      case 'auth/invalid-api-key':
        errorMessage = this.ts.getLocalizedValue('ERRORS.PROVIDED_API_KEY_INVALID_NOT_MATCH_EXPECTED_API_FOR_FIREBASE_PROJECT');
        break;
      case 'auth/invalid-user-token':
        errorMessage = this.ts.getLocalizedValue('ERRORS.PROVIDED_USERS_TOKE_INVALID');
        break;
      case 'auth/network-request-failed':
        errorMessage = this.ts.getLocalizedValue('ERRORS.NETWORK_ERROR_OCCURRED_WHILE_ATTEMPTING_TO_CONNECT_TO_FIREBASE_AUTH');
        break;
      case 'auth/operation-not-allowed':
        errorMessage = this.ts.getLocalizedValue('ERRORS.REQUESTED_OPERATION_NOT_ALLOWED_FOR_CURRENT_FB_PROJECT');
        break;
      case 'auth/too-many-requests':
        errorMessage = this.ts.getLocalizedValue('ERRORS.YOU_HAVE_ATTEMPTED_TO_SIGNIN_TOO_MANY_TIMES');
        break;
      case 'auth/unauthorized-domain':
        errorMessage = this.ts.getLocalizedValue('DOMAIN_SPECIFIED_IN_CONTINUE_URL_NOT_AUTHED_FOR_FIREBASE_PROJECT');
        break;
      case 'auth/user-disabled':
        errorMessage = this.ts.getLocalizedValue('ERRORS.YOUR_ACCOUNT_HAS_BEEN_DISABLED_BY_ADMIN');
        break;
      case 'auth/user-not-found':
        errorMessage = this.ts.getLocalizedValue('ERRORS.EMAIL_ADDRESS_DOESNT_CORRESPOND_TO_AN_EXISTING_ACCOUNT');
        break;
      case 'auth/wrong-password':
        errorMessage = this.ts.getLocalizedValue('ERRORS.PASSWORD_PROVIDED_INCORRECT');
        break;
      case 'auth/invalid-email':
        errorMessage = this.ts.getLocalizedValue('ERRORS.EMAIL_ADDRESS_PROVIDED_NOT_VALID');
        break;
      case 'auth/email-already-in-use':
        errorMessage = this.ts.getLocalizedValue('ERRORS.EMAIL_ADDRESS_PROVIDED_ALREADY_IN_USE');
        break;
      case 'auth/account-exists-with-different-credential':
        errorMessage = this.ts.getLocalizedValue('ERRORS.EMAIL_ADDRESS_PROVIDED_ASSOCIATED_WITH_OTHER_ACCOUNT');
        break;
      case 'auth/credential-already-in-use':
        errorMessage = this.ts.getLocalizedValue('ERRORS.PROVIDER_LINKED_TO_ANOTHER_ACCOUNT');
        break;
      case 'auth/provider-already-linked':
        errorMessage = this.ts.getLocalizedValue('ERRORS.YOU_ARE_TRYING_TO_LINK_ACCOUNT_WITH_PROVIDER_ALREADY_LINKED');
        break;
      case 'auth/weak-password':
        errorMessage = this.ts.getLocalizedValue('ERRORS.PASSWORD_TOO_WEAK');
        break;
      case 'popup_closed_by_user':
        errorMessage = this.ts.getLocalizedValue('ERRORS.SIGNIN_POPUP_CLOSED_BEFORE_SIGNIN_COMPLETED');
        break;
      case 'access_denied':
        errorMessage = this.ts.getLocalizedValue('ERRORS.YOU_DID_NOT_GRANT_REQUESTED_PERMS');
        break;
      case 'immediate_failed':
        errorMessage = this.ts.getLocalizedValue('ERRORS.AUTOMATIC_SIGNIN_FAILED');
        break;
      case 'idpiframe_initialization_failed':
        errorMessage = this.ts.getLocalizedValue('GOOGLE_SIGNIN_FAILED_TO_INITIALIZE_IN_SIGNIN')
        break;
      case 'idpiframe_initialization_timeout':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_FAILED_IN_SIGNIN_POPUP_TIMEOUT');
        break;
      case 'id_token_not_received':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_SUCCESSFUL_BUT_ID_NOT_RETURNED');
        break;
      case 'invalid_first_party_jwt':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_FAILED_INVALID_FIRST_PARTY_JWT');
        break;
      case 'invalid_id_token':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_FAILED_DUE_TO_INVALID_ID_TOKEN');
        break;
      case 'network_error':
        errorMessage = this.ts.getLocalizedValue('ERRORS.NETWORK_ERROR_OCCURRED_WHILE_ATTEMPTING_TO_CONNECT_GOOGLE_SIGNIN');
        break;
      case 'no_auth_event':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_FAILED_DUE_TO_INTERNAL_ERROR');
        break;
      case 'no_such_provider':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_FAILED_PROVIDER_DOESNT_EXIST')
        break;
      case 'popup_blocked_by_browser':
        errorMessage = this.ts.getLocalizedValue('ERRORS.SIGNIN_POPUP_WAS_BLOCKED_BY_AD_BLOCKER');
        break;
      case 'popup_error':
        errorMessage = this.ts.getLocalizedValue('ERRORS.ERROR_OCCURRED_ATTEMPTING_TO_OPEN_SIGNIN_POPUP')
        break;
      case 'provider_error':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_FAILED_DUE_TO_PROVIDER_ERROR');
        break;
      case 'redirect_cancelled_by_user':
        errorMessage = this.ts.getLocalizedValue('ERRORS.GOOGLE_SIGNIN_CANCELLED_BY_USER');
        break;
      case 'redirect_error':
      default:
        errorMessage = errorMessage ? errorMessage : "An unknown occurred (are you offline?)";
        break;
    }
    await this.toasts.showNoteToast(
      errorMessage,
      5000,
      'top'
    )
  }
}
