<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      Voucher QR code
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="explainPage()">
       <ion-icon slot="end" name="information"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content >
  <div class="center-absolute qr-container" >
    <div class="avatar-container">
      <ion-avatar >
        <img [src]="assetLinksService.stamp" />
      </ion-avatar>
    </div>
    <app-qrcode 
      [elementType]="elementTypes.IMG" 
      [value]="qrcodeid"
      cssClass="qrCode"
      [errorCorrectionLevel]="errCorrectionLevels.MEDIUM">
    </app-qrcode>
    <ion-text color="dark">
      <p>Present this to staff when requested</p>
    </ion-text>
  </div>

  <ion-fab vertical="bottom" horizontal="center" slot="fixed">
    <ion-fab-button color="dark" (click)="back()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>