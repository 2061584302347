import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { OutletWithDealz, Reward, TakealotVoucherReward } from 'src/app/models/interfaces';
import { GisService } from 'src/app/services/gis.service';
import { LocationService } from 'src/app/services/location.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Virtual,Pagination,EffectCards,Scrollbar, EffectFlip  } from 'swiper';
import { Observable, map } from 'rxjs';
import { RewardsService } from 'src/app/services/rewards.service';
import { REWARD_TYPES } from 'src/app/models/enums';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
// install Swiper modules
SwiperCore.use([Scrollbar]);

@Component({
  selector: 'app-rewards-lane',
  templateUrl: './rewards-lane.component.html',
  styleUrls: ['./rewards-lane.component.scss'],
})
export class RewardLaneComponent  implements OnInit {
  @Input() cardHeight = '50vh'
  @ViewChild('swiper', { static: true }) swiper?: SwiperComponent;
  swiperConfig: SwiperOptions = {
    slidesPerView:'auto',
    centeredSlides:true,
    centeredSlidesBounds:true,
    scrollbar:true,
    // cardsEffect:{
    //   slideShadows:false,
    //   // transformEl:'ion-card'
    // },
    flipEffect:{
      transformEl:'ion-card'
    }
  };
  takealotVouchers$:Observable<TakealotVoucherReward[]>
  constructor(
    public assetLinksService:AssetlinksService,
    private rewardsService:RewardsService,
  ) { 
 
  }

  ngOnInit() {
     this.takealotVouchers$ = this.rewardsService.rewards$.pipe(
    map((rewards)=>{
      return rewards.filter((reward)=>{
        return reward.type == REWARD_TYPES.TAKEALOT_VOUCHERS
      }).sort((a,b)=>{
        if(a.yum_points_cost > b.yum_points_cost){
          return 1
        }
        if(a.yum_points_cost < b.yum_points_cost){
          return -1
        }
        return 0
      }) as TakealotVoucherReward[]
    })
   )
  }

  onSwiper(params) {
    const [swiper] = params;
    console.log(swiper);
  }
  
  onBeforeTransitionStart(params) {
    const [swiper, speed, internal] = params;
    console.log('beforeTransitionStart, speed: ' + speed);
  }
  
  onSlideChange() {
    console.log('slide change');
  }
  
  slideNext(){
    this.swiper.swiperRef.slideNext(500);
  }
  
  slidePrev(){
   this.swiper.swiperRef.slidePrev(500);
  }

}
