import { Injectable } from '@angular/core';
import { AlertButton, AlertController, IonicSafeString } from '@ionic/angular';
import { AssetlinksService } from './assetlinks.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private readonly okText = 'Ok'
  private readonly alertMode: 'ios' | 'md' = 'ios'
  constructor(
    private alertController: AlertController,
    private assetLinksService: AssetlinksService,
    private ts: TranslationService
  ) {

  }

  async redeemVoucherAlert() {
    try {
      let alrt = await this.alertController.create({
        message: new IonicSafeString(`<ion-img src="${this.assetLinksService.voucherRedeemedStamp}" alt="Congrats!"></ion-img>`),
        cssClass: `custom-alert-shape`,

        backdropDismiss: true,
      })
      await alrt.present();
      return alrt
    } catch (error) {
      this.handleError(error)
    }
  }

  async getStampAlert() {
    try {
      let header = this.ts.getLocalizedValue('ALERT_SERVICE.YUM_YOU_EARNED_A_NEW_STAMP')
      let scanAgain = false
      let alrt = await this.alertController.create({
        header,
        message: new IonicSafeString(`<ion-img src="${this.assetLinksService.greenOpenLogoAnimation}" alt="Congrats!"></ion-img>`),
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        translucent: true,
        backdropDismiss: true,
        buttons: [{
          text: this.ts.getLocalizedValue('ALERT_SERVICE.SCAN_AGAIN'),
          handler: () => {
            scanAgain = true
            return { scanAgain }
          }
        },
        {
          text: this.okText,
          handler: () => {
            scanAgain = false
            return { scanAgain }
          }
        }]
      });

      await alrt.present();
      return alrt.onDidDismiss().then(() => {
        return scanAgain
      });
    } catch (error) {
      this.handleError(error)
    }
  }

  async receivedVoucherAlert() {
    try {
      let header = this.ts.getLocalizedValue('ALERT_SERVICE.CHECK_YOUR_WALLET')
      let alrt = await this.alertController.create({
        header,
        message: new IonicSafeString(`<ion-img src="${this.assetLinksService.voucherCongrats}" alt="Congrats!"></ion-img>`),
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        translucent: true,
        backdropDismiss: true,
      });

      await alrt.present();
      return alrt
    } catch (error) {
      this.handleError(error)
    }
  }

  async receivedRewardAlert() {
    try {
      let alrt = await this.alertController.create({
        message: new IonicSafeString(`<ion-img src="${this.assetLinksService.rewardCongrats}" alt="Congrats!"></ion-img>`),
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        backdropDismiss: true,
      });

      await alrt.present();
      return alrt
    } catch (error) {
      this.handleError(error)
    }
  }

  async yumDealzDefaultAlert(header, message, okButton = false, backdropDismiss = false) {
    try {
      let alrt = await this.alertController.create({
        header,
        message,
        cssClass: `custom-alert-shape`,
        mode: this.alertMode,
        translucent: true,
        backdropDismiss,
        buttons: okButton ? [this.okText] : undefined
      })
      await alrt.present();
    } catch (error) {
      this.handleError(error)
    }
  }

  async yumDealzAlertWithCustomButtons(header, message, buttons: AlertButton[], okButton = false, backdropDismiss = false) {
    try {
      if (okButton) {
        buttons.push({
          text: this.okText
        })
      }
      let alrt = await this.alertController.create({
        header,
        message,
        cssClass: `custom-alert-shape`,
        mode: this.alertMode,
        translucent: true,
        backdropDismiss,
        buttons,
      })
      await alrt.present();
      return alrt
    } catch (error) {
      this.handleError(error)
    }
  }

  private handleError(error) {
    console.log('Tried to display alert but could not', error)
  }
}
