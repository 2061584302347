<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-card-header>
      <ng-container >
        <ion-card-title >Sign in</ion-card-title>
      </ng-container>
     </ion-card-header>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="formContainer">
    <form [formGroup]="loginForm" (ngSubmit)="signIn()">
      <ion-item class="nicerInput" lines='inset'>
        <ion-input  type="email" formControlName="email" clearInput="true"
          placeholder="{{'LOGIN_PAGE.EMAIL' | translate}}*"></ion-input>
      </ion-item>
      <app-form-control-feedback [control]="loginForm.get('email')"></app-form-control-feedback>

      <ion-item class="nicerInput" lines="inset">
        <ion-input type="password" formControlName="pw" clearInput="true"
          placeholder="{{'LOGIN_PAGE.PASSWORD' | translate}}*"></ion-input>
      </ion-item>
      <app-form-control-feedback [control]="loginForm.get('pw')"></app-form-control-feedback>

      <ion-row class="ion-padding">
        <ion-col style="text-align: center;">
          <ion-button type="submit" [disabled]="!loginForm.valid" expand="block" color="primary">{{"LOGIN_PAGE.SIGN_IN"
            |
            translate}}</ion-button>
        </ion-col>
        <ion-col style="text-align: center;" size="12">
          <a (click)="forgotPw()" style="color: var(--ion-color-secondary);">{{"LOGIN_PAGE.FORGOT_PASSWORD" |
            translate}}?</a>
        </ion-col>
      </ion-row>
    </form>

    <ion-row>
      <ion-col size="12">
        <ion-text style="text-align: center;">
          <p style="margin: 0;">{{"LOGIN_PAGE.DONT_HAVE_AN_ACCOUNT" | translate}}?</p>
        </ion-text>
      </ion-col>
      <ion-col size="12">
        <ion-button fill="clear" expand="block" (click)="goToSignUp()">
          {{"LOGIN_PAGE.SIGNUP" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<ion-footer>
  <app-legal-footer-links [color]="'dark'"></app-legal-footer-links>
</ion-footer>