<div mode="ios" class="card" (click)="cardClick()" *ngIf="!loading"> 
  <div class="card-content" *ngIf="special; else nothing">
    <div  id="title-container">
      <h2>{{special?.descriptive_tag}}</h2>
    </div>
    <div id="img-container" class="fade-in">
      <img  
      id="background-image"
      [defaultImage]="assetLinksService.noImage" 
      [lazyLoad]="special?.picture" 
      [errorImage]="assetLinksService.noImage"
      onerror="this.src='assets/favicon.png'">
      <!-- <ion-spinner  name="dots"></ion-spinner> -->
    </div>
    <ion-item style="text-align: center;">
      <ion-label color="secondary">
        <strong>{{special?.restaurant_name}} | {{special?.name}}</strong>
        <p>{{loyaltyCard.expiryDate | daysLeft}}</p>
      </ion-label>
    </ion-item>
      <div *ngIf="special" id="stamps-container" (click)="stampsClick($event)">
        <div class="stamp-row" >
          <div class="stamp-container" *ngFor="let int of arrayMaker(loyaltyCard.stickers_count)" size="4">
            <img  class="stamp" [src]='assetLinksService.stamp'> 
          </div>
          <div class="stamp-container"  [id]="'nextStampSlot_'+int" *ngFor="let int of arrayMaker(special.stamps_required - loyaltyCard.stickers_count)" size="4">
            <img  class="stamp" [src]='assetLinksService.stampHolder'>
          </div>
        </div>
      </div>
  </div>
  <ng-template #nothing>
    <div class="card-content">
      <div  id="title-container">
        <h2>Deleted</h2>
      </div>
      <div id="img-container" class="fade-in">
        <img  
        id="background-image"
        [defaultImage]="assetLinksService.noImage" 
        [lazyLoad]="assetLinksService.noImage" 
        [errorImage]="assetLinksService.noImage"
        onerror="this.src='assets/favicon.png'">
      </div>
        <ion-item  style="text-align: center;">
          <ion-label color="secondary">
            <strong>Deleted</strong>
            <p>{{loyaltyCard.expiryDate | daysLeft}}</p>
          </ion-label>
        </ion-item>
        <div id="stamps-container" >
          <div class="stamp-row" >
            <div class="stamp-container"  size="4">
              
            </div>
          </div>
        </div>
    </div>
  </ng-template>
</div>


<div
style="height: 70vh; margin: 0.5em;"
mode="ios"
*ngIf="loading">
<ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
</div>

