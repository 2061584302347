<ion-header class="ion-no-border">

  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-avatar >
        <img 
        [defaultImage]="assetLinksService.noImage" 
        [lazyLoad]="shop.logo" 
        [errorImage]="assetLinksService.noImage"/>
      </ion-avatar>
    </ion-buttons>
    <ion-title>{{shop.name}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="return(shop)" >
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper 
    #swiper 
    direction="horizontal"
    [pagination]="{
      type: 'progressbar',
    }"
    class="ion-content-scroll-host"
    (swiper)="onSwiper($event)"
    (init)="onSwiperInit($event)"
    (beforeTransitionStart)="onBeforeTransitionStart($event)"
    (slideChange)="onSlideChange($event)"
    >
  <ng-template swiperSlide *ngFor="let story of stories">
    <ion-card class="pic-card"  >
      <img 
      class="fade-in"
      [defaultImage]="assetLinksService.noImage" 
      [lazyLoad]="story.picture" 
      [errorImage]="assetLinksService.noImage"/>
      <ion-fab *ngIf="story.link" (click)="openLink(story)" vertical="bottom" horizontal="end" >
        <ion-fab-button>
          <ion-icon *ngIf="!linkClickThinking; else spinner" name="globe"></ion-icon>
          <ng-template #spinner>
            <ion-spinner name="circular"></ion-spinner>
          </ng-template>
        </ion-fab-button>
      </ion-fab>
    </ion-card>
  
    <ion-card  class="desc-card">
      <ion-card-header>
        <ion-card-title>{{story.name}}</ion-card-title>
        <ion-note> {{story.date_updated_unix_utc | unixDateDisplay}}</ion-note>
      </ion-card-header>
      <ion-card-content>
        {{story.description}}
      </ion-card-content>
    </ion-card>
  
  </ng-template>
</swiper>
</ion-content>