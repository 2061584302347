<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar [color]="toolBarColor" mode="ios" class="ion-no-border">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="backUrl" [defaultHref]="backUrl" text="" icon="chevron-back" style="margin: 0.2em;">
      </ion-back-button>
      <ion-button (click)="runStartButtonHandler()" *ngIf="startButton">
        <ion-icon [name]="startButton.ionIcon"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="runEndButtonHandler()" *ngIf="endButton">
        {{endButton.text?endButton.text:''}}
        <ion-icon slot="{{endButton.text?'end':'icon-only'}}" [name]="endButton.ionIcon"></ion-icon>
      </ion-button>
    </ion-buttons>
    <!-- <ion-title [color]="toolBarTitleColor" (click)="textAreaClick()" *ngIf="!subText">{{mainText}}</ion-title> -->
    <ion-title  [color]="toolBarTitleColor" (click)="textAreaClick()" *ngIf="!subText && mainText !== undefined">
      {{'NO_HEADER_COMPONENT.' + mainText | uppercase | translate}}
    </ion-title>
    <ion-title [color]="toolBarTitleColor" (click)="textAreaClick()" *ngIf="subText" >
      <p style="   text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;">
        {{subText}}
      </p>
    </ion-title>
  </ion-toolbar>
</ion-header>