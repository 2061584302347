
<swiper 
  #swiper 
  direction="horizontal"
  class="ion-content-scroll-host"
  [slidesPerView]="4.5"
  (swiper)="onSwiper($event)"
  (beforeTransitionStart)="onBeforeTransitionStart($event)">
    <ng-template swiperSlide *ngFor="let shopWithStories of shopsWithStories">
      <app-story-avatar
      [shop]="shopWithStories.shop"
      [stories]="shopWithStories.stories"
      ></app-story-avatar>
    </ng-template>
</swiper>

