export enum DAYS_OF_WEEK {
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  }

  export enum TAKEALOT_VOUCHER_VALUES {
    'R50',
    'R100',
    'R250',
    'R500',
    'R1000'
  }

  export enum SCAN_TYPES {
    'NFC',
    'QR',
  }
  
  export enum TextBool{
    TRUE = 'true',
    FALSE = 'false'
}

export enum LoyaltyProgrammeVariants {
  CAMPAIGN = 'campaign',
  INDEFINITE = 'indefinite',
}

export enum StoryVariants {
  INDEFINITE = 'indefinite',
}

export enum SpecialVariants {
  INDEFINITE = 'indefinite',
}

  export enum NOTIFICATION_TYPES {
    TOPIC = 'topic',
    STORY_TOPIC = 'story_topic',
    VOUCHER_EXPIRY = 'voucher_expiry',
    VOUCHER_PUSH = 'voucher_push',
    STAMP_PUSH = 'stamp_push',
    LOYALTY_CARD_EXPIRY = 'loyalty_card_expiry',
    TWO_DAYS_SINCE = 'two_days_since',
    ONE_MONTH_SINCE = 'one_month_since',
    ONE_WEEK_SINCE = 'one_week_since'
  }

  export enum FIRESTORE_ROOT_COLLECTIONS {
    YUM_DEALS_USERS='anon_users',
    BUNDLES='bundles',
    ORDERS='orders',
    PRODUCTS='products',
    STAMP_SESSIONS='stamp_sessions',
    BOOST_PERIODS='boost_periods',
    TAKEALOT_VOUCHERS='takealot_vouchers',
    REWARDS='rewards',
    FRANCHISEES='franchisees',
    PLAN_CONFIGS='plan_configs',
    PRODUCT_CLICKS='product-clicks',
    PRODUCT_LIKES='product-likes',
    PRODUCT_VIEWS='product-views',
    MISC='misc',
    PUCKS='pucks',
    BUSINESS_OWNERS='users',
    PUSH_TOKENS='push_tokens',
    SCAN_ERRORS='scan_errors'
  }

  export enum FIRESTORE_SUB_COLLECTIONS {
    VOUCHERS='vouchers',
    SPECIALS='specials',
    OUTLETS='outlets',
    STORIES='stories',
    RESTAURANTS='restaurants',
    ORDERS='users',
    WALLET_LOYALTY_CARDS = 'specials_loyalty_progress',
    STAMPS = 'stamps'
  }

  export enum REWARD_TYPES {
    TAKEALOT_VOUCHERS='takealot_vouchers',
  }

  export enum PictureFieldNames {
    PRODUCTS = 'picture'
}

export enum supportedProductTypes {
    SPECIALS = 'specials',
    LOYALTY_PROGRAMMES = 'loyalty_programmes',
    STORIES = 'stories',
    PUSH_MESSAGES='push_messages'
}


