import { Injectable } from '@angular/core';
import { ToastServiceService } from './toast-service.service';
import { HapticsService } from './haptics.service';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { TranslationService } from './translation.service';
import { CapacitorBarcodeScanner, CapacitorBarcodeScannerAndroidScanningLibrary, CapacitorBarcodeScannerCameraDirection, CapacitorBarcodeScannerScanOrientation, CapacitorBarcodeScannerScanResult, CapacitorBarcodeScannerTypeHint } from '@capacitor/barcode-scanner';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  crosshairId = "QrCrosshair";
  crosshairContainerId = "QrCrossHairContainer";
  private crosshairContainer: HTMLElement;
  private _isScanning$ = new BehaviorSubject<boolean>(false)
  get isScanning$() {
    return this._isScanning$
  }
  constructor(
    private toasts: ToastServiceService,
    private hapticsService: HapticsService,
    private ts: TranslationService,
  ) {
   // this.createScannerElements();
  }


  async scan() {
    // try {
    //   let result: CapacitorBarcodeScannerScanResult
    //   // TODO Check camera permission
    //   // This is just a simple example, check out the better checks in plugin repo
    //   this.hideBackground();
    //   if (!Capacitor.isNativePlatform()) {
    //     result = await this.mimicScan()
    //   } else {
    //     const grantedPermission = await this.tryToAskPermission()
    //     if (!grantedPermission) {
    //       this.showBackground();
    //       return
    //     }
    //     result = await CapacitorBarcodeScanner.scanBarcode();
    //     await this.hapticsService.hapticsImpactMedium();
    //     this._isScanning$.next(true);
    //   }
    //   this.showBackground();
    //   return { result }
    // } catch (err) {
    //   console.log(err);
    //   await this.toasts.showToast(this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.SCANNER_FAILED'), '');
    // }

      await this.hapticsService.hapticsImpactLight();
      this._isScanning$.next(true);
      return CapacitorBarcodeScanner.scanBarcode(
        {
          hint: CapacitorBarcodeScannerTypeHint.QR_CODE,
          scanInstructions: 'Point camera at QR code. Make sure qr code is in focus and turn on the torch if you have to',
        //  scanButton: true,
         // scanText: 'Click here to scan',
          cameraDirection: CapacitorBarcodeScannerCameraDirection.BACK,
          scanOrientation: CapacitorBarcodeScannerScanOrientation.PORTRAIT,
        }
      ).then((res)=>{
        this._isScanning$.next(false);
        return res
      }).catch(async (err)=>{
        console.log(err);
        this.hapticsService.hapticsImpactLight();
        this._isScanning$.next(false);   
        throw err     
      })
    
  
  }

  // async tryToAskPermission() {
  //   // check if user already granted permission
  //   const status = await BarcodeScanner.checkPermission({ force: false });

  //   if (status.granted) {
  //     // user granted permission
  //     return true;
  //   }

  //   if (status.denied) {
  //     // user denied permission
  //     this.showOpenSettingsToast();
  //     return false;
  //   }

  //   if (status.asked) {
  //     // system requested the user for permission during this call
  //     // only possible when force set to true
  //   }

  //   if (status.neverAsked) {
  //     // user has not been requested this permission before
  //     // it is advised to show the user some sort of prompt
  //     // this way you will not waste your only chance to ask for the permission

  //     const c = confirm(this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.NEED_CAMERA_PERMISSION'));
  //     if (!c) {
  //       return false;
  //     }
  //   }

  //   if (status.restricted || status.unknown) {
  //     // ios only
  //     // probably means the permission has been denied
  //     this.showOpenSettingsToast();
  //     return false;
  //   }

  //   // user has not denied permission
  //   // but the user also has not yet granted the permission
  //   // so request it
  //   const statusRequest = await BarcodeScanner.checkPermission({ force: true });

  //   if (statusRequest.asked) {
  //     // system requested the user for permission during this call
  //     // only possible when force set to true
  //   }

  //   if (statusRequest.granted) {
  //     // the user did grant the permission now
  //     return true;
  //   }

  //   // user did not grant the permission, so he must have declined the request
  //   return false;
  // };

  // private async showOpenSettingsToast() {
  //   await this.toasts.showToast(this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.DECLINED_CAMERA_ACCESS'), '', async () => {
  //     await BarcodeScanner.openAppSettings()
  //   },
  //     this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.OPEN_SETTINGS'))
  // }

  // private async mimicScan() {
  //   this._isScanning$.next(true);
  //   let res = await new Promise<{
  //     hasContent,
  //     content: string,
  //     format: string
  //   }>((resolve) => {
  //     setTimeout(() => {
  //       let result = {
  //         hasContent: false,
  //         content: 'Test',
  //         format: ''
  //       }
  //       resolve(result)
  //     }, 10000)
  //   })
  //   return res
  // }

  // private async hideBackground() {
  //   if (Capacitor.isNativePlatform()) {
  //     await BarcodeScanner.hideBackground();
  //   }
  //   document.body.style.opacity = '0';
  //   document.body.style.background = 'transparent';
  //   this.addCrosshair()
  // }

  // private async showBackground() {
  //   document.body.style.opacity = '';
  //   document.body.style.background = '';
  //   if (Capacitor.isNativePlatform()) {
  //     await BarcodeScanner.showBackground();
  //   }
  //   this.rmCrosshair();
  //   this._isScanning$.next(false);
  // }

  // async toggleTorch(){
  //  const state = await BarcodeScanner.getTorchState();
  //  await this.hapticsService.hapticsImpactLight()
  //  switch (state.isEnabled) {
  //   case true:
  //     await BarcodeScanner.disableTorch();
  //     return !true;
  //   case false:
  //     await BarcodeScanner.enableTorch();
  //     return !false
  //   default:
  //     break;
  //  }
  // }


  // private createScannerElements() {
  //   this.crosshairContainer = document.createElement("div");

  //   // set the crosshairContainer style using CSS
  //   this.crosshairContainer.style.position = "fixed";
  //   this.crosshairContainer.id = this.crosshairContainerId;
  //   this.crosshairContainer.style.top = "50%";
  //   this.crosshairContainer.style.left = "50%";
  //   this.crosshairContainer.style.transform = "translate(-50%, -50%)";
  //   this.crosshairContainer.style.width = "100%";
  //   this.crosshairContainer.style.height = "100%";
  //   this.crosshairContainer.style.zIndex = "9999";

  //   // create a new div element to contain the crosshair
  //   let crosshair = document.createElement("div");

  //   // set the crosshair style using CSS
  //   crosshair.id = this.crosshairId;
  //   crosshair.style.opacity = "";
  //   crosshair.style.background = "";
  //   crosshair.style.position = "fixed";
  //   crosshair.style.top = "50%";
  //   crosshair.style.left = "50%";
  //   crosshair.style.transform = "translate(-50%, -50%)";
  //   crosshair.style.width = "15em";
  //   crosshair.style.height = "15em";
  //   crosshair.style.border = "0.1em dashed #ffffff";
  //   crosshair.style.borderRadius = "2em";
  //   crosshair.style.zIndex = "9999";

  //   // append the crosshair element to the container element
  //   this.crosshairContainer.appendChild(crosshair);


  //   // create a new button element for cancel
  //   var cancelButton = document.createElement("button");

  //   // set the cancel button style using CSS
  //   //cancelButton.textContent = "Cancel";
  //   cancelButton.style.position = "absolute";
  //   cancelButton.style.bottom = "2em";
  //   cancelButton.style.right = "5em";
  //   cancelButton.style.transform = "translateX(50%)";
  //   cancelButton.style.padding = "1em";
  //   cancelButton.style.borderRadius = "50%";
  //   cancelButton.style.backgroundColor = "#ffffff";
  //   cancelButton.style.color = "#36373c";
  //   cancelButton.style.width = "5em";
  //   cancelButton.style.height = "5em";
 
  //   // create the SVG element for the close icon
  //   var svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  //   svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  //   svgElement.setAttribute("class", "ionicon");
  //   svgElement.setAttribute("viewBox", "0 0 512 512");

  //   // create the path element for the close icon
  //   var pathElement1 = document.createElementNS("http://www.w3.org/2000/svg", "path");
  //   pathElement1.setAttribute("d", "M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z");
  //   pathElement1.setAttribute("fill", "none");
  //   pathElement1.setAttribute("stroke", "currentColor");
  //   pathElement1.setAttribute("stroke-miterlimit", "10");
  //   pathElement1.setAttribute("stroke-width", "32");

  //   // create the path element for the close icon lines
  //   var pathElement2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
  //   pathElement2.setAttribute("fill", "none");
  //   pathElement2.setAttribute("stroke", "currentColor");
  //   pathElement2.setAttribute("stroke-linecap", "round");
  //   pathElement2.setAttribute("stroke-linejoin", "round");
  //   pathElement2.setAttribute("stroke-width", "32");
  //   pathElement2.setAttribute("d", "M320 320L192 192M192 320l128-128");

  //   // append the path elements to the SVG element
  //   svgElement.appendChild(pathElement1);
  //   svgElement.appendChild(pathElement2);

  //   // append the SVG element to the close button
  //   cancelButton.appendChild(svgElement);

  //    cancelButton.addEventListener("click", async ()=>{
  //     if(Capacitor.isNativePlatform()){
  //       await BarcodeScanner.stopScan({resolveScan:true});
  //     }
  //     this.showBackground();
  //   });

  //   this.crosshairContainer.appendChild(cancelButton);

  //   var torchButton = document.createElement("button");

  //    torchButton.style.position = "absolute";
  //    torchButton.style.bottom = "2em";
  //    torchButton.style.left = "5em";
  //    torchButton.style.transform = "translateX(-50%)";
  //    torchButton.style.padding = "1em";
  //    torchButton.style.borderRadius = "50%";
  //    torchButton.style.backgroundColor = "#ffffff";
  //    torchButton.style.color = "#36373c";
  //    torchButton.style.width = "5em";
  //    torchButton.style.height = "5em";
  //    // create the SVG element for the torch icon
  //     var svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  //     svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  //     svgElement.setAttribute("class", "ionicon");
  //     svgElement.setAttribute("viewBox", "0 0 512 512");

  //     // create the path element for the torch icon
  //     var pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
  //     pathElement.setAttribute("d", "M456.64 162.86L349.12 55.36c-13.15-13.14-28.68-7.17-41.82 6l-11.95 12c-26.13 26.13-27.62 58.38-29.42 83.31-.89 12.24-9.78 27.55-18.51 36.28L58.58 381.67c-16.35 16.33-12.69 39.42 3.73 55.84l12.17 12.17c16.36 16.35 39.43 20.16 55.86 3.74l188.83-188.8c8.74-8.74 24-17.55 36.29-18.52 24.87-1.86 58.62-4.85 83.26-29.49l11.94-11.94c13.15-13.14 19.12-28.67 5.98-41.81z");
  //     pathElement.setAttribute("fill", "none");
  //     pathElement.setAttribute("stroke", "currentColor");
  //     pathElement.setAttribute("stroke-miterlimit", "10");
  //     pathElement.setAttribute("stroke-width", "32");

  //     // append the path element to the SVG element
  //     svgElement.appendChild(pathElement);

  //     // append the SVG element to the torch button
  //     torchButton.appendChild(svgElement);


  //      // add an event listener to the torch button to toggle the torch when clicked
  //     torchButton.addEventListener("click", async (e)=>{
  //       // Assuming there's a function toggleTorch() defined to handle torch toggling
  //      try {
  //       let state = await this.toggleTorch();
  //       if(state == true){
  //         torchButton.style.backgroundColor = "#7a9e9f";
  //         torchButton.style.color = "#ffffff";
  //       }else if(state ==false){
  //         torchButton.style.backgroundColor = "#ffffff";
  //         torchButton.style.color = "#36373c";
  //       }
  //      } catch (error) {
  //       console.log(error);
  //      }
  //     });

  //     // append the torch button to the container element
  //     this.crosshairContainer.appendChild(torchButton);
  // }

  // private addCrosshair() {
  //   // append the crosshair element to the root element
  //   try {
  //     document.documentElement.appendChild(this.crosshairContainer);
  //   } catch (error) {
  //     //
  //   }
  // }

  // private rmCrosshair() {
  //   try {
  //     document.getElementById(this.crosshairContainerId).remove();
  //   } catch (error) {
  //     //
  //   }

  // }
}
