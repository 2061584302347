import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { Outlet } from '../models/interfaces';
import { CloudfunctriggersService } from './cloudfunctriggers.service';
import { TextBool } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class StampSpamAlertService {
  private outletStamps: { [key: string]: { timestamp: number }[] } = {};
  private threshold = 5; // default threshold

  constructor(
    private cloudFunctions: CloudfunctriggersService
  ) {
    // Clear stamps older than 5 minutes every minute
    interval(60000).subscribe(() => this.clearOldStamps());
  }

  addStamp(outlet: Outlet) {
    const timestamp = Date.now();
    if (!this.outletStamps[outlet.id]) {
      this.outletStamps[outlet.id] = [];
    }
    this.clearOldStamps(); // Clear old stamps on every addStamp call
    this.outletStamps[outlet.id].push({ timestamp });
    const threshold = outlet.receive_stamp_spam_alerts == TextBool.TRUE && outlet.stamp_spam_alerts_threshold ? outlet.stamp_spam_alerts_threshold : this.threshold;
    if (this.outletStamps[outlet.id].length > threshold) {
      this.triggerAlert(outlet);
    }
  }

  private clearOldStamps() {
    const fiveMinutesAgo = Date.now() - 300000;
    for (const outlet in this.outletStamps) {
      this.outletStamps[outlet] = this.outletStamps[outlet].filter((stamp) => {
        return stamp.timestamp > fiveMinutesAgo
      });
    }
  }


  private async triggerAlert(outlet: Outlet) {
    this.outletStamps[outlet.id] = [];
    await this.cloudFunctions.triggerStampSpamAlert(outlet);
  }
}
