import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { LandingPage } from './pages/landing/landing.page';
import { AuthService } from './services/auth.service';
import { LocationService } from './services/location.service';
import { VouchersService } from './services/vouchers.service';
import { NetworkStatusService } from './services/network-status.service';
import { ToastServiceService } from './services/toast-service.service';
import { User } from 'firebase/auth';
import { YumdealzUserService } from './services/yumdealz-user.service';
import { AssetlinksService } from './services/assetlinks.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoyaltyCardService } from './services/loyalty-card.service';
import { UserChangeService } from './services/user-change.service';
import { PushNotificationsService } from './services/pushnotifications.service';
import { environment } from 'src/environments/environment';
import { Particle } from './classes/concrete/canvasObjects/Particle';
import { TranslationService } from './services/translation.service';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { DatabaseService } from './services/database.service';
import { StampSessionsService } from './services/stamp-sessions.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  envType: string;
  isProductionEnv: boolean;
  appVersion: string;
  particles: Particle[] = [];
  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public assetLinksService: AssetlinksService,
    public router: Router,
    public voucherService: VouchersService,
    public loyaltyCardService: LoyaltyCardService,
    public networkStatusService: NetworkStatusService,
    private pushNotification: PushNotificationsService,
    public locationService: LocationService,
    public toasts: ToastServiceService,
    private stampSessionsService:StampSessionsService,
    public yumDealzUserService: YumdealzUserService,
    private userChangeService: UserChangeService,
    private ts: TranslationService
  ) {

  }

  async ngOnInit() {
    this.envType = environment.type;
    this.isProductionEnv = environment.production;
    this.appVersion = environment.appVersion;
    const redirected = await this.redirectToStoresIfWebAndProd();
    if(!redirected)
    await this.initialize();
  }

  async redirectToStoresIfWebAndProd(){
    if(Capacitor.getPlatform() != 'web' || !environment.production) return false;
      await this.router.navigateByUrl('redirect', {
        replaceUrl: true
      });
      const userAgent = navigator.userAgent.toLowerCase();
      const iosAppStoreURL = 'https://apps.apple.com/za/app/yumdealz/id1602929962';
      const androidAppStoreURL = 'https://play.google.com/store/apps/details?id=io.mealdealz.vproductions';
      if (userAgent.match(/(iphone|ipod|ipad|macintosh|mac os x)/)) {
        window.location.href = iosAppStoreURL;
      }
      else {
        window.location.href = androidAppStoreURL;
      }
      return true
  }

  async initialize() {
    try {
      await this.platform.ready();
     if( Capacitor.isNativePlatform()){
      StatusBar.setBackgroundColor({
        color:'#7a9e9f'
      })
     }
      this.ts.resolveAppLanguage();
      await SplashScreen.hide();
      this.authService.giveFirebaseAuth().onAuthStateChanged(
        async (fbUser) => {
          
          if (!fbUser) {
            console.debug('onAuthStateChanged without user');
            await this.authService.guestSignIn(); // this will trigger onAuthStateChanged again;
          } else {
            await this.start(fbUser);
          }
        });
      await this.router.navigateByUrl('tabs', {
        replaceUrl: true
      });
    } catch (error) {
      this.toasts.showErrorToast(error.message);
    }
  }

  async showIntro() {
    const modal = await this.modalCtrl.create({
      component: LandingPage,
      mode: 'ios',
      initialBreakpoint: 0.7,
      breakpoints: [0, 0.7],
      backdropDismiss: false,
      handle: false,
    });
    await modal.present();
  }


  async start(fbUser: User) {
    if (fbUser) {
      try {
        this.userChangeService.setUser(fbUser);
        this.pushNotification.startListening();
        await this.pushNotification.saveTokenIfPossible();
      } catch (error) {
        console.log(error);
        this.toasts.showErrorToast(error.message);
      }
    }
  };
}
