<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="return()" >
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card class="pic-card" *ngIf="imgUrl">
      <img 
      class="fade-in"
      [defaultImage]="assetLinksService.noImage" 
      [lazyLoad]="imgUrl" 
      [errorImage]="assetLinksService.noImage"/>
    </ion-card>
  
    <ion-card  class="desc-card">
      <ion-card-header>
        <ion-card-title>{{title}}</ion-card-title>
        <ion-note> {{note}}</ion-note>
      </ion-card-header>
      <ion-card-content>
        {{message}}
      </ion-card-content>
    </ion-card>
</ion-content>