<div mode="ios" class="card" *ngIf="!loading">
  <div class="card-content" *ngIf="voucher; else nothing">
    <div  id="title-container">
      <h2 class="ion-no-margin">Takealot Voucher</h2>
      <p style="padding: 0.4rem; margin: 0px;">{{voucher.value}}</p>
    </div>
    <div id="img-container" class="fade-in">
      <img  
      id="background-image"
      [defaultImage]="assetLinksService.noImage" 
      [lazyLoad]="assetLinksService.voucherReward" 
      [errorImage]="assetLinksService.noImage"
      onerror="this.src='assets/favicon.png'">
      <!-- <ion-spinner  name="dots"></ion-spinner> -->
    </div>
      <ion-item  style="text-align: center;">
        <ion-label color="secondary" *ngIf="voucherIsReady(); else notReadyLabel">
          <strong>Issued | {{voucher.issued_on_unix_utc_date | unixDateDisplay}} </strong>
          <p>{{voucher.code}}</p>
        </ion-label>
        <ng-template #notReadyLabel>
          <ion-label color="secondary">
            <strong>Voucher is being issued </strong>
            <p>You will receive an email once issued</p>
          </ion-label>
        </ng-template>
      </ion-item>
      <div id="redeem-button-container" >
          <ion-button expand="block" [disabled]="!voucherIsReady()" (click)="copyCode(voucher)" >
              Copy code &nbsp;
            <ion-icon slot="end"  name="copy"></ion-icon>
          </ion-button>
          <ion-button color="secondary"  *ngIf="voucherIsReady()" (click)="showHowToRedeem()" expand="block" fill="clear">
              How to redeem &nbsp;
            <ion-icon slot="end" name="exit"></ion-icon>
          </ion-button>
          <ion-button color="danger" *ngIf="voucherIsReady()" (click)="deleteVoucher()" expand="block" fill="clear">
            Delete &nbsp;
          <ion-icon slot="end" name="trash"></ion-icon>
        </ion-button>
      </div>
  </div>
  <ng-template #nothing>
   
  </ng-template>
</div>

<div
style="height: 40vh; margin: 0.5em;"
mode="ios"
*ngIf="loading">
<ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
</div>