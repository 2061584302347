import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { ConfettiService } from './confetti.service';
import { ToastServiceService } from './toast-service.service';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, Outlet, Puck, ScanType, Shop, Voucher, WalletLoyaltyCard, YumDealzUser } from '../models/interfaces';
import { BarcodeScannerService } from './barcode-scanner.service';
import { NfcService } from './nfc-read.service';
import { HapticsService } from './haptics.service';
import { Capacitor } from '@capacitor/core';
import { NetworkStatusService } from './network-status.service';
import { LoyaltyCardService } from './loyalty-card.service';
import { VouchersService } from './vouchers.service';
import { AuthService } from './auth.service';
import { ModalController } from '@ionic/angular';
import { LoyaltyCardPickerComponent } from '../shared/components/loyalty-card-picker/loyalty-card-picker.component';
import { DealPickerComponent } from '../shared/components/deal-picker/deal-picker.component';
import { LoyaltyCardPreviewComponent } from '../shared/components/loyalty-card-preview/loyalty-card-preview.component';
import { DateTimeService } from './date-time.service';
import { LoadingService } from './loading.service';
import { CapacitorBarcodeScannerScanResult } from '@capacitor/barcode-scanner';
import { Nfc } from '@capawesome-team/capacitor-nfc';
import { LocationService } from './location.service';
import { BehaviorSubject, Subject} from 'rxjs';
import { TranslationService } from './translation.service';
import { DatabaseService } from './database.service';
import { OutletDealzService } from './outlet-dealz.service';
import { CloudfunctriggersService } from './cloudfunctriggers.service';
import { YumdealzUserService } from './yumdealz-user.service';
import { StampSessionsService } from './stamp-sessions.service';
import { StampSpamAlertService } from './stamp-spam-alert.service';
import { SCAN_TYPES } from '../models/enums';

export interface PuckScanResult {
  puckId:string,
  tagSerialNr:string,
  scanType:ScanType
}

@Injectable({
  providedIn: 'root'
})
export class ScanService {
  private _isNfcSupported: boolean = false;
  get isNfcSupported() {
    return this._isNfcSupported
  }
  /**
   * Will emit a number when a voucher is received.
   * The number is the amount of seconds to celebrate for (how long the fireworks animation will play).
   * Can be used in other pages to celebrate for that amount of time
   */
  private _voucherCelebrationBroadcast = new Subject<number>();
  get voucherCelebrationBroadcast() {
    return this._voucherCelebrationBroadcast
  }

  private _isScanning$ = new BehaviorSubject<boolean>(false)
  get isScanning$() {
    return this._isScanning$
  }



  constructor(
    private alertService: AlertService,
    private confettiService: ConfettiService,
    private toasts: ToastServiceService,
    private barcodeScannerService: BarcodeScannerService,
    private nfcReader: NfcService,
    private dbService:DatabaseService,
    private hapticsService: HapticsService,
    private stampSpamAlertService: StampSpamAlertService,
    private network: NetworkStatusService,
    private loyaltyCardService: LoyaltyCardService,
    private vouchersService: VouchersService,
    private outletDealzService: OutletDealzService,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private dateTimeService: DateTimeService,
    private loadingService: LoadingService,
    private locationService: LocationService,
    private cloudFunctions:CloudfunctriggersService,
    private userService:YumdealzUserService,
    private stampSessionsService:StampSessionsService,
    private ts: TranslationService,
  ) {
    this.nfcReader.isSupported().then((res) => {
      this._isNfcSupported = res
    })

    this.nfcReader.isScanning$.subscribe((isScanning) => {
      this.isScanning$.next(isScanning);
    })

    this.barcodeScannerService.isScanning$.subscribe((isScanning) => {
      this.isScanning$.next(isScanning);
    })
  }

  async scanPuck(scanType:ScanType) {

    try {
      this.locationService.attemptSilentUserLocationFetch();
     } catch (error) {
      console.log(error);
     }

    const tryScanTagNfc = async () => {
      try {
        let readRes = await this.nfcReader.readTagForText();
        if (!readRes) {
          return false
        }
        return {
          puckId:readRes.result,
          tagSerialNr:readRes.id,
          scanType
        } as PuckScanResult
      } catch (error) {
        await this.dbService.tryUploadScanError(
          error.message?error.message:error?JSON.stringify(error):'Undefined error',
          'tapped puck with nfc',
          'puckId',
          'no sure - see message',
          scanType
        )
        console.log(error);
      }
    }

    const tryScanTagQr = async () => {
      try {
        let res = await this.barcodeScannerService.scan();

        if (!res || !res.ScanResult) {
          await this.toasts.showToast(
            this.ts.getLocalizedValue('SCAN_SERVICE.NOTHING_SCANNED'),
            ''
          );
          return false
        }

        return {
          puckId:res.ScanResult,
          tagSerialNr:null,
          scanType
        } as PuckScanResult
      } catch (error) {
        await this.dbService.tryUploadScanError(
          error.message?error.message:error?JSON.stringify(error):'Undefined error',
          'Scanned with barcode scanner',
          'no sure - see message',
          'no sure - see message',
          scanType
        )   
        console.log(error);
      }
    }

    let scanResult:PuckScanResult|false;

    if(scanType == 'NFC') {
      scanResult = await tryScanTagNfc();
    } else if(scanType == 'QR'){
      scanResult = await tryScanTagQr();
    } else{
      console.log('unsupported scan type passed')
    }
    
    return scanResult
  }

  async giveStampOrJoinProgrammeBasedOnPuckId(puckScanRes: PuckScanResult) {

    const joinProgrammeAndGiveStampOrStartSession = async (deal: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme, outlet: Outlet,puck:Puck,scanType:ScanType,stampOrSession:'stamp'|'session') => {
      await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
      if(stampOrSession=='session'){
        await startStampSession(deal,puck)
      }else if(stampOrSession=='stamp'){
        const cards = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
        await giveStamp(cards.find(card => { return card.special_id = deal.id }), deal, outlet,puck,scanType)
      }
    }

    const giveStamp = async (card: WalletLoyaltyCard, deal: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme, outlet: Outlet,puck:Puck,scanType:ScanType) => {
      const gotStampButNotVoucher = await this.letUserGetStamp(card, deal,outlet,puck.id,scanType);
      await this.loadingService.hideLoading();
      if (gotStampButNotVoucher) {
        await this.previewCard(deal, outlet,puck);
      }
    }

    const startStampSession = async (deal:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme,puck:Puck) => {
      const card = this.loyaltyCardService.giveCardInWalletForThisSpecial(deal)
      await this.stampSessionsService.startStampSession(card,puck)
      await this.loadingService.hideLoading();
    }

    /**
     * if joinUnjoined is true it will fetch the shops' active deals and join the user to the unjoined
     * loyalty programmes before showing the dealpickermodal. If false it will just show the dealpickermodal with userCardsAtShop
     * @param userCardsAtShop 
     * @param outlet 
     * @param puckId 
     * @param joinUnjoined 
     * @returns 
     */
    const manyCardsInWalletScan = async (userCardsAtShop: WalletLoyaltyCard[], outlet: Outlet,puck:Puck,scanType:ScanType,joinUnjoined:boolean=false) => {
      if(joinUnjoined){
        const activeDealz = await this.outletDealzService.fetchDealzOfThisOutlet(outlet);
        if (activeDealz.length > 1) {
          // join those that have not been joined...
          const unjoinedDealz = activeDealz.filter((deal)=>{
            return !userCardsAtShop.some(card=>{
              return card.special_id === deal.id
            })
          });
  
          for (let index = 0; index < unjoinedDealz.length; index++) {
            const deal = unjoinedDealz[index];
            try {
              await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
            } catch (error) {
              await this.dbService.tryUploadScanError(
                error.message?error.message:error?JSON.stringify(error):'Undefined error',
                'scanPuck.manyCardsInWalletScan.joinLoyalty',
                'puckId',
                puckScanRes.puckId?puckScanRes.puckId:null,
                puckScanRes.scanType
              )   
              console.log(error);
            }
          }
           userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
        }
      }

      if(!userCardsAtShop){
        return
      }

      const dealPickerModal = await this.modalCtrl.create({
        component: LoyaltyCardPickerComponent,
        mode: 'ios',
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        handle: false,
        componentProps: {
          loyaltyCards: userCardsAtShop,
        }
      });

      await dealPickerModal.present();
      await this.loadingService.hideLoading();

      return dealPickerModal.onDidDismiss().then(async (detail) => {
        if(!detail || !detail.data) return;
        let chosenCard = detail.data.card as WalletLoyaltyCard
        if (chosenCard) {
          const deal = await this.outletDealzService.fetchaSpecial(chosenCard.userId, chosenCard.restaurantId, chosenCard.special_id);
          await giveStamp(chosenCard, deal, outlet,puck,scanType);
        }
      })
    }

    const noCardsInWalletScan = async (outlet: Outlet,puck:Puck,scanType:ScanType) => {
      // check if the shop has active dealz
      const dealz = await this.outletDealzService.fetchDealzOfThisOutlet(outlet);

      // no dealz to join
      if (dealz.length == 0) {
        await this.loadingService.hideLoading()
        await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.NO_DEALZ_TO_JOIN'))
      }

      // join and get stamp
      if (dealz.length == 1) {
        await joinProgrammeAndGiveStampOrStartSession(dealz[0], outlet,puck,scanType,'stamp')
      }

      if (dealz.length > 1) {
        // join all...
        for (let index = 0; index < dealz.length; index++) {
          const deal = dealz[index];
          try {
            await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
          } catch (error) {
            await this.dbService.tryUploadScanError(
              error.message?error.message:error?JSON.stringify(error):'Undefined error',
              'giveStampOrJoinProgrammeBasedOnPuckId.noCardsInWalletScan.joinLoyalty',
              'puckId',
              puckScanRes.puckId?puckScanRes.puckId:null,
              puckScanRes.scanType
            )    
            console.log(error);
          }
        }
        const userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
        await this.loadingService.hideLoading();
        return manyCardsInWalletScan(userCardsAtShop,outlet,puck,scanType); 
      }
    }

    const oneCardInWalletScan = async (card: WalletLoyaltyCard, outlet: Outlet,puck:Puck,scanType:ScanType) => {
      // check if the shop has additional active dealz
      const activeDealz = await this.outletDealzService.fetchDealzOfThisOutlet(outlet);
     
      const unjoinedDealz = activeDealz.filter((deal)=>{
        return deal.id != card.special_id
      });

        // join those that have not been joined...
      for (let index = 0; index < unjoinedDealz.length; index++) {
        const deal = unjoinedDealz[index];
        try {
          await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
        } catch (error) {
          await this.dbService.tryUploadScanError(
            error.message?error.message:error?JSON.stringify(error):'Undefined error',
            'giveStampOrJoinProgrammeBasedOnPuckId.oneCardInWalletScan.joinLoyalty',
            'puckId',
            puckScanRes.puckId?puckScanRes.puckId:null,
            puckScanRes.scanType
          )   
          console.log(error);
        }      
      }

       // if unjoinedDealz were found - do manyCardsInWalletScan instead.
      if (unjoinedDealz && unjoinedDealz.length > 0) {
        const userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
        await this.loadingService.hideLoading();
        return manyCardsInWalletScan(userCardsAtShop,outlet,puck,scanType); 
      }

      // if no unjoinedDealz were found we can just give a stamp
      // on the only card
      const deal = await this.outletDealzService.fetchaSpecial(card.userId, card.restaurantId, card.special_id);
      await giveStamp(card, deal, outlet,puck,scanType)
    }
    
    const startNoCardsInWalletSession = async (outlet: Outlet,puck:Puck,scanType:ScanType) => {
      // check if the shop has active dealz
      const dealz = await this.outletDealzService.fetchDealzOfThisOutlet(outlet);

      // no dealz to join
      if (dealz.length == 0) {
        await this.loadingService.hideLoading()
        await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.NO_DEALZ_TO_JOIN'))
      }

      // join and get stamp
      if (dealz.length == 1) {
        await joinProgrammeAndGiveStampOrStartSession(dealz[0], outlet,puck,scanType,'session')
      }

      if (dealz.length > 1) {
        // join all...
        for (let index = 0; index < dealz.length; index++) {
          const deal = dealz[index];
          try {
            await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
          } catch (error) {
            await this.dbService.tryUploadScanError(
              error.message?error.message:error?JSON.stringify(error):'Undefined error',
              'giveStampOrJoinProgrammeBasedOnPuckId.startNoCardsInWalletSession.joinLoyalty',
              'puckId',
              puckScanRes.puckId?puckScanRes.puckId:null,
              puckScanRes.scanType
            )   
            console.log(error);
          }        
        }
        const userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
        await this.loadingService.hideLoading();
        return startManyCardsInWalletSession(userCardsAtShop,outlet,puck); 
      }
    }

    const startOneCardInWalletSession = async (card: WalletLoyaltyCard, outlet: Outlet,puck:Puck,scanType:ScanType) => {
    // check if the shop has additional active dealz
    const activeDealz = await this.outletDealzService.fetchDealzOfThisOutlet(outlet);
     
    const unjoinedDealz = activeDealz.filter((deal)=>{
      return deal.id != card.special_id
    });

      // join those that have not been joined...
    for (let index = 0; index < unjoinedDealz.length; index++) {
      const deal = unjoinedDealz[index];
      try {
        await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
      } catch (error) {
        console.log(error);
        await this.dbService.tryUploadScanError(
          error.message?error.message:error?JSON.stringify(error):'Undefined error',
          'giveStampOrJoinProgrammeBasedOnPuckId.startOneCardInWalletSession.joinLoyalty',
          'puckId',
          puckScanRes.puckId?puckScanRes.puckId:null,
          puckScanRes.scanType
        )   
      }
    }

     // if unjoinedDealz were found - do startManyCardsInWalletSession instead.
    if (unjoinedDealz && unjoinedDealz.length > 0) {
      const userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
      await this.loadingService.hideLoading();
      return startManyCardsInWalletSession(userCardsAtShop,outlet,puck); 
    }

    // if no unjoinedDealz were found we can just start aa session
    // on the only card
    const deal = await this.outletDealzService.fetchaSpecial(card.userId, card.restaurantId, card.special_id);
    await startStampSession(deal,puck)
    }

    const startManyCardsInWalletSession = async (userCardsAtShop: WalletLoyaltyCard[], outlet: Outlet,puck:Puck,joinUnjoined:boolean=false) => {
      if(joinUnjoined){
        const activeDealz = await this.outletDealzService.fetchDealzOfThisOutlet(outlet);
        if (activeDealz.length > 1) {
          // join those that have not been joined...
          const unjoinedDealz = activeDealz.filter((deal)=>{
            return !userCardsAtShop.some(card=>{
              return card.special_id === deal.id
            })
          });
  
          for (let index = 0; index < unjoinedDealz.length; index++) {
            const deal = unjoinedDealz[index];
            try {
              await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser,outlet.id);
            } catch (error) {
              console.log(error);
              await this.dbService.tryUploadScanError(
                error.message?error.message:error?JSON.stringify(error):'Undefined error',
                'giveStampOrJoinProgrammeBasedOnPuckId.startManyCardsInWalletSession.joinLoyalty',
                'puckId',
                puckScanRes.puckId?puckScanRes.puckId:null,
                puckScanRes.scanType
              )   
            }
          }
           userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(outlet.shop_id); //TODO potential race condition (but seems to work??)
        }
      }

      if(!userCardsAtShop){
        return
      }

      const dealPickerModal = await this.modalCtrl.create({
        component: LoyaltyCardPickerComponent,
        mode: 'ios',
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        handle: false,
        componentProps: {
          loyaltyCards: userCardsAtShop,
        }
      });

      await dealPickerModal.present();
      await this.loadingService.hideLoading();

      return dealPickerModal.onDidDismiss().then(async (detail) => {
        if(!detail || !detail.data) return;
        let chosenCard = detail.data.card as WalletLoyaltyCard
        if (chosenCard) {
          const deal = await this.outletDealzService.fetchaSpecial(chosenCard.userId, chosenCard.restaurantId, chosenCard.special_id);
          await startStampSession(deal,puck);
        }
      })
    }

    try {
      if(puckScanRes.scanType == 'QR'){
        if(puckScanRes.puckId.includes('://')){
          await this.toasts.showErrorToast('You must scan the QR code below the puck provided by staff.')
          return;
        }
      }

      const user = await this.dbService.fetchYumDealzUser();

      if(!user){
        throw new Error(`User info not available. Online: ${this.network.isOnline()}`);
      }

      if(user.suspicious){
        throw new Error("Flagged as suspicious.");
      }

      const puck = await this.dbService.fetchPuck(puckScanRes.puckId);
      
      if(!puck.active){
        await this.toasts.showToast('This puck is currently switched off', 'Offline')
        return;
      }

      const outlet = await this.outletDealzService.fetchPuckOutlet(puck);
      if(!outlet.loyalty_programmes){
        await this.toasts.showToast('Earning stamps at this outlet is currently not available', 'Outlet unavailable');
        return;
      }

      if(puckScanRes.scanType=='NFC'){
        if (puck.tag_serial_nr != puckScanRes.tagSerialNr) {
          throw new Error("Scanned with nfc but tagSerialNr and puck tagSerialNr does not match");
        }
        if (!puckScanRes.tagSerialNr) {
          throw new Error("Tag has no serial number");
        }
      }
      
      const userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(puck.shop_id);

      // if user has no cards we should allow the user to add a card
      if (userCardsAtShop.length == 0) {
        if(puck.prefer_stamp_session || (puckScanRes.scanType == 'QR' && puck.qr_disabled)){
          await startNoCardsInWalletSession(outlet,puck,puckScanRes.scanType)
        }else{
          await noCardsInWalletScan(outlet,puck,puckScanRes.scanType);
        }
      
      }

      // give stamp for the only card in wallet
      if (userCardsAtShop.length == 1) {
        if(puck.prefer_stamp_session || (puckScanRes.scanType == 'QR' && puck.qr_disabled)){
          await startOneCardInWalletSession(userCardsAtShop[0], outlet,puck,puckScanRes.scanType)
        }else{
          await oneCardInWalletScan(userCardsAtShop[0], outlet,puck,puckScanRes.scanType)
        }
      }

      // let user choose if there are many of the shops's cards in wallet
      if (userCardsAtShop.length > 1) {
        if(puck.prefer_stamp_session || (puckScanRes.scanType == 'QR' && puck.qr_disabled)){
          await startManyCardsInWalletSession(userCardsAtShop, outlet,puck,true) 
        }else{
          await manyCardsInWalletScan(userCardsAtShop, outlet,puck,puckScanRes.scanType,true)   
        }
      }

      await this.loadingService.hideLoading();
    } catch (error) {
      await this.loadingService.hideLoading();
      await this.toasts.showToast(`Could not get stamp ${!this.network.isOnline() ? '(You are offline)' : ''}`, 'Oops...')
      console.log(error);
      await this.dbService.tryUploadScanError(
        error.message?error.message:error?JSON.stringify(error):'Undefined error',
        'giveStampOrJoinProgrammeBasedOnPuckId.startManyCardsInWalletSession.joinLoyalty',
        'puckId',
        puckScanRes.puckId?puckScanRes.puckId:null,
        puckScanRes.scanType
      )   
    }
  }

  async stopScanning() {
    try {
      await this.nfcReader.stopScanning();
    } catch (error) {
      console.log(error);
    }
  }

  async scanLoyaltyCard(loyaltyCard: WalletLoyaltyCard,scanType:ScanType,initiationOutlet:Outlet) {

    const doGeneralPuckChecks = async (puck:Puck) =>{
      if(!puck.active){
        await this.toasts.showToast('This puck is currently switched off', 'Offline')
        return false
      }

      if (puck.shop_id != loyaltyCard.restaurantId) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
          'Oops...'
        );
        return false;
      }

      const outlet = await this.outletDealzService.fetchPuckOutlet(puck);
      if(!outlet.loyalty_programmes){
        await this.toasts.showToast('Receiving stamps at this outlet is currently not available', 'Outlet unavailable');
        return false;
      }

      if(initiationOutlet.id != outlet.id){
        await this.toasts.showToast(
          'This card preview was initiated at a different outlet. Please close this preview and initiate a new one by using the scan button on the home page',
           'Wrong outlet...'
        );
        return false;
      }

      return true;
    }

    const doUserChecks = async () =>{
      const user = await this.dbService.fetchYumDealzUser()
  
      if(!user){
        throw new Error(`User info not available. Online: ${this.network.isOnline()}`);
      }
  
      if(user.suspicious){
        throw new Error("Flagged as suspicious.");
      }
    }

    const handleLoyaltyCardReadResultNfc = async (readRes:{result: string, id: string}) => {
      if (!readRes) {
        return false;
      }

      await doUserChecks();

      let puck:Puck = await this.dbService.fetchPuck(readRes.result);
      const generalPuckChecksPassed = await doGeneralPuckChecks(puck)
      if(!generalPuckChecksPassed){
        return false
      }

      if (puck.tag_serial_nr != readRes.id) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
          'Oops...'
        );
        return false;
      }

      return readRes.result;
    }
  
    const handleLoyaltyCardReadResultQr = async (readRes:CapacitorBarcodeScannerScanResult) => {
      if (!readRes.ScanResult) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.NOTHING_WAS_SCANNED'),
          this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN'));
        return false
      }

      await doUserChecks();
      
      let puck:Puck = await this.dbService.fetchPuck(readRes.ScanResult);
      const generalPuckChecksPassed = await doGeneralPuckChecks(puck)
      if(!generalPuckChecksPassed){
        return false
      }

      if(puck.qr_disabled){
        if(this._isNfcSupported){
          await this.toasts.showErrorToast('Unfortunately QR code scanning is disabled for this outlet. Use NFC instead.')
        }else{
          await this.toasts.showErrorToast('Unfortunately QR code scanning is disabled for outlet. Your device does not support NFC')
        }
        return false
      }
      return  readRes.ScanResult;
    }

    try {
      try {
        this.locationService.attemptSilentUserLocationFetch();
       } catch (error) {
        console.log(error);
      }
      await this.hapticsService.hapticsImpactLight();
      let puckId;
      if (scanType=='NFC') {
        let scanRes:{result: string;id: string;}
        try {
          scanRes = await this.nfcReader.readTagForText()
          puckId = await handleLoyaltyCardReadResultNfc(scanRes)
        } catch (error) {
          await this.dbService.tryUploadScanError(
            error.message?error.message:error?JSON.stringify(error):'Undefined error',
            'scanLoyaltyCard.handleLoyaltyCardReadResultQr.handleLoyaltyCardReadResultNfc',
            'puckId',
            scanRes?scanRes.result:null,
            scanType
          )  
          console.log(error);
        }
      } else if(scanType == 'QR') {
        let scanRes:CapacitorBarcodeScannerScanResult
        try {
          scanRes = await this.barcodeScannerService.scan();
          puckId = await handleLoyaltyCardReadResultQr(scanRes)
        } catch (error) {
          await this.dbService.tryUploadScanError(
            error.message?error.message:error?JSON.stringify(error):'Undefined error',
            'scanLoyaltyCard.handleLoyaltyCardReadResultQr.handleLoyaltyCardReadResultQr',
            'puckId',
            scanRes? scanRes.ScanResult: null,
            scanType
          ) 
          console.log(error);
        }
      } else {
        console.log('unsupported scan type passed');
      }
      if (!puckId) {
        return;
      }
      return puckId

    } catch (error) {
      console.log(error);
    }
  }

  async scanVoucher(voucher: Voucher,scanType:ScanType) {

    const tryScanVoucherNfc = async (voucher: Voucher) => {
      let scanRes:{
          result: string;
          id: string;
      }
      try {
        scanRes = await this.nfcReader.readTagForText();
        return await this.handleVoucherReadResultNfc(scanRes,voucher)
      } catch (error) {
        await this.dbService.tryUploadScanError(
          error.message?error.message:error?JSON.stringify(error):'Undefined error',
          'scanVoucher.tryScanVoucherNfc.handleVoucherReadResultNfc',
          'puckId',
          scanRes?scanRes.result:null,
          'NFC'
        ) 
        console.log(error);
      }
    }

    const tryScanVoucherQr = async (voucher: Voucher) => {
      let res:CapacitorBarcodeScannerScanResult
      try {
        let res =  await this.barcodeScannerService.scan();
        return await this.handleVoucherReadResultQr(res,voucher)
      } catch (error) {
        await this.dbService.tryUploadScanError(
          error.message?error.message:error?JSON.stringify(error):'Undefined error',
          'scanVoucher.tryScanVoucherNfc.tryScanVoucherQr',
          'puckId',
          res.ScanResult?res.ScanResult:null,
          'QR'
        ) 
        console.log(error);
      }
    }

    try {
      this.locationService.attemptSilentUserLocationFetch();
     } catch (error) {
      console.log(error);
     }

    try {
      await this.hapticsService.hapticsImpactLight()
      let scanContent:false | {
        puck: Puck;
        outlet: Outlet;
        scanContent: string;
      };
      if (scanType=='NFC') {
        scanContent = await tryScanVoucherNfc(voucher);
      } else if (scanType=='QR') {
        scanContent = await tryScanVoucherQr(voucher);
      } else {
        console.log('Unsupported scan type passed');
      }
      if (!scanContent) {
        return
      }
      return scanContent;
    } catch (err) {
      console.log(err);
      await this.toasts.showToast(
        this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN_LATER'),
        this.ts.getLocalizedValue('SCAN_SERVICE.FAILED_TO_REDEEM_VOUCHER')
      );
    }
  }

  async checkIfCameraPermissionsGranted() {
    // const status = await BarcodeScanner.checkPermission({ force: false });
    // if (status.granted) {
    //   return true;
    // }
    // return false;
    return true

  }

  async checkIfCameraPermissionsDenied() {
    // const status = await BarcodeScanner.checkPermission({ force: false });
    // if (status.denied) {
    //   return true;
    // }
    // return false

    return true
  }

  async askCameraPermissions() {
   // await this.barcodeScannerService.tryToAskPermission()
  }

  async checkIfNfcIsOn() {
    const supported = await Nfc.isSupported();
    if (Capacitor.getPlatform() == 'ios') {
      return supported.isSupported
    } else {
      return (await Nfc.isEnabled()).isEnabled
    }
  }

  async letUserGetStamp(loyaltyCard: WalletLoyaltyCard, deal: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme,outlet:Outlet,puckId:string,scanType:ScanType) {
    const stampReceivedReaction = async (newStampCount) => {
      if(!this.authService.isAnonUser()){
        this.cloudFunctions.tryAwardYumPoint(outlet.id)
      }
      try {
        this.stampSpamAlertService.addStamp(outlet)
      } catch (error) {
        console.log(error);
      }
      if (newStampCount !== null && newStampCount !== undefined) {
        await this.showGetStampAlert();
        return true
      } else {
        this.celebrateVoucherReceived();
        return false
      }
    }

    const stampNotReceivedReaction = async (err) => {
      await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.COULD_NOT_GET_STAMP'));
      console.log(err);
      return false
    }

    try {
        return this.loyaltyCardService.receiveStamp(loyaltyCard, deal, {
          lat: this.locationService.userLocation.lat,
          lng: this.locationService.userLocation.lng,
          gps_accuracy:this.locationService.userLocation.accuracy
        },outlet,puckId,scanType).then(async (newStampCount) => {
          return await stampReceivedReaction(newStampCount)
        }).catch(async (err) => {
          return await stampNotReceivedReaction(err)
        })
    } catch (error) {
      console.log(error);
    }
  }

  async letUserRedeemVoucher(voucher: Voucher,outlet:Outlet) {
    try {
        await this.vouchersService.redeemVoucher(voucher,this.locationService.userLocation.lat,this.locationService.userLocation.lng,outlet).then(async (voucherRedeemed) => {
          if(voucherRedeemed){
            await this.showRedeemedVoucherAlert()
          }
        }).catch(async (err) => {
          await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.COULD_NOT_REDEEM_VOUCHER'));
          console.log(err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  private async previewCard(deal: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme, outlet: Outlet,puck:Puck) {
    await this.hapticsService.hapticsImpactLight()
    const card = this.loyaltyCardService.giveCardInWalletForThisSpecial(deal)
    const modal = await this.modalCtrl.create({
      component: LoyaltyCardPreviewComponent,
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      handle: false,
      componentProps: {
        loyaltyCard: card,
        deal,
        outlet,
        puck,
        mode:'previewWithScan',
        today: this.dateTimeService.giveLocalNowDay()
      }
    });
    await modal.present();
  }

  private async showGetStampAlert() {
    try {
      const stampElement = document.getElementById('nextStampSlot_0')
      let stamp = null;
      let busrtPos = null;
      if (stampElement) {
        stamp = document.getElementById('nextStampSlot_0').getBoundingClientRect();
        let busrtPos = {
          x: (stamp.x / window.innerWidth) * 1,
          y: (stamp.y / window.innerHeight) * 1
        }
        console.log(busrtPos);
      }

      this.confettiService.confettiCannonBurst(4, 1, busrtPos);
      //this.confettiService.fireWorksWithAudio()
    } catch (error) {
      console.log(':( no confetti', error);
    }
  }

  private async handleVoucherReadResultNfc(readRes:{result: string, id: string},voucher: Voucher){
    if (!readRes) {
      return false
    }
    const user = await this.dbService.fetchYumDealzUser()

    if(!user){
      throw new Error(`User info not available. Online: ${this.network.isOnline()}`);
    }

    if(user.suspicious){
      throw new Error("Flagged as suspicious.");
    }
    let puck:Puck = await this.dbService.fetchPuck(readRes.result);
    if(!puck.active){
      await this.toasts.showToast('This puck is currently switched off', 'Offline')
      return false
    }
    if (puck.shop_id != voucher.restaurantUid || puck.tag_serial_nr != readRes.id) {
      await this.toasts.showToast(
        this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
        'Oops...'
      );
      return false;
    }
    const outlet = await this.outletDealzService.fetchPuckOutlet(puck);
    if(!outlet.loyalty_programmes){
      await this.toasts.showToast('Redeeming vouchers at this outlet is currently not available', 'Outlet unavailable');
      return false
    }
    
    return  {
      puck,
      outlet,
      scanContent:readRes.result
    }
  }

  private async handleVoucherReadResultQr(readRes: CapacitorBarcodeScannerScanResult,voucher: Voucher){
    if (!readRes || !readRes.ScanResult) {
      await this.toasts.showToast(
        this.ts.getLocalizedValue('SCAN_SERVICE.NOTHING_WAS_SCANNED'),
        this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN')
      );
      return false
    }

    const user = await this.dbService.fetchYumDealzUser()

    if(!user){
      throw new Error(`User info not available. Online: ${this.network.isOnline()}`);
    }

    if(user.suspicious){
      throw new Error("Flagged as suspicious.");
    }

    let puck:Puck = await this.dbService.fetchPuck(readRes.ScanResult)
    if(!puck.active){
      await this.toasts.showToast('This puck is currently switched off', 'Puck Offline')
      return false
    }

    if(puck.qr_disabled){
      if(this._isNfcSupported){
        await this.toasts.showErrorToast('Unfortunately QR code scanning is disabled for this outlet. Use NFC or "Show QR" instead.')
      }else{
        await this.toasts.showErrorToast('Unfortunately QR code scanning is disabled for outlet. Your device does not support NFC. Use "Show QR" instead')
      }
      return false;
    }


    if (puck.shop_id != voucher.restaurantUid) {
      await this.toasts.showToast(
        this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
        'Oops...'
      );
      return false;
    }
    const outlet = await this.outletDealzService.fetchPuckOutlet(puck);
    if(!outlet.loyalty_programmes){
      await this.toasts.showToast('Redeeming vouchers at this outlet is currently not available', 'Outlet unavailable');
      return false
    }
    return  {
      puck,
      outlet,
      scanContent:readRes.ScanResult
    };
  }


  private async showRedeemedVoucherAlert() {
    let alert = await this.alertService.redeemVoucherAlert();
    try {
      let interval = this.confettiService.confettiFireWorks(15);
      alert.onDidDismiss().then(() => {
        clearInterval(interval)
      })
    } catch (error) {
      console.log(':( no confetti', error);
    }
  }


  celebrateVoucherReceived() {
   // this.confettiService.confettiFireWorks(6);
    this.confettiService.confettiStream(4);
    this.voucherCelebrationBroadcast.next(4);
  }
}
