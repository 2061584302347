import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { Outlet, Puck, StampSession, WalletLoyaltyCard } from '../models/interfaces';
import { DateTimeService } from './date-time.service';
import { LoyaltyCardPreviewComponent } from '../shared/components/loyalty-card-preview/loyalty-card-preview.component';
import { ModalController } from '@ionic/angular';
import { HapticsService } from './haptics.service';
import { LoyaltyCardService } from './loyalty-card.service';
import { Unsubscribe, collectionGroup, doc, onSnapshot, query, where } from 'firebase/firestore';
import { FirebaseService } from './firebase.service';
import { UserChangeService } from './user-change.service';
import { User } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import { OutletDealzService } from './outlet-dealz.service';
import { FIRESTORE_ROOT_COLLECTIONS } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class StampSessionsService {
  stampSessionsUnsubscribe:Unsubscribe;
  userStampSession$:BehaviorSubject<StampSession> = new BehaviorSubject(null)
  constructor(
    private db:DatabaseService,
    private dateTimeService:DateTimeService,
    private modalCtrl:ModalController,
    private hapticsService:HapticsService,
    private loyaltyCardService:LoyaltyCardService,
    private outletService:OutletDealzService,
    private firebase:FirebaseService,
    private userChangeService:UserChangeService
  ) {
    this.userChangeService.user$.subscribe((user) => {
      if(!user) return;
      this.listenToUserStampSessions(user);
    });

   }

   async listenToUserStampSessions(user: User){
    if(this.stampSessionsUnsubscribe){
      this.stampSessionsUnsubscribe();
    }
    
    this.stampSessionsUnsubscribe = onSnapshot( doc(this.firebase.getDb(),`${FIRESTORE_ROOT_COLLECTIONS.STAMP_SESSIONS}/${user.uid}`), (querySnapshot) => {
      const stampSession = querySnapshot.data() as StampSession
      this.userStampSession$.next(stampSession);
      this.openStampSession(stampSession)
    });
  }

  async startStampSession(card:WalletLoyaltyCard,puck:Puck){
    return await this.db.startStampSession(card,puck)
  }

  async endStampSession(){
     await this.db.endStampSession()
  }

  private async openStampSession(stampSession:StampSession) {
    if(!stampSession) return
   try {
    await this.hapticsService.hapticsImpactLight();
    const deal = await this.outletService.fetchaSpecial(stampSession.shop_user_id,stampSession.shop_id,stampSession.loyaltyProgramme_id)
    const card = await this.loyaltyCardService.giveCardInWalletForThisSpecial(deal);
    const outlet = await this.outletService.fetchOutlet(stampSession.shop_user_id,stampSession.shop_id,stampSession.outlet_id);
    const modal = await this.modalCtrl.create({
      component: LoyaltyCardPreviewComponent,
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      handle: false,
      componentProps: {
        loyaltyCard: card,
        deal,
        outlet,
        mode:'previewWithSession',
        today: this.dateTimeService.giveLocalNowDay()
      }
    });
    await modal.present();
   } catch (error) {
    console.log(error);
    
   }
  }
}
