<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{'DEAL_PICKER_COMPONENT.CHOOSE_A_DEAL' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="return()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-outlet-lane [outletWithDealz]="outletWithDealz" [cardHeight]="cardHeight" [mode]="'dealSelect'"
    (onDealSelectEmit)="selectDeal($event)">
  </app-outlet-lane>
</ion-content>