import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unixDateDisplay'
})
export class UnixDateDisplayPipe implements PipeTransform {

  transform(value: number): string {
    if (!value && value !== 0) return '';

    const isMilliseconds = value.toString().length === 13;

    const date = isMilliseconds ? new Date(value) : new Date(value * 1000);

    return date.toLocaleDateString(); 
  }

}
