<ion-toolbar mode="md">
 <ion-item mode="ios">
  <ion-label >
    <h2>Takealot Vouchers</h2>
    <p>For your next Takealot purchase</p>
  </ion-label>
 </ion-item>
  <swiper 
  #swiper 
  [config]="swiperConfig"
  (swiper)="onSwiper($event)"
  (beforeTransitionStart)="onBeforeTransitionStart($event)">
    <ng-template swiperSlide *ngFor="let voucher of takealotVouchers$ | async;" >
      <app-takealot-voucher-lane-card
      [voucher]="voucher"
      >
    </app-takealot-voucher-lane-card>
    </ng-template>
</swiper>
</ion-toolbar>

