import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DaysLeftPipe } from "./days-left.pipe";
import { UnixDateDisplayPipe } from './unix-date-display.pipe';

@NgModule({
    declarations:[
        DaysLeftPipe,
        UnixDateDisplayPipe
    ],
    imports:[
        CommonModule,
    ],
    providers:[],
    exports:[
        DaysLeftPipe,
        UnixDateDisplayPipe
    ]
})
export class PipesModule {}