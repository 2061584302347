import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { sendEmailVerification } from 'firebase/auth';
import { Observable, map } from 'rxjs';
import { REWARD_TYPES } from 'src/app/models/enums';
import { Outlet, TakealotVoucher, TakealotVoucherReward } from 'src/app/models/interfaces';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { AuthService } from 'src/app/services/auth.service';
import { CloudfunctriggersService } from 'src/app/services/cloudfunctriggers.service';
import { ConfettiService } from 'src/app/services/confetti.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { HapticsService } from 'src/app/services/haptics.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { PushNotificationsService } from 'src/app/services/pushnotifications.service';
import { RewardsService } from 'src/app/services/rewards.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { TranslationService } from 'src/app/services/translation.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { LoyaltyCardPreviewComponent } from 'src/app/shared/components/loyalty-card-preview/loyalty-card-preview.component';

@Component({
  selector: 'app-takealot-voucher-lane-card',
  templateUrl: './takealot-voucher-lane-card.component.html',
  styleUrls: ['./takealot-voucher-lane-card.component.scss'],
})
export class TakealotVoucherLaneCardComponent implements OnInit {
  @Input() voucher: TakealotVoucherReward
  @Input() cardHeight = '50vh'
  isButtonLoading:boolean = false;
  constructor(
    public assetLinksService: AssetlinksService,
    public userService: YumdealzUserService,
    private cloudFunctions:CloudfunctriggersService,
    private alerts:AlertService,
    private alertController: AlertController,
    private toasts:ToastServiceService,
    private confettiService:ConfettiService
  ) {

  }

  ngOnInit() {

  }

  async cardClick() {

  }

  

  async askToBuyVoucher() {
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message: `You are about to exchange <strong>${this.voucher.yum_points_cost}</strong> YumPoints for this voucher.`,
      cssClass: `custom-alert-shape`,
      mode: 'ios',
      backdropDismiss: true,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: async () => {
            this.buyVoucher();
          }
        }
      ]
    });
  
    await alert.present();
  }

  private async buyVoucher(){
    this.isButtonLoading = true
    try {
      const res = await this.cloudFunctions.assignTakeAlotVoucherToUser(this.voucher);
      if(res.data.voucher_id){
        await this.alerts.receivedRewardAlert()
        this.confettiService.confettiStream(4)
        await this.toasts.showToast('Check your wallet in the rewards tab!','Reward earned!')
      }
    } catch (error) {
      console.log(error);
      await this.toasts.showErrorToast('Could not claim reward')
    }
    this.isButtonLoading = false
  }

}
