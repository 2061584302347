import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { DateTimeService } from 'src/app/services/date-time.service';
import { AuthService } from 'src/app/services/auth.service';
import { CloudfunctriggersService } from 'src/app/services/cloudfunctriggers.service';
import { NetworkStatusService } from 'src/app/services/network-status.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DayTradingHours, Shop, Outlet } from 'src/app/models/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { TranslationService } from 'src/app/services/translation.service';
import { OutletDealzService } from 'src/app/services/outlet-dealz.service';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.page.html',
  styleUrls: ['./outlet.page.scss'],
})
export class OutletPage implements OnInit {
  tradingHours: DayTradingHours[] = [];
  statusColor: string;
  outlet: Outlet
  shop: Shop
  today: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  tradingStatus: 'Open' | 'Closed' | 'Opening soon';


  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public loadingService: LoadingService,
    public router: Router,
    public outletsDealzService: OutletDealzService,
    public assetLinksService: AssetlinksService,
    public authService: AuthService,
    public toastCtrl: ToastController,
    public cloudTriggers: CloudfunctriggersService,
    public dateTimeService: DateTimeService,
    public navParams: NavParams,
    private yumDealzUserService: YumdealzUserService,
    public toasts: ToastServiceService,
    public networkStatusService: NetworkStatusService,
    private dbService: DatabaseService,
    private ts: TranslationService,
  ) { }

  async ngOnInit() {
  try {
    this.shop = await this.outletsDealzService.fetchOutletShop(this.outlet)
  } catch (error) {
    console.log(error);
    this.shop = null
  }
    try {
      this.parseTradingHours();
    } catch (error) {
      console.error(error)
    }
  }

  parseTradingHours() {
    const timeState = this.outletsDealzService.isOutletAvailableNowOrLaterOrMissed(this.outlet, this.today);
    switch (timeState) {
      case 'later':
        this.statusColor = 'warning'
        break;
      case 'now':
        this.statusColor = 'success'
        break;
      case 'missed':
        this.statusColor = 'danger'
        break;
      default:
        this.statusColor = 'medium'
        break;
    }
    

    let today = this.dateTimeService.giveLocalNowDayAbreviated();
    this.outlet.normal_trading_days.forEach(day => {
      let activated = false;
      let abbreviation = day.substr(0, 3).toUpperCase();
      if (abbreviation == today) {
        activated = true;
      }
      this.tradingHours.push({
        activated,
        day,
        abbreviation,
        from: this.outletsDealzService.giveOutletDayFromTradingHours(this.outlet, day),
        to: this.outletsDealzService.giveOutletDayToTradingHours(this.outlet, day)
      })
    });
    this.sortTradingHours();
    this.determineTradingStatus();
  }

  sortTradingHours() {
    this.tradingHours.sort((a, b) => {
      const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holidays'];
      const aIndex = dayOrder.indexOf(a.day.toLowerCase());
      const bIndex = dayOrder.indexOf(b.day.toLowerCase());
      return aIndex - bIndex;
    });
  }

  determineTradingStatus() {
    let today = this.tradingHours.filter((day) => {
      return day.activated
    })[0]
    let timeRelativeTo: string
    try {
      timeRelativeTo = this.dateTimeService.whereIsCurrentTimeRelativeTo(today.from, today.to);
    } catch (error) {
      // the try will fail if today is a day not within the restaurant's trading days
    }
    switch (timeRelativeTo) {
      case 'before':
        this.tradingStatus = 'Opening soon'
        break;
      case 'between':
        this.tradingStatus = 'Open'
        break;
      default:
        this.tradingStatus = 'Closed'
    }
  }

  share() {
    Share.share({
      text: this.outlet.name + this.ts.getLocalizedValue('RESTAURANT_PAGE.SHOP_GIVING_OUT_LOYALTY_CARDS') + '!',
      title: this.ts.getLocalizedValue('RESTAURANT_PAGE.SHOP_ON_YUMDEALZ'),
      url: `https://yumdealz.com`,
      dialogTitle: this.ts.getLocalizedValue('RESTAURANT_PAGE.SHARE_DEAL')
    }
    )
  }

  async showSpecialDays() {
    // let alert = await this.alertCtrl.create({
    //   message:this.special.days.join(', '),
    //   header:'Available on:',
    //   cssClass:`custom-alert-shape`,
    //   mode:'ios',
    // })
    // alert.present()
  }

  return(data = null) {
    this.modalCtrl.dismiss();
  }

  async like() {
    // if(!this.yumDealzUserService.getUser().likedSpecials.includes(this.special.id)){
    //   let alert = await this.alertCtrl.create({
    //     header:'Like this?',
    //     message:'Let the restaurant know by giving their deal a like',
    //     cssClass:`custom-alert-shape`,
    //     mode:'ios',
    //     buttons:[
    //       {
    //         text:'not now',
    //         role:'cancel',
    //       },
    //       {
    //         text:'Like',
    //         handler:async()=>{
    //           await this.loadingService.showOrContinueShowingLoading();
    //           this.cloudTriggers.likeSpecial(this.special,this.yumDealzUserService.getUser()).then(async()=>{
    //             this.special.likes? this.special.likes= this.special.likes+1:this.special.likes=1
    //              this.yumDealzUserService.refreshUser().then(async()=>{
    //               await this.loadingService.hideLoading();
    //               await this.toasts.showToast('Thanks!','Liked deal')
    //             }).catch(async (err)=>{
    //               console.log(err);
    //               await this.loadingService.hideLoading();
    //             })
    //           }).catch(async (err)=>{
    //             await this.loadingService.hideLoading();
    //             await this.toasts.showToast('Are you offline?','Failed to like deal.')
    //           })
    //         }
    //       }
    //     ]
    //   })
    //   await alert.present();
    // }else{
    //   await this.toasts.showToast('Thanks again!','You already liked this deal')
    // }
  }

  activateTradingHourday(day: DayTradingHours) {
    this.tradingHours.forEach(tradingday => {
      tradingday.activated = false;
      if (tradingday.day == day.day) {
        tradingday.activated = true;
      }
    });
  }

  sendMail() {
    try {
      location.href = `mailto:${this.outlet.email}`;
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_OPEN_MAIL_APP'));
    }
  }

  call() {
    try {
      window.open(`tel:${this.outlet.contact_number}`);
    } catch (error) {
      console.log(error);
      this.toasts.showErrorToast(this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_CALL'))
    }
  }

  async viewMap() {
    let googlemaps = `https://www.google.com/maps/search/?api=1&query=${this.outlet.lat},${this.outlet.lng}`
    try {
      window.open(googlemaps, '_system', 'location=yes');
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_OPEN_GOOGLE_MAPS'))
    }
  }

  //can remove this eventually as validation in merchant portal should
  //not allow non international format
  giveIntContactNumber(contactNumber: string) {
    let indexZero = '+27'
    let contactNumberSplit = contactNumber.split('');
    contactNumberSplit[0] = indexZero;
    let contactNumberNew = contactNumberSplit.join('');
    return contactNumberNew
  }

  openWhatsapp() {
    try {
      let SAIntNumber = this.outlet.contact_number[0] == '+' ? this.outlet.contact_number : this.giveIntContactNumber(this.outlet.contact_number)
      let msg = encodeURI(this.ts.getLocalizedValue('RESTAURANT_PAGE.WHATSAPP_DEFAULT_MESSAGE') + ':');
      window.open(`https://wa.me/${SAIntNumber}?text=${msg}`, '_system', 'location=yes');
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_OPEN_WHATSAPP'))
    }
  }

  openFacebookPage() {
    try {
      window.open(this.shop.facebook, '_system', 'location=yes')
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_OPEN_FACEBOOK_PAGE'))
    }
  }

  openInstaPage() {
    try {
      window.open(this.shop.instagram, '_system', 'location=yes')
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_OPEN_INSTAGRAM_PAGE'))
    }
  }

  viewReviews() {
    this.toasts.showToast(this.ts.getLocalizedValue('RESTAURANT_PAGE.REVIEW_FUNCTIONALITY_PLANNED_FOR_FUTURE_UPDATE'), this.ts.getLocalizedValue('COMING SOON') + '!')
  }

  openWebsite() {
    try {
      window.open(this.shop.website, '_system', 'location=yes')
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('RESTAURANT_PAGE.COULD_NOT_OPEN_WEBSITE'))
    }
  }

  // isAbbreviatedDayInSpecialDays(day:DayTradingHours){
  //   return this.special.days.includes(day.day)
  // }

}
