import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then(m => m.InfoPageModule)
  },
  {
    path: 'outlet',
    loadChildren: () => import('./pages/outlet/outlet.module').then(m => m.OutletPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'location-selection-map',
    loadChildren: () => import('./pages/tabs/search/modals/location-selection-map/location-selection-map.module').then(m => m.LocationSelectionMapPageModule)
  },
  {
    path: 'vouchers',
    loadChildren: () => import('./pages/wallet/wallet.module').then(m => m.WalletPageModule)
  },
  {
    path: 'search-location',
    loadChildren: () => import('./pages/tabs/search/modals/search-location/search-location.module').then(m => m.SearchLocationPageModule)
  },
  {
    path: 'restaurant-search',
    loadChildren: () => import('./pages/tabs/search/modals/outlet-search/outlet-search.module').then(m => m.OutletSearchPageModule)
  },
  {
    path: 'voucher-claim-page',
    loadChildren: () => import('./pages/voucher-claim-page/voucher-claim-page.module').then(m => m.VoucherClaimPagePageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'forgot-pw',
    loadChildren: () => import('./pages/auth/forgot-pw/forgot-pw.module').then(m => m.ForgotPwPageModule)
  },
  {
    path: 'offline',
    loadChildren: () => import('./pages/offline/offline.module').then(m => m.OfflinePageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/tabs/info/language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'redirect',
    loadChildren: () => import('./pages/redirect/redirect.module').then( m => m.RedirectPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
