import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TakealotVoucher } from 'src/app/models/interfaces';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { VouchersService } from 'src/app/services/vouchers.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { HapticsService } from 'src/app/services/haptics.service';
import { DatabaseService } from 'src/app/services/database.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-takealot-voucher-card',
  templateUrl: './takealot-voucher-card.component.html',
  styleUrls: ['./takealot-voucher-card.component.scss'],
})
export class TakealotVoucherCardComponent  implements OnInit,OnDestroy {
  @Input() voucher:TakealotVoucher;

  /**
  * the current day
  */
  today:"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";

  loading:boolean=true;

  howToUseGiftVoucherUrl = 'https://www.takealot.com/help-centre/payments-promos-gift-vouchers/all-about-gift-vouchers'

  constructor(
    public assetLinksService:AssetlinksService,
    public alertService:AlertService,
    public yumDealzUserService:YumdealzUserService,
    public dateTimeService:DateTimeService,
    private clipboard: Clipboard,
    private toasts:ToastServiceService,
    private haptics:HapticsService,
    private dbService:DatabaseService,
    private alertController:AlertController
    ) { 
     

    }
  
  ngOnDestroy(): void {

  }


  async deleteVoucher() {
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message: `You are about to delete a voucher. This is irreversible. Make sure you have loaded the voucher on your Takealot account before removing.`,
      cssClass: `custom-alert-shape`,
      mode: 'ios',
      backdropDismiss: true,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: async () => {
            this.tryDeleteVoucher()
          }
        }
      ]
    });
  
    await alert.present();
  }

  private async tryDeleteVoucher(){
    try {
      await this.dbService.deleteTakealotVoucher(this.voucher)
    } catch (error) {
      this.toasts.showToast('','Could not delete')
    }
  }

  voucherIsReady(){
    return this.voucher.issued_on_unix_utc_date && this.voucher.issued && this.voucher.code
  }

  showHowToRedeem(){
    window.open(this.howToUseGiftVoucherUrl, '_system', 'location=yes');
  }

  async ngOnInit() {
    this.loading = false;
  }

  async copyCode(voucher:TakealotVoucher){
    this.clipboard.copy(voucher.code);
    await this.haptics.hapticsImpactLight()
    await this.toasts.showNoteToast('Voucher code copied to clipboard',2000,'top');
  }
}
