import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { BehaviorSubject } from 'rxjs';
import { DateTimeService } from './date-time.service';
import { GisService } from './gis.service';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, Outlet, OutletWithDealz, Puck } from '../models/interfaces';
import { supportedProductTypes } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class OutletDealzService {
  private _browsingOutletsWithDealz:OutletWithDealz[]=[]
  browsingOutlets$: BehaviorSubject<OutletWithDealz[]> = new BehaviorSubject<OutletWithDealz[]>([]);
  constructor(
    private dateTimeService:DateTimeService,
    private database:DatabaseService,
    private gisService:GisService
  ) { 

  }

  async fetchaSpecial(userUid:string,shopId:string,specialId:string){
    const specialInMemory = this.returnSpecialFromBrowsingSpecialsIfThere(specialId)
    if(specialInMemory){
      return specialInMemory
    }else{
      return this.database.fetchaSpecial(userUid,shopId,specialId);
    }
  }

  async fetchOutlet(userId:string,shopId:string,outletId:string){
    return this.database.getOutletWithId(userId,shopId,outletId);
  }

  fetchOutletShop(outlet:Outlet){
    return this.database.getShopWithOutlet(outlet);
  }

  fetchPuckShop(puck:Puck){
    return this.database.getShopWithPuck(puck);
  }

  fetchPuckOutlet(puck:Puck){
    return this.database.getOutletWithPuck(puck);
  }

  returnSpecialFromBrowsingSpecialsIfThere(specialUid:string){
    for (let index = 0; index < this._browsingOutletsWithDealz.length; index++) {
      const outlet = this._browsingOutletsWithDealz[index]
      const foundDeal = outlet.dealz.find(deal=>{
        return deal.id == specialUid
      })
      if(foundDeal){
        return foundDeal  
      }
    }
  }
  
  async fetchOutletsInArea(center:[number,number],radiusInM:number){
   let outlets = await this.database.fetchAllVerifiedOutletsAtLocation(center,radiusInM,supportedProductTypes.LOYALTY_PROGRAMMES);
    this.gisService.sortTheseObjectsByDistanceFrom(
      outlets,
      center[1],
      center[0]
    )
    const browsingOutletsWithDealz = await this.getActiveDealzOfTheseOutlets(outlets)
    this.browsingOutlets$.next(browsingOutletsWithDealz);
  }  

  async fetchDealzOfThisOutlet(outlet:Outlet){
    if(!outlet.loyalty_programmes){
      return []
    }
      return this.database.getShopActiveDealz(outlet.shop_id)
   }   

  giveOutletDayFromTradingHours(outlet:Outlet,day:'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'){
    if(!day||!outlet){
      throw new Error("outlet or day is nullish"); 
    }
    switch (day) {
      case 'Monday':
      case 'Tuesday':
      case 'Wednesday':
      case 'Thursday':
      case 'Friday':
        return outlet.trading_from
      case 'Saturday':
        return outlet.saturday_trading_from
      case 'Sunday':
        return outlet.sunday_trading_from
      default:
        return outlet.holiday_trading_from
    }
  }
  
  giveOutletDayToTradingHours(outlet:Outlet,day:'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'){
    if(!day||!outlet){
      throw new Error("outlet or day is nullish"); 
    }
    switch (day) {
      case 'Monday':
      case 'Tuesday':
      case 'Wednesday':
      case 'Thursday':
      case 'Friday':
        return outlet.trading_to
      case 'Saturday':
        return outlet.saturday_trading_to
      case 'Sunday':
        return outlet.sunday_trading_to
      default:
        return outlet.holiday_trading_to
    }
  }

  isOutletAvailableNowOrLaterOrMissed(outlet:Outlet,day:'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'){
    const from = this.giveOutletDayFromTradingHours(outlet,day)
    const to = this.giveOutletDayToTradingHours(outlet,day)
    
    if(this.dateTimeService.isCurrentTimeBetween(from,to)){
      return 'now'
    }
    if(this.dateTimeService.isCurrentTimeBefore(from)){
      return 'later'
    }
    if(this.dateTimeService.isCurrentTimeAfter(to)){
      return 'missed'
    }
  }

  private async getActiveDealzOfTheseOutlets(outlets:Outlet[]) {
    let promises:Promise<(CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[]>[]= [];
    outlets.forEach(outlet => {
      promises.push(this.fetchDealzOfThisOutlet(outlet))
    });
    let dealzPerOutlet = await Promise.all(promises);
    return this.constructBrowsingOutletsWithDealzFromArrayOfOutlets(outlets,dealzPerOutlet);
  }

  private constructBrowsingOutletsWithDealzFromArrayOfOutlets(outlets:Outlet[],dealzPerOutlet:(CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[][]){
    this._browsingOutletsWithDealz.splice(0,this._browsingOutletsWithDealz.length)
    outlets.forEach((outlet)=>{
      let outletDealzArray = dealzPerOutlet.find((dealz)=>{
        if(dealz.length > 0){
          return dealz[0].restaurant_id == outlet.shop_id
        }
        return false
      })
      this._browsingOutletsWithDealz.push({...outlet,dealz:outletDealzArray?outletDealzArray:[]})
    })
    return this._browsingOutletsWithDealz
  }
}

