<ion-header class="ion-no-border">
  <ion-toolbar color="light" class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="return()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="secondary" class="bordered" (click)="share()">
        <ion-icon color="primary" name="share-social"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true" slot="fixed">

  <div class="contentHeader">
    <div  class="specialImgCard">
      <img class="specialImg"  [defaultImage]="assetLinksService.noImage" [lazyLoad]="outlet.image" [errorImage]="assetLinksService.noImage" onerror="this.src='assets/favicon.png'">
    </div>
  </div>


  <ion-card #content class="outlet-details-card">
    <ion-card-header class="outlet-details-card-header">
      <ion-grid class="outlet-details-card-header-grid" fixed>
        <ion-row >
            <ng-container *ngIf="outlet.name">
              <ion-col class="outlet-details-card-header-grid-left" size="12">
              <ion-card-title >
                {{outlet.name}} <ion-note [class]="tradingStatus" class="tradingStatus">({{tradingStatus}})</ion-note>
              </ion-card-title>
              <ion-card-subtitle>
                {{outlet.address}}
              </ion-card-subtitle>
            </ion-col >
            </ng-container>
            <ng-container *ngIf="!outlet.name">
              <ion-col class="outlet-details-card-header-grid-left" size="12">
              <ion-card-title >
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text> 
              </ion-card-title>
              <ion-card-subtitle>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
              </ion-card-subtitle>
            </ion-col >
        
            </ng-container>
            
        </ion-row>
      </ion-grid>
    </ion-card-header>

    <ion-grid style="text-align: center;">
      <ion-row>
        <ion-col>
          <ion-button class="contactInfoButtons" (click)="call()">
            <ion-icon name="call-outline"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button (click)="sendMail()" class="contactInfoButtons">
            <ion-icon name="mail-outline"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button class="contactInfoButtons" (click)="viewMap()">
            <ion-icon name="location-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button [disabled]="!shop || !shop.whatsapp" class="contactInfoButtons" (click)="openWhatsapp()">
            <ion-icon name="logo-whatsapp"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button [disabled]="!shop || !shop.facebook" class="contactInfoButtons" (click)="openFacebookPage()">
            <ion-icon name="logo-facebook"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button [disabled]="!shop || !shop.instagram" class="contactInfoButtons" (click)="openInstaPage()">
            <ion-icon name="logo-instagram"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button [disabled]="!shop || !shop.website" class="contactInfoButtons" (click)="openWebsite()">
            <ion-icon name="globe-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    
    <ion-card  style="text-align: center;box-shadow: none;">
      <ion-item *ngFor="let day of tradingHours">
          <ion-label *ngIf="!day.activated">
            {{day.abbreviation}} : {{day.from}} - {{day.to}}
          </ion-label>
          <div *ngIf="day.activated" class="timeLeftToday">
            <ion-label *ngIf="day.activated">
              {{day.abbreviation}} : {{day.from}} - {{day.to}}
            </ion-label>
          </div>
      </ion-item>
    </ion-card>
  </ion-card>
</ion-content>