import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { FormControlFeedbackComponent } from "./components/form-control-feedback/form-control-feedback.component";
import { LegalFooterLinksComponent } from "./components/legal-footer-links/legal-footer-links.component";
import { WalletLoyaltyCardComponent } from "./components/wallet-loyalty-card/wallet-loyalty-card.component";
import { LoginBannerComponent } from "./components/login-banner/login-banner.component";
import { VoucherCardComponent } from "./components/voucher-card/voucher-card.component";
import { PipesModule } from "./pipes/pipes.module";
import { NoHeaderComponent } from "./components/headers/no-header/no-header.component";
import { LoyaltyCardPickerComponent } from "./components/loyalty-card-picker/loyalty-card-picker.component";
import { DealPickerComponent } from "./components/deal-picker/deal-picker.component";
import { LoyaltyCardPreviewComponent } from "./components/loyalty-card-preview/loyalty-card-preview.component";
import { StoryAvatarComponent } from "./components/story-avatar/story-avatar.component";
import { StoryViewerComponent } from "./components/story-viewer/story-viewer.component";
import { StoriesLaneComponent } from "./components/stories-lane/stories-lane.component";
import { SwiperModule } from "swiper/angular";
import { TranslateModule } from "@ngx-translate/core"
import { LoadingStampComponent } from "./components/stamps/loading-stamp/loading-stamp.component";
import { YumdealzStampComponent } from "./components/stamps/yumdealz-stamp/yumdealz-stamp.component";
import { ScanningNfcStampComponent } from "./components/stamps/scanning-nfc-stamp/scanning-nfc-stamp.component";
import { NfcDisabledStampComponent } from "./components/stamps/nfc-disabled-stamp/nfc-disabled-stamp.component";
import { CameraDisabledStampComponent } from "./components/stamps/camera-disabled-stamp/camera-disabled-stamp.component";
import { ClickToScanNfcStampComponent } from "./components/stamps/click-to-scan-nfc-stamp/click-to-scan-nfc-stamp.component";
import { LocationDeniedStampComponent } from "./components/stamps/location-denied-stamp/location-denied-stamp.component";
import { ScanningQrCodeStampComponent } from "./components/stamps/scanning-qr-code-stamp/scanning-qr-code-stamp.component";
import { CongratsVoucherStampComponent } from "./components/stamps/congrats-voucher-stamp/congrats-voucher-stamp.component";
import { NoSearchResultsStampComponent } from "./components/stamps/no-search-results-stamp/no-search-results-stamp.component";
import { EmptyCardsWalletStampComponent } from "./components/stamps/empty-cards-wallet-stamp/empty-cards-wallet-stamp.component";
import { LocationDisabledStampComponent } from "./components/stamps/location-disabled-stamp/location-disabled-stamp.component";
import { ClickToScanQrCodeStampComponent } from "./components/stamps/click-to-scan-qr-code-stamp/click-to-scan-qr-code-stamp.component";
import { EmptyVouchersWalletStampComponent } from "./components/stamps/empty-vouchers-wallet-stamp/empty-vouchers-wallet-stamp.component";
import { ShowDealzAroundYouStampComponent } from "./components/stamps/show-dealz-around-you-stamp/show-dealz-around-you.component";
import { LocationPermissionStampComponent } from "./components/stamps/location-permission-stamp/location-permission-stamp.component";
import { DealLaneCardComponent } from "./components/outlet-lane-cards/deal-lane-card/deal-lane-card.component";
import { OultetLaneComponent } from "./components/outlet-lane/outlet-lane.component";
import { OutletAvatarComponent } from "./components/outlet-avatar/outlet-avatar.component";
import { TakealotVoucherCardComponent } from "./components/takealot-voucher-card/takealot-voucher-card.component";
import { EmptyRewardsWalletStampComponent } from "./components/stamps/empty-rewards-wallet-stamp/empty-rewards-wallet-stamp.component";
import { RewardLaneComponent } from "./components/rewards-lane/rewards-lane.component";
import { TakealotVoucherLaneCardComponent } from "./components/rewards-lane-cards/takealot-voucher-lane-card/takealot-voucher-lane-card.component";
import { QrcodeComponent } from "./components/qrcode/qrcode.component";

@NgModule({
    declarations: [
        StoryAvatarComponent,
        LoyaltyCardPickerComponent,
        TakealotVoucherCardComponent,
        VoucherCardComponent,
        DealPickerComponent,
        FormControlFeedbackComponent,
        LoyaltyCardPreviewComponent,
        LoginBannerComponent,
        LoyaltyCardPickerComponent,
        NoHeaderComponent,
        LoadingStampComponent,
        LegalFooterLinksComponent,
        WalletLoyaltyCardComponent,
        StoriesLaneComponent,
        StoryViewerComponent,
        OultetLaneComponent,
        OutletAvatarComponent,
        DealLaneCardComponent,
        YumdealzStampComponent,
        ScanningNfcStampComponent,
        NfcDisabledStampComponent,
        CameraDisabledStampComponent,
        ClickToScanNfcStampComponent,
        LocationDeniedStampComponent,
        ScanningQrCodeStampComponent,
        QrcodeComponent,
        CongratsVoucherStampComponent,
        LocationPermissionStampComponent,
        ShowDealzAroundYouStampComponent,
        EmptyRewardsWalletStampComponent,
        NoSearchResultsStampComponent,
        EmptyCardsWalletStampComponent,
        LocationDisabledStampComponent,
        ClickToScanQrCodeStampComponent,
        EmptyVouchersWalletStampComponent,
        RewardLaneComponent,
        TakealotVoucherLaneCardComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        IonicModule,
        SwiperModule,
        FormsModule,
        LazyLoadImageModule,
        TranslateModule
    ],
    providers: [],
    exports: [
        RewardLaneComponent,
        TakealotVoucherLaneCardComponent,
        TranslateModule,
        WalletLoyaltyCardComponent,
        QrcodeComponent,
        EmptyRewardsWalletStampComponent,
        LoyaltyCardPickerComponent,
        LoginBannerComponent,
        DealLaneCardComponent,
        OultetLaneComponent,
        DealPickerComponent,
        LoadingStampComponent,
        OutletAvatarComponent,
        VoucherCardComponent,
        LegalFooterLinksComponent,
        LoyaltyCardPickerComponent,
        TakealotVoucherCardComponent,
        NoHeaderComponent,
        LoyaltyCardPreviewComponent,
        FormControlFeedbackComponent,
        StoryAvatarComponent,
        StoriesLaneComponent,
        StoryViewerComponent,
        YumdealzStampComponent,
        ScanningNfcStampComponent,
        NfcDisabledStampComponent,
        CameraDisabledStampComponent,
        ClickToScanNfcStampComponent,
        LocationPermissionStampComponent,
        ShowDealzAroundYouStampComponent,
        LocationDeniedStampComponent,
        ScanningQrCodeStampComponent,
        CongratsVoucherStampComponent,
        NoSearchResultsStampComponent,
        EmptyCardsWalletStampComponent,
        LocationDisabledStampComponent,
        ClickToScanQrCodeStampComponent,
        EmptyVouchersWalletStampComponent
    ]
})
export class SharedModule { }