import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssetlinksService {
  private _baseUrl = '/assets/'
  private _favicon='favicon.png'
  private _noResults='empty.svg'
  private _voucherCongrats='voucher_congrats.svg'
  private _rewardCongrats='reward_congrats.svg'
  private _voucherReward='rewardVoucher.png'
  private _voucherRedeemedStamp='voucher_redeemed_stamp.svg'
  private _noResultsLight='empty_light.svg'
  private _stampHolder='coin_missing.svg'
  private _stamp='coin_stamped.svg'
  private _logoWhite='logo_white.svg'
  private _logoGreen='logo_green.svg'
  private _loadingWhite = "loadinglogo_css_white.svg"
  private _loadingGreen = "loadinglogo_css_green.svg"
  private _loading2 = "loading.svg"
  private _openLogoAnimationWhite = "open_animation_css_white.svg"
  private _openLogoAnimationGreen = "open_animation_css_green.svg"
  private _loadingImgAnimation = "loading.svg"
  private _introSlideOne ="wondering3.svg"
  private _introSlideTwo ="simple.svg"
  private _introSlideThree ="loyalty_transparent.svg"
  private _introSlideFour ="open_animation_css_white.svg"
  private _underConstruction="under_construction.svg"
  private _gift="food_gift.svg"
  private _openGift="food_gift_opened.svg"
  private _backgroundImage ="stacked-waves-haikei.svg"
  private _currentLocationGreen="currentlocation_icon_green.svg"
  private _currentLocationWeak="currentlocation_icon_weak.svg"
  private _shop ="stacked-shop-haikei.svg"
  private _spinnerGif = "spinnerGif2.gif"
  private _confettiGif = "confetti.gif"
  private _fireWorksWav = "explosions.wav"
  private _story_small = "story_small.png"
  private _no_image = "noimg.png"
  private _person = "person.png"
  get person(){
    return `${this._baseUrl}${this._person}`
  }
  get voucherReward(){
    return `${this._baseUrl}${this._voucherReward}` 
  }
  get noImage(){
    return `${this._baseUrl}${this._no_image}`
  }
  get story_small(){
    return `${this._baseUrl}${this._story_small}`
  }
  private _story = "story.png"
  get story(){
    return `${this._baseUrl}${this._story}`
  }
  get currentLocationGreen(){
    return `${this._baseUrl}${this._currentLocationGreen}`
  }
  get currentLocationWeak(){
    return `${this._baseUrl}${this._currentLocationWeak}`
  }
  get shop(){
    return `${this._baseUrl}${this._shop}`
  }
  get voucherRedeemedStamp(){
    return `${this._baseUrl}${this._voucherRedeemedStamp}`
  }
  get voucherCongrats(){
    return `${this._baseUrl}${this._voucherCongrats}`
  }
  get rewardCongrats(){
    return `${this._baseUrl}${this._rewardCongrats}`
  }
  get favicon(){
    return `${this._baseUrl}${this._favicon}`
  }
  get noResults(){
    return `${this._baseUrl}${this._noResults}`
  }
  get noResultsLight(){
    return `${this._baseUrl}${this._noResultsLight}`
  }
  get stampHolder(){
    return `${this._baseUrl}${this._stampHolder}`
  }
  get stamp(){
    return `${this._baseUrl}${this._stamp}`
  }
  get whiteLogo(){
    return `${this._baseUrl}${this._logoWhite}`
  }
  get greenLogo(){
    return `${this._baseUrl}${this._logoGreen}`
  }
  get whiteLoading(){
    return `${this._baseUrl}${this._loadingWhite}`
  }
  get greenLoading(){
    return `${this._baseUrl}${this._loadingGreen}`
  }
  get loading2(){
    return `${this._baseUrl}${this._loading2}`
  }
  get whiteOpenLogoAnimation(){
    return `${this._baseUrl}${this._openLogoAnimationWhite}`
  }
  get greenOpenLogoAnimation(){
    return `${this._baseUrl}${this._openLogoAnimationGreen}`
  }
  get loadingImgAnimation(){
    return `${this._baseUrl}${this._loadingImgAnimation}`
  }
  get introSlideOne(){
    return `${this._baseUrl}${this._introSlideOne}`
  }
  get introSlideTwo(){
    return `${this._baseUrl}${this._introSlideTwo}`
  }
  get introSlideThree(){
    return `${this._baseUrl}${this._introSlideThree}`
  }
  get introSlideFour(){
    return `${this._baseUrl}${this._introSlideFour}`
  }
  get underConstruction(){
    return `${this._baseUrl}${this._underConstruction}`
  }
  get gift(){
    return `${this._baseUrl}${this._gift}`
  }
  get openGift(){
    return `${this._baseUrl}${this._openGift}`
  }
  get backgroundImage(){
    return `${this._baseUrl}${this._backgroundImage}`
  }
  get spinnerGif(){
    return `${this._baseUrl}${this._spinnerGif}`
  }
  get confettiGif(){
    return `${this._baseUrl}${this._confettiGif}`
  }
  get fireWorksWav(){
    return `${this._baseUrl}${this._fireWorksWav}`
  }
  constructor() { }
}
