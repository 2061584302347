<ion-toolbar mode="md">
 <ion-item mode="ios">
  <app-outlet-avatar 
  slot="start"
  [outlet]="outletWithDealz"
  ></app-outlet-avatar>
  <ion-label >
    <h2>{{outletWithDealz.name}}</h2>
    <p *ngIf="kmFromUser">{{kmFromUser}}km - {{outletWithDealz.address}}</p>
    <p *ngIf="!kmFromUser">{{outletWithDealz.address}}</p>
  </ion-label>
  <!-- TODO this should open a place preview with photos and such -->
  <!-- <ion-icon name="information-circle" color="light" slot="end"></ion-icon>  -->
  <!-- <ion-icon name="location" color="dark" slot="end"></ion-icon> -->
 </ion-item>
  <swiper 
  #swiper 
  [config]="swiperConfig"
  (swiper)="onSwiper($event)"
  (beforeTransitionStart)="onBeforeTransitionStart($event)">
    <ng-template swiperSlide *ngFor="let deal of outletWithDealz.dealz;" >
     <app-deal-lane-card 
     [deal]="deal"
     [outlet]="outletWithDealz"
     [cardHeight]="cardHeight"
     (onWantsToFlip)="slideNext()"
     [mode]="mode"
     (onDealSelectEmit)="dealSelected($event)"
     >
     </app-deal-lane-card>
    </ng-template>
</swiper>
</ion-toolbar>

