import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { AuthService } from 'src/app/services/auth.service';
import { CloudfunctriggersService } from 'src/app/services/cloudfunctriggers.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { LocationService } from 'src/app/services/location.service';
import { NetworkStatusService } from 'src/app/services/network-status.service';
import { RewardsService } from 'src/app/services/rewards.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { UserChangeService } from 'src/app/services/user-change.service';
import { Subject, takeUntil } from 'rxjs';
import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore';
import { Voucher } from 'src/app/models/interfaces';
import { FIRESTORE_ROOT_COLLECTIONS, FIRESTORE_SUB_COLLECTIONS } from 'src/app/models/enums';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ConfettiService } from 'src/app/services/confetti.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from 'src/app/shared/components/qrcode/qrcode.types';
//import { GetBrightnessReturnValue, ScreenBrightness } from '@capacitor-community/screen-brightness';


@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.page.html',
  styleUrls: ['./qr-code.page.scss'],
})
export class QrCodePage implements OnInit,OnDestroy {
  @Input() qrcodeid:string;
  @Input() voucher:Voucher;
  voucherUnsubscribe:Unsubscribe
  errCorrectionLevels = NgxQrcodeErrorCorrectionLevels;
  elementTypes=NgxQrcodeElementTypes;
 // _startingBrightness:GetBrightnessReturnValue
  constructor(
    public assetLinksService:AssetlinksService,
    public authService:AuthService,
    public rewardsService:RewardsService,
    public dateTimeService:DateTimeService,
    public modalCtrl:ModalController,
    public locationService:LocationService,
    public router: Router,
    public toastCrtl: ToastController,
    public cloudTriggers: CloudfunctriggersService,
    public network: NetworkStatusService,
    public toasts: ToastServiceService,
    public userService:YumdealzUserService,
    public alertService:AlertService,
    private firebase:FirebaseService,
    private confettiService:ConfettiService
  ) { 

  }

  async ngOnInit() {
    this.listenToVoucher();
    // try {
    //   this._startingBrightness = await ScreenBrightness.getBrightness();
    // } catch (error) {
    //   console.log(error);
    // }
    this.trySetBrightness(1)
  }

  private async trySetBrightness(brightness:number){
    // try {
    //   await ScreenBrightness.setBrightness({ brightness });
    // } catch (error) {
    //   console.log(error);
    // }
  }

  ngOnDestroy(): void {
    this.voucherUnsubscribe();
  //  this.trySetBrightness(this._startingBrightness.brightness);
  }

  async listenToVoucher(){
    if(this.voucherUnsubscribe){
      this.voucherUnsubscribe();
    }

    this.voucherUnsubscribe = onSnapshot( doc(this.firebase.getDb(),`${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${this.voucher.userId}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${this.voucher.restaurantUid}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${this.voucher.specialUid}/${FIRESTORE_SUB_COLLECTIONS.VOUCHERS}/${this.voucher.uid}`), async (querySnapshot) => {
      const voucher = querySnapshot.data() as Voucher;
      if(querySnapshot.exists() && voucher.redeemed){
        let alert = await this.alertService.redeemVoucherAlert();
        try {
          let interval = this.confettiService.confettiFireWorks(15);
          alert.onDidDismiss().then(() => {
            clearInterval(interval)
          })
          await this.back()
        } catch (error) {
          console.log(':( no confetti', error);
        }
      }
    });
  }



  explainPage(){
    this.toasts.showToast(
      "Some businesses prefer to manually redeem vouchers. They will ask you to present this qr code so they can redeem your voucher.",
      'Voucher QR Code'
    )
  }

  async back() {
    await this.modalCtrl.dismiss();
  }

}
