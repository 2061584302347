<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-card-header>
      <ng-container >
        <ion-card-title >Sign up</ion-card-title>
      </ng-container>
     </ion-card-header>
  </ion-toolbar>
</ion-header>


<ion-content>
  <div id="formContainer">
    <ion-grid fixed>
      <ion-row>
        <ion-col size-xl="10" offset-xl="1" size-lg="9" offset-lg="1.5" size-md="11"  offset-md="0.5"  size-sm="12" size-xs="12">
          <form (ngSubmit)="signUpWithEmail()" [formGroup]="registerForm">
            <ion-item class="nicerInput" lines='inset'>
              <ion-input  type="email" formControlName="email" clearInput="true"
                placeholder="{{'SIGNUP_PAGE.EMAIL' | translate}}*"></ion-input>
            </ion-item>
            <app-form-control-feedback [control]="registerForm.get('email')"></app-form-control-feedback>
      
            <ion-item class="nicerInput" lines="inset">
              <ion-input formControlName="firstname" clearInput="true"
                placeholder="{{'SIGNUP_PAGE.NAME' | translate}}*"></ion-input>
            </ion-item>
            <app-form-control-feedback [control]="registerForm.get('firstname')"></app-form-control-feedback>
      
            <ion-item class="nicerInput" lines="inset">
              <ion-input formControlName="lastname" clearInput="true"
                placeholder="{{'SIGNUP_PAGE.LAST_NAME' | translate}}*"></ion-input>
            </ion-item>
            <app-form-control-feedback [control]="registerForm.get('lastname')"></app-form-control-feedback>
      
      
            <ion-item class="nicerInput" lines="inset">
              <ion-input type="password" formControlName="pw" clearInput="true"
                placeholder="{{'SIGNUP_PAGE.PASSWORD' | translate}}*"></ion-input>
            </ion-item>
            <app-form-control-feedback [control]="registerForm.get('pw')"
              [translateNameKey]="'PASSWORD'"></app-form-control-feedback>
      
            <ion-item class="nicerInput" lines="inset">
              <ion-input type="password" formControlName="ppw_confirm" clearInput="true"
                placeholder="{{'SIGNUP_PAGE.CONFIRM_PASSWORD' | translate}}*"></ion-input>
            </ion-item>
            <app-form-control-feedback [control]="registerForm.get('ppw_confirm')"
              [translateNameKey]="'PASSWORD'"></app-form-control-feedback>
      
            <!-- <ion-item lines="none">
            <ion-checkbox (ionChange)="chnageAgreesToTerms($event)" slot="start"></ion-checkbox>
            <ion-label>I agree to the terms and conditions</ion-label>
          </ion-item> -->
      
            <ion-row class="ion-padding">
              <ion-col style="text-align: center;" size="12">
                <ion-button type="submit" [disabled]="!registerForm.valid || !agreesToTerms" expand="block" color="primary">
                  {{"SIGNUP_PAGE.SUBMIT" | translate}}
                </ion-button>
              </ion-col>
            </ion-row>
          </form>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-text style="text-align: center;">
            <p style="margin: 0;">{{"SIGNUP_PAGE.ALREADY_HAVE_AN_ACCOUNT" | translate}}?</p>
          </ion-text>
        </ion-col>
        <ion-col size="12">
          <ion-button fill="clear" expand="block" (click)="goToLogin()">
            {{"SIGNUP_PAGE.LOGIN" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

<ion-footer>
  <app-legal-footer-links [color]="'dark'"></app-legal-footer-links>
</ion-footer>