export const environment = {
  production: true,
  appVersion:'4.0.1',
  type:'PROD',
  placesApiKey:'AIzaSyDZv6Hmm3ziSFPB3xtOYp_uHTuPRx_TgOo',
  firebaseConfig :{
    apiKey: "AIzaSyDRMSqFnbLl7P_Q66X6SpGk2ecax-xIuB4",
    authDomain: "mealdealz-a23e7.firebaseapp.com",
    projectId: "mealdealz-a23e7",
    storageBucket: "mealdealz-a23e7.appspot.com",
    messagingSenderId: "107441941863",
    appId: "1:107441941863:web:d4898e87196b770a9193c2",
    measurementId: "G-ZP2CKCP5WL",
    vapidKey:"BGNorCSHVtvFlJY-D9OnF2xuHHQIVrrxrbj-cNQy1GFHZaXUvTNBlj4bsfj_qny5dV8hMrS9ZEO26hcCqJn4UXs"
  },
  iosSignUpConfig:{
    clientId: 'io.mealdealz.vproductions',
    redirectURI: 'https://mealdealz-a23e7.firebaseapp.com/__/auth/handler',
    scopes: 'email name',
    state: '12345',
    nonce: 'jshrgukxv_ofjsk',
  },
  onesignalAppId:'0a621da2-17ce-434f-ab04-9cb9908a7c29'
};
