import { Injectable } from '@angular/core';
import {
  collectionGroup,
  CollectionReference,
  deleteDoc,
  addDoc,
  doc, DocumentData, DocumentReference, DocumentSnapshot, endAt, Firestore,
  getDoc, getDocs, onSnapshot, orderBy, query, runTransaction, setDoc, startAt, Transaction, updateDoc, where
} from 'firebase/firestore';
import { collection, Query, Timestamp } from 'firebase/firestore';
import { FIRESTORE_ROOT_COLLECTIONS, FIRESTORE_SUB_COLLECTIONS, LoyaltyProgrammeVariants, supportedProductTypes } from '../models/enums';
import * as geofire from 'geofire-common'
import { PushTokenDoc, Shop, Story, Voucher, WalletLoyaltyCard, YumDealzUser, Stamp, Puck, Outlet, IProduct, ProductEngagement, TakealotVoucher, Reward, ScanType,StampSession, IndefiniteLoyaltyProgramme, CampaignLoyaltyProgramme, LoyaltyProgramme, ScanError } from '../models/interfaces';
import { ToastServiceService } from './toast-service.service';
import { Unsubscribe, User } from 'firebase/auth';
import { PreferencesService } from './preferences.service';
import { UserChangeService } from './user-change.service';
import { environment } from '../../environments/environment';
import { DateTimeService } from './date-time.service';
import { FirebaseService } from './firebase.service';
import { BehaviorSubject } from 'rxjs';
import { GisService } from './gis.service';
import { TranslationService } from './translation.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private yumDealzUserDocRef: DocumentReference<DocumentData>
  private merchantUserDocRef: DocumentReference<DocumentData>
  private userAllLoyaltyCardsRef: CollectionReference<DocumentData>
  private stampSessionsCollectionRef: CollectionReference<DocumentData>
  private pushTokensCollectionRef: CollectionReference<DocumentData>
  private planConfigsCollectionRef:CollectionReference<DocumentData>
  private puckCollectionRef: CollectionReference<DocumentData>
  private takealotVouchersCollectionRef: CollectionReference<DocumentData>
  private rewardsCollectionRef: CollectionReference<DocumentData>
  private userTakealotVouchersRef: Query<DocumentData>
  private scanErrorsCollectionRef:CollectionReference<DocumentData>
  private yumDealzUserPushTokensRef: Query<DocumentData>
  private userIncompleteLoyaltyCardsRef: Query<DocumentData>
  private allDealzCollectionRef: Query<DocumentData>
  private allStoryDealzCollectionRef: Query<DocumentData>
  private activeDealzCollectionRef: Query<DocumentData>
  private activeStoryDealzCollectionRef: Query<DocumentData>
  private restaurantsCollectionRef: Query<DocumentData>
  private verifiedRestaurantsCollectionRef: Query<DocumentData>
  private outletsCollectionRef: Query<DocumentData>
  private verifiedOutletsCollectionRef: Query<DocumentData>
  private stampsCollectionRef: Query<DocumentData>
  private vouchersCollectionRef: Query<DocumentData>
  private userVouchersRef: Query<DocumentData>
  private clicksCollectionRef: CollectionReference<DocumentData>
  private likesCollectionRef: CollectionReference<DocumentData>
  private viewsCollectionRef: CollectionReference<DocumentData>
  private userUid: string;
  private firestore: Firestore;
  private listenerUnsubscribes: {
    userVouchers: Unsubscribe,
    userLoyaltyCards: Unsubscribe,
    browsingShops: Unsubscribe,
    userTakealotVouchers:Unsubscribe
  } = {
      userVouchers: null,
      userLoyaltyCards: null,
      browsingShops: null,
      userTakealotVouchers:null
    }
  constructor(
    private firebaseService: FirebaseService,
    private userChangeService: UserChangeService,
    private toastsService: ToastServiceService,
    private preferences: PreferencesService,
    private dateTimeService: DateTimeService,
    private gisService: GisService,
    private ts: TranslationService
  ) {
    this.firestore = this.firebaseService.getDb();
    this.userChangeService.user$.subscribe(async (user) => {
      if (!user) return;
      this.userUid = user.uid;
      this.stampSessionsCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.STAMP_SESSIONS);
      this.planConfigsCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PLAN_CONFIGS);
      this.pushTokensCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PUSH_TOKENS);
      this.clicksCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PRODUCT_CLICKS);
      this.likesCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PRODUCT_LIKES);
      this.viewsCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PRODUCT_VIEWS);
      this.takealotVouchersCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.TAKEALOT_VOUCHERS)
      this.rewardsCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.REWARDS)
      this.puckCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PUCKS);
      this.scanErrorsCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.SCAN_ERRORS);
      this.yumDealzUserPushTokensRef = query(this.pushTokensCollectionRef, where('userId', '==', this.userUid));
      this.userTakealotVouchersRef = query(this.takealotVouchersCollectionRef, where('user_id', '==', this.userUid));
      this.yumDealzUserDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.YUM_DEALS_USERS}/${this.userUid}`);
      this.merchantUserDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${this.userUid}`);
      this.userAllLoyaltyCardsRef = collection(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}`);
      this.userIncompleteLoyaltyCardsRef = query(this.userAllLoyaltyCardsRef, where('complete', '==', false));
      this.restaurantsCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.RESTAURANTS);
      this.verifiedRestaurantsCollectionRef = query(this.restaurantsCollectionRef,where('verified', '==', true));
      this.outletsCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.OUTLETS); 
      this.verifiedOutletsCollectionRef = query(this.outletsCollectionRef,where('verified', '==', true)); 
      this.stampsCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.STAMPS);
      this.allDealzCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.SPECIALS);
      this.activeDealzCollectionRef = query(this.allDealzCollectionRef, where('active', '==', true));
      this.allStoryDealzCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.STORIES);
      this.activeStoryDealzCollectionRef = query(this.allStoryDealzCollectionRef, where('active', '==', true));
      this.vouchersCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.VOUCHERS);
      this.userVouchersRef = query(this.vouchersCollectionRef, where('claimerUId', '==', this.userUid), where('redeemed', '==', false));
      await this.initUser(user)
    });
  }

  /**
   * only need to call this once.
   * If called more than once it will cancel the previous 
   * listener and replace it with a new one
   * @param subject 
   */
  listenToUserVouchers(subject: BehaviorSubject<Voucher[]>) {
    const refq = query(this.userVouchersRef, where('expirationDate_unix_utc', '>', Date.now()))
    if (this.listenerUnsubscribes.userVouchers) {
      this.listenerUnsubscribes.userVouchers();
    }
    this.listenerUnsubscribes.userVouchers = onSnapshot(refq, (querySnapshot) => {
      const vouchers: Voucher[] = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          vouchers.push(doc.data() as Voucher);
        }
      });
      vouchers.sort((a,b)=>{
        return a.expirationDate_unix_utc > b.expirationDate_unix_utc ? 1 : a.expirationDate_unix_utc==b.expirationDate_unix_utc ? 0 : -1
      })
      subject.next(vouchers);
    });
  }

  async startStampSession(card:WalletLoyaltyCard,puck:Puck){
    if(card.participant_id != this.userUid) {
      throw new Error("card user id and logged in user id does not match");
    }

    const session:StampSession = {
      session_start_unix_utc: Date.now(),
      yum_dealz_user_id:this.userUid,
      shop_user_id:card.userId,
      loyaltyProgramme_id:card.special_id,
      card_id:card.id,
      outlet_id:puck.outlet_id,
      shop_id:card.restaurantId
    }
    await setDoc(doc(this.stampSessionsCollectionRef,card.participant_id),session)
    return this.userUid
  }

  async endStampSession(){
      await deleteDoc(
        doc(this.firestore,`${this.stampSessionsCollectionRef.path}/${this.userUid}`)
      )
  }

  
    /**
   * only need to call this once.
   * If called more than once it will cancel the previous 
   * listener and replace it with a new one
   * @param subject 
   */
    listenToUserTakealotVouchers(subject: BehaviorSubject<TakealotVoucher[]>) {
      if (this.listenerUnsubscribes.userTakealotVouchers) {
        this.listenerUnsubscribes.userTakealotVouchers();
      }
      this.listenerUnsubscribes.userTakealotVouchers = onSnapshot(this.userTakealotVouchersRef, (querySnapshot) => {
        const vouchers: TakealotVoucher[] = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            vouchers.push(doc.data() as TakealotVoucher);
          }
        });
        subject.next(vouchers);
      });
    }

    async deleteTakealotVoucher(voucher:TakealotVoucher){
      await deleteDoc(
        doc(this.firestore,`${this.takealotVouchersCollectionRef.path}/${voucher.id}`)
      )
    }


  /**
   * only need to call this once.
   * If called more than once it will cancel the previous 
   * listener and replace it with a new one
   * @param subject 
   */
  listenToYumDealzUserIncompleteLoyaltyCards(subject: BehaviorSubject<WalletLoyaltyCard[]>) {
    const refq = query(this.userIncompleteLoyaltyCardsRef, where('expiryDate', '>', this.dateTimeService.todayNowUtcUnixMilisecs()))
    if (this.listenerUnsubscribes.userLoyaltyCards) {
      this.listenerUnsubscribes.userLoyaltyCards();
    }
    this.listenerUnsubscribes.userLoyaltyCards = onSnapshot(refq, (querySnapshot) => {
      const walletLoyaltyCards: WalletLoyaltyCard[] = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          walletLoyaltyCards.push(doc.data() as WalletLoyaltyCard);
        }
      });
      subject.next(walletLoyaltyCards);
    });
  }


  private async addPushToken(token: string, notificationsEnabled: boolean) {
    const data: PushTokenDoc = {
      token,
      userId: this.userUid,
      timestamp: Timestamp.fromDate(new Date()),
      notificationsEnabled
    }
    await addDoc(this.pushTokensCollectionRef, data)
  }

  async tryUploadScanError(
    msg:string,
    action:string, 
    scannedKey:string,
    scannedValue:string,
    scanType:ScanType,
  ){
    try {
      const data:ScanError = {
        msg,
        scannedValue,
        scannedKey,
        scanType,
        app:'YumDealz',
        appVersion:environment.appVersion,
        action,
        timestamp: Timestamp.fromDate(new Date()),
        userId:this.userUid
      }
      console.log('uplading error');
      console.log(data);
      
      await addDoc(this.scanErrorsCollectionRef, data)
    } catch (error) {
      console.log(error);
    }
  }

  private async refreshPushToken(existingTokenDoc: DocumentReference<DocumentData>, notificationsEnabled: boolean) {
    const data = {
      timestamp: Timestamp.fromDate(new Date()),
      notificationsEnabled
    }
    await updateDoc(existingTokenDoc, data);
  }

  async refreshOrAddPushToken(token: string, notificationsEnabled: boolean) {
    let snapShot = await getDocs(this.yumDealzUserPushTokensRef);
    let existingTokenDoc: DocumentReference<DocumentData>;
    snapShot.docs.forEach(doc => {
      let tokenDoc = doc.data() as PushTokenDoc;
      if (tokenDoc.token == token) {
        existingTokenDoc = doc.ref
      }
    })

    if (existingTokenDoc) {
      await this.refreshPushToken(existingTokenDoc, notificationsEnabled)
    } else {
      await this.addPushToken(token, notificationsEnabled);
    }
  }

  async rmPushToken(token: string) {
    let snapShot = await getDocs(this.yumDealzUserPushTokensRef);
    let docToDeleteRef
    snapShot.docs.forEach(doc => {
      if (doc.exists()) {
        let tokenDoc = doc.data() as PushTokenDoc;
        if (tokenDoc.token == token) {
          docToDeleteRef = doc.ref
        }
      }
    })
    if (docToDeleteRef) {
      return deleteDoc(docToDeleteRef);
    }
  }

  async fetchRestaurantOwnerDocData(resUserId) {
    let document = await getDoc(doc(this.firestore, `users/${resUserId}`))
    return document.data()
  }

  async fetchPuck(puckId:string){
      const docRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.PUCKS}/${puckId}`);
      const puckDoc = await getDoc(docRef);
      const puck = this.givePuckObjectFromFirebasePuckDoc(puckDoc);
      return puck;
  }

  async getShopActiveStories(shopId: string) {
    const storiesSnapShot = await getDocs(
      query(this.activeStoryDealzCollectionRef, where('restaurant_id', '==', shopId))
    );
    const shopStories: Story[] = [];
    for (const doc of storiesSnapShot.docs) {
      const story = this.giveStoryObjectFromFirebaseStoryDoc(doc)
      shopStories.push(story)
    }
    shopStories.sort((a, b) => {
      if (a.date_updated_unix_utc > b.date_updated_unix_utc) {
        return -1;
      } else if (a.date_updated_unix_utc < b.date_updated_unix_utc) {
        return 1; 
      }
      return 0; 
    });
    return shopStories;
  }

  async getShopActiveDealz(shopId:string) {
    const specialsSnapShot = await getDocs(
      query(this.activeDealzCollectionRef, where('restaurant_id', '==', shopId))
    );
    const shopSpecials: (CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[] = [];
    for (const doc of specialsSnapShot.docs) {
      if(doc.exists()){
        const special = this.giveSpecialObjectFromFirebaseSpecialDoc(doc)
        shopSpecials.push(special)
      }
    }
    return shopSpecials;
  }

  async fetchRewards(){
    const snap = await getDocs(this.rewardsCollectionRef)
    return snap.docs.map((doc)=>{
      return doc.data() as Reward
    })
  }


  async newLoyaltyCard(businessOwnerId: string, restaurantId: string, special_id: string, expiryDate: number,outletId:string) {
    let date_added_unix_utc_timestamp = this.dateTimeService.todayNowUtcUnixMilisecs();
    let collectionRef = collection(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}`)
    const docRef = doc(collectionRef)
    let card: WalletLoyaltyCard = {
      id: docRef.id,
      userId: businessOwnerId,
      restaurantId: restaurantId,
      complete: false,
      participant_id:this.userUid,
      special_id: special_id,
      expiryDate,
      date_added_unix_utc_timestamp,
      stickers_count: 0,
      outlet_id:outletId
    }
    await setDoc(docRef, card)
  }

  async fetchUserLoyaltyCardDocData(cardId) {
    let document = await getDoc(doc(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}/${cardId}`))
    return document.data()
  }


  async getStampTransaction(
    special: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme,
    loyaltyCard: WalletLoyaltyCard,
    lat: number,
    lng: number,
    gps_accuracy:number,
    outlet:Outlet,
    puckId:string,
    scanType:ScanType
  ) {

    const docUpdates = [];
    let newCount: number = null;

    loyaltyCard.stickers_count < (special.stamps_required - 1) ?
    newCount = loyaltyCard.stickers_count + 1 :
    newCount = null;

    const loyaltyCardUpdate = async (transaction: Transaction) => {
      const data = { stickers_count: newCount, complete: newCount ? false : true }
      let ref = doc(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}/${loyaltyCard.id}`)
      await transaction.update(ref, data);
    }

    const giveStamp = async (transaction: Transaction) => {
      const data:Stamp = {
        lat,
        lng,
        // sometimes location is on and granted but gps was slow to respond. for UX we rather not wait.. but ugh...
        geohash: lat && lng ? this.gisService.makeGeoHashFromLatLng({ lat, lng }) : null,
        gps_accuracy: gps_accuracy? gps_accuracy: null,
        timestamp_unix_utc: this.dateTimeService.todayNowUtcUnixMilisecs(),
        card_id: loyaltyCard.id,
        deal_id: special.id,
        shop_id: special.restaurant_id,
        puck_id: puckId,
        shop_user_id: special.user_uid,
        user_id: this.userUid,
        outlet_id:outlet.id,
        scanType
      } 
      let ref = doc(collection(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}/${loyaltyCard.id}/${FIRESTORE_SUB_COLLECTIONS.STAMPS}`))
      await transaction.set(ref, data);
    }

    const voucherUpdate = async (transaction: Transaction) => {
      let date = new Date();
      let thisDay = date.getUTCDate();
      let thisYear = date.getUTCFullYear();
      let thisMonth = date.getUTCMonth();
      let utc_start_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 0, 0, 0, 0);
      let utc_end_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 23, 59, 59, 999);
      let collectionRef = collection(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${special.user_uid}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${special.restaurant_id}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${special.id}/${FIRESTORE_SUB_COLLECTIONS.VOUCHERS}`)

      const giveVoucherExpiryDate = ()=>{
        switch (special.variant) {
          case LoyaltyProgrammeVariants.CAMPAIGN:
            special = special as CampaignLoyaltyProgramme
            const dayAmountToMillis = (days:number) => {
              if(!days){
                days=0;
              }
              const millisecondsInDay = 86400000; // 24 * 60 * 60 * 1000
              return days * (millisecondsInDay-1000); // remove one second (-1000)
            }
            return special.campaign_end + dayAmountToMillis(special.voucher_days_active_post_campaign)
          case LoyaltyProgrammeVariants.INDEFINITE:
            special = special as IndefiniteLoyaltyProgramme
            return utc_end_of_day_unix + special.voucherExpiresAfter
          default:
            throw new Error('Invalid Loyalty programme variant')
        }
      }

      const docRef = doc(collectionRef)
      let data:Voucher = {
        uid: docRef.id,
        claimerUId: this.userUid,
        specialLat: outlet.lat,
        restaurantUid: special.restaurant_id,
        userId: special.user_uid,
        redeemed: false,
        redeemed_on:null,
        redeemed_at_outlet_id:null,
        redemption_lat:null,
        redemption_lng:null,
        redemption_geohash:null,
        activation_lat:lat,
        activation_lng:lng,
        // sometimes location is on and granted but gps was slow to respond. for UX we rather not wait.. but ugh...
        activation_geohash: lat && lng ? this.gisService.makeGeoHashFromLatLng({ lat, lng }) : null,
        notificationRadius: 0,
        specialLng: outlet.lng,
        specialUid: special.id,
        activationDate: {
          dayStart_unix_utx: utc_start_of_day_unix,
          dayEnd_unix_utx: utc_end_of_day_unix
        },
        claimed: true,
        outlet_id:outlet.id,
        expirationDate_unix_utc: giveVoucherExpiryDate()
      }
      await transaction.set(docRef, data);
    }

    docUpdates.push(loyaltyCardUpdate);
    docUpdates.push(giveStamp);

    if (newCount == null) {
      docUpdates.push(voucherUpdate)
    }


    return runTransaction(this.firestore, async (transaction) => {
      try {
        for (const docUpdate of docUpdates) {
          await docUpdate(transaction);
        }
       
        return newCount
      } catch (error) {
        await this.toastError(error);
        throw error;
      }
    })
  }

  async redeemVoucher(voucher: Voucher, redeemedOnUnix: number,lat: number, lng: number,outlet:Outlet) {
    const voucherUpdate = async (transaction: Transaction) => {
      let redemption_geohash = null
      try {
         /**
         *  sometimes location is on and granted but gps was slow to respond. for UX we rather not wait.. but ugh...
         */
        if(lat && lng ){
          redemption_geohash = this.gisService.makeGeoHashFromLatLng({ lat, lng }) 
        }
      } catch (error) {
        console.log(error);
      }

      const redemptionData:Pick<Voucher,'redeemed'|'redeemed_on'|'redeemed_at_outlet_id'|'redemption_lat'|'redemption_lng'|'redemption_geohash'> = { 
        redeemed: true, 
        redeemed_on: redeemedOnUnix,
        redeemed_at_outlet_id:outlet.id,
        redemption_lat:lat,
        redemption_lng:lng,
        redemption_geohash 
       }
      const voucherDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${voucher.userId}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${voucher.restaurantUid}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${voucher.specialUid}/${FIRESTORE_SUB_COLLECTIONS.VOUCHERS}/${voucher.uid}`)
      await transaction.update(voucherDocRef, redemptionData);
    }

    return runTransaction(this.firestore, async (transaction) => {
      try {
        await voucherUpdate(transaction)
        // If all operations are successful, the transaction will be committed
      } catch (error) {
        // Handle transaction failure or specific error cases
        await this.toastError(error);
        throw error;
      }
    })
  }

  async initUser(firebaseUser: User) {
    const doc = await getDoc(this.yumDealzUserDocRef)
    const potentialUser = doc.data() as YumDealzUser
    if (!potentialUser) {
      const data = this.giveNewUserObjectFromFirebaseUser(firebaseUser);
      await setDoc(this.yumDealzUserDocRef, data);
      const newUser = { ...data }
      await this.preferences.saveUserIdAndEmail(newUser.uniqueDeviceId, newUser.email);
      return newUser
    }

    await this.preferences.saveUserIdAndEmail(potentialUser.uniqueDeviceId, potentialUser.email)
    let platform = null;
    try {
      platform = Capacitor.getPlatform()
    } catch (error) {
      console.log(error);
    }
    const existingUser: YumDealzUser = {
      uniqueDeviceId: doc.id,
      yum_points: potentialUser.yum_points,
      displayName: potentialUser.displayName? potentialUser.displayName :firebaseUser.displayName,
      email: firebaseUser.email,
      emailVerified: firebaseUser.emailVerified,
      providerId: firebaseUser.providerId,
      phoneNumber:potentialUser.phoneNumber? potentialUser.phoneNumber : firebaseUser.phoneNumber,
      photoURL: firebaseUser.photoURL,
      likedSpecials: potentialUser.likedSpecials,
      pushToken: potentialUser.pushToken,
      suspicious:potentialUser.suspicious ? potentialUser.suspicious : false,
      lastSearchLocation: potentialUser.lastSearchLocation ? potentialUser.lastSearchLocation : null,
      notificationArea: potentialUser.notificationArea ? potentialUser.notificationArea : {
        geohash: '',
        radius: 5,
        lat: 0,
        lng: 0,
      },
      scanPreference:potentialUser.scanPreference?potentialUser.scanPreference:null,
      lastTimeVisited: Timestamp.fromDate(new Date()),
      installedVersion: environment.appVersion,
      platform
    }
    const user: YumDealzUser = existingUser
    // await this.editUser(user)
    this.editUser(user)
    return user as YumDealzUser
  }

  async editUser(data: DocumentData) {
    await updateDoc(this.yumDealzUserDocRef, data);
  }

  async deleteUser() {
    await updateDoc(this.yumDealzUserDocRef, { markedForDeletion: true } as DocumentData);
  }

  async fetchPossibleMerchantUser() {
    return await getDoc(this.merchantUserDocRef);
  }

  async refreshUser() {
    let user = await getDoc(this.yumDealzUserDocRef)
    if (user.exists) {
      return user.data() as YumDealzUser
    }
  }

  async fetchYumDealzUser(userId?: string) {
    if (userId) {
      let docRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.YUM_DEALS_USERS}/${userId}`)
      const document = await getDoc(docRef)
      return document.data() as YumDealzUser
    } else {
       const document = await getDoc(this.yumDealzUserDocRef);
       return document.data() as YumDealzUser
    }
  }

  async fetchYumDealzUserVouchers() {
    return getDocs(this.userVouchersRef);
  }

  fetchAllVerifiedOutletsAtLocation(location: [number, number], radiusInM: number,forProduct?:supportedProductTypes) {
    let baseQuery = query(this.verifiedOutletsCollectionRef);
    if(forProduct){
      baseQuery = query(
        baseQuery,
        where(`${forProduct}`,'==',true)
      )
    }

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geofire.geohashQueryBounds(location, radiusInM);
    const promises = [];

    for (const b of bounds) {
      const geoQuery = query(
        baseQuery,
        orderBy('geohash'),
        startAt(b[0]), endAt(b[1])
      )
      promises.push(getDocs(geoQuery));
    }

    // Collect all the query results together into a single list
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs: Outlet[] = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('lat');
          const lng = doc.get('lng');
          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], location);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            let outlet = this.giveOutletsObjectFromFirebaseShopDoc(doc)
            matchingDocs.push(outlet);
          }
        }
      }

      return matchingDocs
    })
  }

  async fetchaSpecial(shopOwnerId: string, restaurantId: string, specialId: string) {
    const docRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${shopOwnerId}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${restaurantId}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${specialId}`)
    const specialDoc = await getDoc(docRef)
   if(specialDoc.exists()){
    const special = this.giveSpecialObjectFromFirebaseSpecialDoc(specialDoc)
    return special;
   }
  }

  async getShopWithOutlet(outlet: Outlet) {
    let shopRef = doc(this.firestore,`users/${outlet.user_uid}/restaurants/${outlet.shop_id}`)
    const shopDoc = await getDoc(shopRef)
    return this.giveShopObjectFromFirebaseShopDoc(shopDoc)
  }

  async getShopWithId(shopId: string) {
    const snap = await getDocs(
      query(this.verifiedRestaurantsCollectionRef,where('id','==',shopId))
    )
    if(snap.empty){
      return null
    }
    const shopDoc = snap.docs[0];
    return this.giveShopObjectFromFirebaseShopDoc(shopDoc);
  }

  async getShopWithPuck(puck: Puck) {
    let shopRef = doc(this.firestore,`users/${puck.shop_user_id}/restaurants/${puck.shop_id}`)
    const shopDoc = await getDoc(shopRef)
    return this.giveShopObjectFromFirebaseShopDoc(shopDoc)
  }

  async getOutletWithPuck(puck: Puck) {
    let outletRef = doc(this.firestore,`users/${puck.shop_user_id}/restaurants/${puck.shop_id}/outlets/${puck.outlet_id}`)
    const outletDoc = await getDoc(outletRef)
    return this.giveOutletsObjectFromFirebaseShopDoc(outletDoc)
  }

  async getOutletWithId(userId:string,shopId:string,outletId:string) {
    let outletRef = doc(this.firestore,`users/${userId}/restaurants/${shopId}/outlets/${outletId}`)
    const outletDoc = await getDoc(outletRef)
    return this.giveOutletsObjectFromFirebaseShopDoc(outletDoc)
  }

  private async downloadRestaurantsRef(ref: Query<DocumentData>) {
    const restaurantResponse = await getDocs(ref)
    let restaurants: Shop[] = [];
    let docs = restaurantResponse.docs;
    docs.forEach((doc) => {
      restaurants.push(this.giveShopObjectFromFirebaseShopDoc(doc));
    });
    return restaurants;
  }

  private async downloadOutletsRef(ref: Query<DocumentData>) {
    const outletResponse = await getDocs(ref)
    let outlets: Outlet[] = [];
    let docs = outletResponse.docs;
    docs.forEach((doc) => {
      outlets.push(this.giveOutletsObjectFromFirebaseShopDoc(doc));
    });
    return outlets;
  }

  private giveNewUserObjectFromFirebaseUser(user) {
    let platform
    try {
      platform = Capacitor.getPlatform()
    } catch (error) {
      console.log(error);
    }
    return {
      uniqueDeviceId: user.uid,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      providerId: user.providerId,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      likedSpecials: [],
      pushToken: null,
      notificationArea: {
        geohash: '',
        radius: 5,
        lat: 0,
        lng: 0,
      },
      yum_points:0,
      scanPreference:user.scanPreference ? user.scanPreference: null,
      lastTimeVisited: Timestamp.fromDate(new Date()),
      installedVersion: environment.appVersion,
      platform
    } as YumDealzUser
  }

  private givePuckObjectFromFirebasePuckDoc(puckDoc: DocumentSnapshot<DocumentData>){
    const data = puckDoc.data() as Puck
    const puck: Puck = {
      id: data.id,
      shop_user_id: data.shop_user_id,
      legacy:data.legacy?true:false,
      shop_id: data.shop_id,
      outlet_id: data.outlet_id,
      tag_serial_nr: data.tag_serial_nr,
      active:data.active,
      claimed:data.claimed,
      qr_disabled:data.qr_disabled,
      prefer_stamp_session:data.prefer_stamp_session,
      name:data.name,
      ready:data.ready,
    };
    return puck
  }

  private giveSpecialObjectFromFirebaseSpecialDoc(specialDoc: DocumentSnapshot<DocumentData>) {
    let data = specialDoc.data() as CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme;
    const special: LoyaltyProgramme= {
      name: data['name'],
      description: data['description'],
      descriptive_tag: data['descriptive_tag'],
      active: data['active'],
      picture: data['picture'],
      likes: data['likes'],
      days: data['days'],
      stamps_required: data.stamps_required,
      reward_cost:data.reward_cost,
      stamp_cost:data.stamp_cost,
      verified:data.verified,
      saturday_trading_from: data.saturday_trading_from,
      saturday_trading_to: data.saturday_trading_to,
      sunday_trading_from: data.sunday_trading_from,
      sunday_trading_to: data.sunday_trading_to,
      trading_from: data.trading_from,
      trading_to: data.trading_to,
      lat: data.lat,
      lng: data.lng,
      geohash: data['geohash'],
      id: specialDoc.id,
      clicks: data['clicks'],
      user_uid: data['user_uid'],
      restaurant_id: data['restaurant_id'],
      restaurant_name: data['restaurant_name'],
      type: data['type'],
      variant:data.variant
    };

    switch (special.variant) {
      case LoyaltyProgrammeVariants.CAMPAIGN:
        data = data as CampaignLoyaltyProgramme;
        const campaignSpecial:CampaignLoyaltyProgramme = {
          ...special,
          campaign_end: data.campaign_end,
          campaign_start: data.campaign_start,
          voucher_days_active_post_campaign:data.voucher_days_active_post_campaign,
        } as CampaignLoyaltyProgramme;
        return campaignSpecial
      case LoyaltyProgrammeVariants.INDEFINITE:
        data = data as IndefiniteLoyaltyProgramme;
        const indefiniteSpecial = {
          ...special,
          expiresAfter: data.expiresAfter,
          voucherExpiresAfter: data.voucherExpiresAfter,
        } as IndefiniteLoyaltyProgramme;
        return indefiniteSpecial
      default:
        throw new Error("Invalid Loyalty programme variant");
    }
  }

  giveOutletsObjectFromFirebaseShopDoc(outletDoc: DocumentSnapshot<DocumentData>) {
    const data = outletDoc.data() as Outlet
    let outletData: Outlet = {
      name: data['name'],
      lat: data['lat'],
      lng: data['lng'],
      geohash: data['geohash'],
      revision: data['revision'],
      holiday_trading_from: data['holiday_trading_from'],
      holiday_trading_to: data['holiday_trading_to'],
      saturday_trading_to: data['saturday_trading_to'],
      saturday_trading_from: data['saturday_trading_from'],
      sunday_trading_to: data['sunday_trading_to'],
      sunday_trading_from: data['sunday_trading_from'],
      trading_to: data['trading_to'],
      trading_from: data['trading_from'],
      normal_trading_days: data['normal_trading_days'],
      id: data['id'],
      shop_id:data['shop_id'],
      image:data['image'],
      contact_number: data['contact_number'],
      address: data['address'],
      alerts_email: data['alerts_email'],
      stamp_spam_alerts_threshold: data['stamp_spam_alerts_threshold'],
      receive_stamp_spam_alerts:data['receive_stamp_spam_alerts'],
      email: data['email'],
      verified: data['verified'],
      yum_points:data['yum_points'],
      user_uid: data['user_uid'],
      [supportedProductTypes.LOYALTY_PROGRAMMES]:data[`${supportedProductTypes.LOYALTY_PROGRAMMES}`],
      [supportedProductTypes.STORIES]:data[`${supportedProductTypes.STORIES}`],
    }
    return outletData
  }

  private giveShopObjectFromFirebaseShopDoc(shopDoc: DocumentSnapshot<DocumentData>) {
    const data = shopDoc.data() as Shop
    let shopData: Shop = {
      name: data['name'],
      lat: data['lat'],
      lng: data['lng'],
      geohash: data['geohash'],
      whatsapp: data['whatsapp'],
      facebook: data['facebook'],
      revision: data['revision'],
      instagram: data['instagram'],
      website: data['website'],
      logo: data['logo'],
      holiday_trading_from: data['holiday_trading_from'],
      holiday_trading_to: data['holiday_trading_to'],
      saturday_trading_to: data['saturday_trading_to'],
      saturday_trading_from: data['saturday_trading_from'],
      sunday_trading_to: data['sunday_trading_to'],
      sunday_trading_from: data['sunday_trading_from'],
      trading_to: data['trading_to'],
      trading_from: data['trading_from'],
      normal_trading_days: data['normal_trading_days'],
      id: data['id'],
      contact_number: data['contact_number'],
      address: data['address'],
      email: data['email'],
      verified: data['verified'],
      user_uid: data['user_uid'],
    }
    return shopData
  }

  private giveStoryObjectFromFirebaseStoryDoc(storyDoc: DocumentSnapshot<DocumentData>) {
    const data = storyDoc.data() as Story
    const story: Story = {
      name: data.name,
      description: data.description,
      id: storyDoc.id,
      clicks: data.clicks,
      likes:data.likes,
      views: data.views,
      picture: data.picture,
      restaurant_id: data.restaurant_id,
      verified:data.verified,
      active:data.active,
      restaurant_name:data.restaurant_name,
      type:data.type,
      user_uid: data.user_uid,
      date_updated_unix_utc: data.date_updated_unix_utc,
      link:data.link
    };
    return story
  }

 

  async engageProduct(type:'like'|'view'|'click',latLng:{lat:number,lng:number},geohash:string,product:IProduct,outletId:string){
    let newDoc:DocumentReference<DocumentData>
    switch (type) {
      case 'view':
        newDoc =  doc(this.viewsCollectionRef)
        break;
      case 'like':
        newDoc =   doc(this.likesCollectionRef)
        break;
      case 'click':
        newDoc =  doc(this.clicksCollectionRef)
      break;
      default:
        throw new Error("type not supported");
        
    }
    const uploadData:ProductEngagement = {
      id:newDoc.id,
      lat: latLng?latLng.lat:null,
      lng: latLng?latLng.lng:null,
      geohash:geohash?geohash:null,
      product_type:product.type,
      product_id:product.id,
      user_id:this.userUid,
      shop_id:product.restaurant_id,
      outlet_id:outletId,
      timestamp_unix_utc:this.dateTimeService.todayNowUtcUnixMilisecs()
    }
    await setDoc(newDoc,uploadData)
  }

  private async toastError(error) {
    let message = '';
    switch (error.code) {
      case 'cancelled':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_WAS_CANCELLED');
        break;
      case 'unknown':
        message = this.ts.getLocalizedValue('ERRORS.UNKNOWN_ERROR_OCCURRED');
        break;
      case 'invalid-argument':
        message = this.ts.getLocalizedValue('ERRORS.ONE_OR_MORE_ARGS_INVALID');
        break;
      case 'deadline-exceeded':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_TIMED_OUT');
        break;
      case 'not-found':
        message = this.ts.getLocalizedValue('ERRORS.REQUESTED_DOCUMENT_OR_COLLECTION_NOT_FOUND');
        break;
      case 'already-exists':
        message = this.ts.getLocalizedValue('ERRORS.DOCUMENT_OR_COLLECTION_ALREADY_EXISTS');
        break;
      case 'permission-denied':
        message = this.ts.getLocalizedValue('ERRORS.YOU_DONT_HAVE_PERMS_TO_COMPLETE_ACTION');
        break;
      case 'resource-exhausted':
        message = this.ts.getLocalizedValue('ERRORS.RESOURCE_HAS_BEEN_EXHAUSTED');
        break;
      case 'failed-precondition':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_REJECTED_DUE_TO_SYSTEM_STATE');
        break;
      case 'aborted':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_WAS_ABORTED');
        break;
      case 'out-of-range':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_ATTEMPTED_PAST_VALID_RANGE');
        break;
      case 'unimplemented':
        message = this.ts.getLocalizedValue('ERRORS.REQUESTED_FEATURE_OR_METHOD_NOT_IMPLEMENTED');
        break;
      case 'internal':
        message = this.ts.getLocalizedValue('ERRORS.INTERNAL_ERROR_OCCURRED');
        break;
      case 'unauthenticated':
        message = this.ts.getLocalizedValue('ERRORS.NEED_AUTH_TO_PERFORM_ACTION');
        break;
      case 'data-loss':
        message = this.ts.getLocalizedValue('ERRORS.DATALOSS_OCCURRED_WHILE_TRANSMITTING_TO_FIREBASE');
        break;
      case 'unavailable':
        message = this.ts.getLocalizedValue('ERRORS.ARE_YOU_OFFLINE') + '?';
        break;
      default:
        message = error.message;
        break;
    }
    console.log(error);
    await this.toastsService.showToast(message, 'Oops...');
  }
}

