import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Nfc, NfcTag, ConvertBytesToStringOptions, NfcUtils, GetTextFromNdefTextRecordOptions } from '@capawesome-team/capacitor-nfc';
import { LoadingController, LoadingOptions } from '@ionic/angular';
import { AlertService } from './alert.service';
import { ToastServiceService } from './toast-service.service';
import { BehaviorSubject } from 'rxjs';
import { TranslationService } from './translation.service';


@Injectable({
  providedIn: 'root'
})
export class NfcService {
  isScanning$ = new BehaviorSubject<boolean>(false)
  constructor(
    private alertService: AlertService,
    private toasts: ToastServiceService,
    private ts: TranslationService
  ) {

  }

  readTagForText() {
    return new Promise<{ result: string, id: string } | undefined>(async (resolve) => {
      const supported = await this.isSupported();

      if (!supported) {
        this.alertService.yumDealzDefaultAlert(
          this.ts.getLocalizedValue('NFC_READ_SERVICE.NOT_SUPPORTED'),
          this.ts.getLocalizedValue('NFC_READ_SERVICE.DEVICE_YOU_ARE_USING_DOESNT_SUPPORT_NFC'),
          false,
          true);
        resolve(null)
      }

      const enabled = await this.isEnabled();

      if (!enabled) {
        this.alertService.yumDealzAlertWithCustomButtons(
          this.ts.getLocalizedValue('NFC_READ_SERVICE.NFC_DISABLED'),
          this.ts.getLocalizedValue('NFC_READ_SERVICE.ENABLE_NFC_TO_RECEIVE_STAMPS'),
          [
            {
              text: this.ts.getLocalizedValue('NFC_READ_SERVICE.OPEN_SETTINGS'),
              handler: async () => {
                await this.openSettings()
              },
            },
            {
              text: this.ts.getLocalizedValue('NFC_READ_SERVICE.CANCEL'),
              role: 'cancel'
            }
          ]);
        resolve(null)
      }

      const loaderAndStopScanPromise = await this.giveAndroidWaitingForTagLoader();

      if (loaderAndStopScanPromise) {
        await loaderAndStopScanPromise.toast.present();
        loaderAndStopScanPromise.toast.onDidDismiss().then(async () => {
          await this.stopScanning();
          loaderAndStopScanPromise.toastPromise.then((forcedStop) => {
            if (forcedStop) {
              resolve(null);
            }
          })
        })
      }


      await Nfc.addListener('scanSessionError', async (event) => {
        await this.stopScanning();
        this.toasts.showErrorToast(this.ts.getLocalizedValue('NFC_READ_SERVICE.SOMETHING_WENT_WRONG_DURING_SCAN_SESSION') + event.message);
        resolve(null);
      });

      await Nfc.addListener('scanSessionCanceled', async () => {
        console.log('cancelled scanSession');
        await this.stopScanning();
        resolve(null);
      });

      await Nfc.addListener('nfcTagScanned', async (event) => {
        if (loaderAndStopScanPromise) {
          await loaderAndStopScanPromise.toast.dismiss()
        } else {
          await this.stopScanning();
        }
        
        if(!event.nfcTag || !event.nfcTag.message){
          await this.toasts.showErrorToast(this.ts.getLocalizedValue('NFC_READ_SERVICE.NOTHING_SCANNED_TRY_AGAIN'))
          resolve(null)
        }

        if(!event.nfcTag.id){
          await this.toasts.showErrorToast(this.ts.getLocalizedValue('NFC_READ_SERVICE.TAG_HAS_NO_ID'));
          resolve(null)
        }

        let textRecord = null;

        // Iterate over the records in the message to find a text record
        // 84 is the text record
        for (const record of event.nfcTag.message.records) {
          if(record.type.includes(84) && record.type.length == 1){
            textRecord = record;
            break; // there should only be one
          } 
         
        }

        if(!textRecord){
          await this.toasts.showErrorToast('Tag is not set up properly');
          resolve(null)
        }
  
        let result:string;

        const utils = new NfcUtils();

        // Decode the payload as text
        const textObj = utils.getTextFromNdefTextRecord({
          record:textRecord
        })

        // Display the text
        result=textObj.text
        
        resolve({result,id:utils.convertBytesToHex({
          bytes:event.nfcTag.id
        }).hex});
      })

      await Nfc.startScanSession();
      this.isScanning$.next(true);
    })
  };

  async isSupported() {
    const { isSupported } = await Nfc.isSupported();
    return isSupported;
  };

  async stopScanning() {
    await Nfc.stopScanSession();
    await this.removeAllListeners();
    this.isScanning$.next(false)
    await this.toasts.closeToasts();
  }

  private async isEnabled() {
    if (Capacitor.getPlatform() == 'android') {
      const { isEnabled } = await Nfc.isEnabled();
      return isEnabled;
    } else {
      return true;
    }
  };

  async openSettings() {
    if (Capacitor.getPlatform() == 'android') {
      await Nfc.openSettings();
    }
  };

  private async giveAndroidWaitingForTagLoader() {
    if (Capacitor.getPlatform() != 'android') {
      return
    }

    let loaderAndStopScanPromise = await this.toasts.showAndroidNfcScanningToast(
      '', //this.ts.getLocalizedValue('NFC_READ_SERVICE.HOLD_BACK_OF_DEVICE_CLOSE_TO_YUMDEALZ_BOARD')
      this.ts.getLocalizedValue('NFC_READ_SERVICE.SCANNING'),
      this.ts.getLocalizedValue('NFC_READ_SERVICE.STOP')
    );

    return loaderAndStopScanPromise
  }

  private async removeAllListeners() {
    await Nfc.removeAllListeners();
  };
}
