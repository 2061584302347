<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{'LOYALTY_CARD_PICKER_COMPONENT.CHOOSE_A_CARD' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="return()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngFor="let card of loyaltyCards">
    <app-wallet-loyalty-card [loyaltyCard]="card"  [mode]="'cardSelect'" (onCardSelectEmit)="selectCard($event)">
    </app-wallet-loyalty-card>
  </ng-container>
</ion-content>