import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, WalletCardInfo, WalletLoyaltyCard } from 'src/app/models/interfaces';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { GisService } from 'src/app/services/gis.service';
import { LocationService } from 'src/app/services/location.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { ScanService } from 'src/app/services/scan.service';
import { TranslationService } from 'src/app/services/translation.service';
import { OutletDealzService } from 'src/app/services/outlet-dealz.service';

@Component({
  selector: 'app-wallet-loyalty-card',
  templateUrl: './wallet-loyalty-card.component.html',
  styleUrls: ['./wallet-loyalty-card.component.scss'],
})
export class WalletLoyaltyCardComponent implements OnInit, OnDestroy {
  @Input() loyaltyCard: WalletLoyaltyCard;
  @Input() scanRange: number;
  @Input() cardHeight = '60vh'
  @Input() inMemorySpecial: CampaignLoyaltyProgramme| IndefiniteLoyaltyProgramme;
  @Input() mode: 'cardSelect' | 'cardPreview' = 'cardPreview'
  isButtonLoading: boolean;
  special: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme;
  /**
  * the current day
  */
  today: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  showUserQr = false;
  qrcodeid: string;
  // errCorrectionLevels = NgxQrcodeErrorCorrectionLevels;
  // elementTypes = NgxQrcodeElementTypes;
  @Output() onWantsToScan: EventEmitter<WalletLoyaltyCard> = new EventEmitter();
  @Output() onSpecialFetched: EventEmitter<CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme> = new EventEmitter();
  @Output() onCardInfoEmit: EventEmitter<WalletCardInfo> = new EventEmitter();
  @Output() onCardSelectEmit: EventEmitter<WalletLoyaltyCard> = new EventEmitter();
  loading: boolean = true;
  platformResumeSubscription: Subscription
  cardInfo: WalletCardInfo = {
    id: null,
    kmFromSpecial: null,
    lat: null,
    lng: null,
    specialId: null,
    shopName: null
  };

  constructor(
    public assetLinksService: AssetlinksService,
    public alertService: AlertService,
    public yumDealzUserService: YumdealzUserService,
    public outletDealzService: OutletDealzService,
    public loyaltyCardService: LoyaltyCardService,
    private locationService: LocationService,
    private gisService: GisService,
    private ts: TranslationService,
    public dateTimeService: DateTimeService,
    private platform: Platform,
    private toasts: ToastServiceService,
    private modalCtrl: ModalController,
    public scanService: ScanService
  ) {
    this.today = this.dateTimeService.giveLocalNowDay();
    this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
      // and other stuff should happen here if the day changed...
      this.today = this.dateTimeService.giveLocalNowDay();
    });

  }


  ngOnDestroy(): void {
    if (this.platformResumeSubscription) {
      this.platformResumeSubscription.unsubscribe()
    }
  }

  private calcKmFromSpecial(special: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme) {
    if (!this.locationService.isUserLocationSet()) {
      return null
    }
    return this.gisService.getDistanceFromLocation(special.lng, special.lat,
      { lng: this.locationService.userLocation.lng, lat: this.locationService.userLocation.lat }
    )
  }


  async actionButton() {

    if (this.mode == 'cardSelect') {
      this.onCardSelectEmit.emit(this.loyaltyCard)
    }
  }

  async stampsClick(e) {
    e.preventDefault()
    if (this.mode == 'cardPreview') {
      await this.explainStamps()
    }

    if (this.mode == 'cardSelect') {
      this.onCardSelectEmit.emit(this.loyaltyCard)
    }
  }

  async cardClick() {
    if (this.mode == 'cardSelect') {
      this.onCardSelectEmit.emit(this.loyaltyCard)
    }
  }

  emitInitDetails() {
    this.onSpecialFetched.emit(this.special)
    this.cardInfo.id = this.loyaltyCard.id,
    this.cardInfo.shopName = this.special.restaurant_name
    this.cardInfo.specialId = this.special.id
    this.cardInfo.lat = this.special.lat
    this.cardInfo.lng = this.special.lng
    this.cardInfo.kmFromSpecial = this.calcKmFromSpecial(this.special)
    this.onCardInfoEmit.emit(this.cardInfo)
  }

  async ngOnInit() {
    if (this.inMemorySpecial) {
      this.special = this.inMemorySpecial
      this.emitInitDetails();
    } else {
      try {
        this.special = await this.outletDealzService.fetchaSpecial(this.loyaltyCard.userId,this.loyaltyCard.restaurantId, this.loyaltyCard.special_id)
        if (this.special) {
          this.emitInitDetails();
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.loading = false;
  }

  async mayScan() {
    try {
      let permissionStatus = await this.locationService.checkLocationPermissions();
      switch (permissionStatus.location) {
        case 'denied':
        case 'prompt':
        case 'prompt-with-rationale':
          return false;
        case 'granted':
          return true
        default:
          return false
      }
    } catch (error) {
      await this.toasts.showToast(this.ts.getLocalizedValue('WALLET_LOYALTY_CARD_COMPONENT.LOCATION_IS_OFF_PLEASE_TURN_ON_SO_WE_CAN_VERIFY'), this.ts.getLocalizedValue('ALMOST_READY_TO_SCAN') + '!');
      console.log(error);
    }

  }

  isDealInRange(deal: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme) {
    if (!this.locationService.isUserLocationSet()) {
      return false;
    }
  }

  async explainStamps() {
    await this.alertService.yumDealzDefaultAlert(
      this.ts.getLocalizedValue('WALLET_LOYALTY_CARD_COMPONENT.STAMPS'),
      this.ts.getLocalizedValue('WALLET_LOYALTY_CARD_COMPONENT.CIRCLES_YOU_SEE_HERE_WILL_FILL_WITH_STAMPS'),
      false,
      true)
  }

  ngAfterViewInit() {
    // const getRandomNumber = () => {
    //   return Math.floor(Math.random() * 181) - 90;
    // }

    // const images = document.getElementsByClassName('stamp');
    // Array.from(images).forEach((image:HTMLElement) => {
    //   const rotation = getRandomNumber() + 'deg';
    //   image.style.transform = `rotate(${rotation})`;
    // });
  }



  arrayMaker(total: number) {
    let returnArray = []
    for (let i = 0; i < total; i++) {
      returnArray.push(i)
    }
    return returnArray
  }
}
