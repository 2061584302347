<ion-card mode="ios" class="deal-card" [style.height]="cardHeight">
  <ion-badge  *ngIf="outlet.yum_points" 
  (click)="explainPoints()"
    color="primary"
    mode="ios">
     
    <ion-icon name="ribbon" size="large"></ion-icon>
  </ion-badge>
    <ion-grid fixed id="main-grid">
      <ion-row id="main-row">
        <ion-col id="main-col">
          <div id="img-container" class="fade-in">
            <img  
            [style.height]="cardHeight"
            id="background-image"
            [defaultImage]="assetLinksService.noImage" 
            [lazyLoad]="deal.picture" 
            [errorImage]="assetLinksService.noImage"
            onerror="this.src='assets/favicon.png'">
          </div>
        </ion-col>
      </ion-row>
      <div id="text-container">
        <ion-row>
          <ion-col>
          <p id="reward"> {{deal.descriptive_tag}}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col >
            <p id="description"> {{deal.description}}</p>
          </ion-col>
        </ion-row>

        <ion-fab edge="true" vertical="top" horizontal="end" shape="round" slot="fixed">
          <ion-fab-button [disabled]="isButtonLoading"  (click)="actionButtonClick()"  *ngIf="giveUserSpecialProgress().stickers_count==null"    >
            <ion-icon *ngIf="!isButtonLoading"  name="add"></ion-icon>
            <ion-spinner *ngIf="isButtonLoading" name="bubbles"></ion-spinner>
          </ion-fab-button>
          <ion-fab-button (click)="viewUserCard()" color="secondary"  *ngIf="giveUserSpecialProgress().stickers_count>0 || giveUserSpecialProgress().stickers_count===0"  [disabled]="isButtonLoading"  >
           <ion-icon color="light" name="wallet"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </ion-grid>
</ion-card>

