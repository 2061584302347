import { Component, Input, OnInit } from '@angular/core';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { OutletPage } from 'src/app/pages/outlet/outlet.page';
import { DateTimeService } from 'src/app/services/date-time.service';
import { OutletDealzService } from 'src/app/services/outlet-dealz.service';
import { Outlet } from 'src/app/models/interfaces';

@Component({
  selector: 'app-outlet-avatar',
  templateUrl: './outlet-avatar.component.html',
  styleUrls: ['./outlet-avatar.component.scss'],
})
export class OutletAvatarComponent  implements OnInit {
  @Input() outlet:Outlet
  constructor(
    private modalCtrl:ModalController,
    public assetLinksService:AssetlinksService,
    private dateTimeService:DateTimeService,
  ) { }

  ngOnInit() {}

  async viewAction(){
    try {
      const modal = await this.modalCtrl.create({
        component: OutletPage,
       // presentingElement: this.routerOutlet.nativeEl,
        componentProps: {
          outlet:this.outlet,
          today:this.dateTimeService.giveLocalNowDay()
        }
      });
      await modal.present();
    } catch (error) {
      console.error(error)
    }
  }

  
}
