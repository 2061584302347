import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Outlet, Shop, ShopWithDealz, WalletLoyaltyCard } from 'src/app/models/interfaces';
import { AssetlinksService } from 'src/app/services/assetlinks.service';

@Component({
  selector: 'app-loyalty-card-picker',
  templateUrl: './loyalty-card-picker.component.html',
  styleUrls: ['./loyalty-card-picker.component.scss'],
})
export class LoyaltyCardPickerComponent  implements OnInit {
  @Input() loyaltyCards:WalletLoyaltyCard[]
  constructor(
    public assetLinksService:AssetlinksService,
    private modalController:ModalController
  ) { }

  ngOnInit() {}


  return(data = null) {
    this.modalController.dismiss(data);
  }

  selectCard(card:WalletLoyaltyCard){
    this.return({card})
  }

}
