import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, ScanType, Voucher, WalletCardInfo } from 'src/app/models/interfaces';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { GisService } from 'src/app/services/gis.service';
import { LocationService } from 'src/app/services/location.service';
import { VouchersService } from 'src/app/services/vouchers.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { ScanService } from 'src/app/services/scan.service';
import { OutletDealzService } from 'src/app/services/outlet-dealz.service';
import { PreferencesService } from 'src/app/services/preferences.service';
import { QrCodePage } from 'src/app/pages/wallet/modals/qr-code/qr-code.page';


@Component({
  selector: 'app-voucher-card',
  templateUrl: './voucher-card.component.html',
  styleUrls: ['./voucher-card.component.scss'],
})
export class VoucherCardComponent  implements OnInit,OnDestroy {
  @Input() voucher:Voucher;
  @Input() scanRange:number;
  @Input() inMemorySpecial:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme;

  special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme;
  isButtonLoading
  /**
  * the current day
  */
  today:"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";

  loading:boolean=true;
  cardInfo:WalletCardInfo = {
    id:null,
    kmFromSpecial:null,
    lat:null,
    lng:null,
    specialId:null,
    shopName:null
  };
  platformResumeSubscription:Subscription;

  @Output() onWantsToScan:EventEmitter<Voucher> = new EventEmitter()
  @Output() onSpecialFetched:EventEmitter<CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme> = new EventEmitter()
  @Output() onCardInfoEmit:EventEmitter<WalletCardInfo> = new EventEmitter();
  constructor(
    public assetLinksService:AssetlinksService,
    private modalCtrl:ModalController,
    public alertService:AlertService,
    public yumDealzUserService:YumdealzUserService,
    public outletDealzService:OutletDealzService,
    public vouchersService:VouchersService,
    public dateTimeService:DateTimeService,
    private locationService:LocationService,
    private gisService:GisService,
    private platform:Platform,
    private preferencesService:PreferencesService,
    public scanService:ScanService
    ) { 
     
      this.today =  this.dateTimeService.giveLocalNowDay();
      this.platformResumeSubscription = this.platform.resume.subscribe(async()=>{     
        // and other stuff should happen here if the day changed...
        this.today =  this.dateTimeService.giveLocalNowDay();
      });
    }
  
    private calcKmFromSpecial(special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme){
      if(!this.locationService.isUserLocationSet()){
        return null
      }
      return this.gisService.getDistanceFromLocation(special.lng,special.lat,
        {lng:this.locationService.userLocation.lng,lat:this.locationService.userLocation.lat}
      )
    }

    ngOnDestroy(): void {
      if(this.platformResumeSubscription){
        this.platformResumeSubscription.unsubscribe()
      }
    }

  emitInitDetails(){
    this.onSpecialFetched.emit(this.special)
    this.cardInfo.id = this.voucher.uid,
    this.cardInfo.shopName = this.special.restaurant_name
    this.cardInfo.specialId = this.special.id
    this.cardInfo.lat = this.special.lat
    this.cardInfo.lng = this.special.lng
    this.cardInfo.kmFromSpecial = this.calcKmFromSpecial(this.special)
    this.onCardInfoEmit.emit(this.cardInfo)
  }

  async ngOnInit() {
    if(this.inMemorySpecial){
      this.special = this.inMemorySpecial
      this.emitInitDetails();
    }else{
      try {
        this.special = await this.outletDealzService.fetchaSpecial(this.voucher.userId,this.voucher.restaurantUid,this.voucher.specialUid);
        if(this.special){
          this.emitInitDetails();
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.loading = false;
  }

  async viewRestaurant(special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme){

  }

  async showRedemptionQrCode(){
    const modal = await this.modalCtrl.create({
      component: QrCodePage,
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      handle: false,
      componentProps:{
        qrcodeid:`redeem_${this.voucher.uid}_${this.voucher.outlet_id}_${this.voucher.restaurantUid}_${this.voucher.specialUid}_${this.voucher.userId}`,
        voucher:this.voucher
      }
    });
    await modal.present();
  }


   async redeemVoucher(scanType:ScanType){
    this.isButtonLoading = true;

    const scanContent = await this.scanService.scanVoucher(this.voucher,scanType);
    if(scanContent){
      await this.scanService.letUserRedeemVoucher(this.voucher,scanContent.outlet);
    }
    this.isButtonLoading = false;
  }

}
