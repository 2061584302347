import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Shop, ShopWithDealz, Story } from 'src/app/models/interfaces';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import SwiperCore, { SwiperOptions, Virtual,Pagination,EffectCards  } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ModalController } from '@ionic/angular';
// install Swiper modules
SwiperCore.use([Virtual]);

interface StoryAvatar extends Shop{
  viewed:boolean,
  stories:Story[]
}

@Component({
  selector: 'app-stories-lane',
  templateUrl: './stories-lane.component.html',
  styleUrls: ['./stories-lane.component.scss'],
})
export class StoriesLaneComponent  implements OnInit,OnChanges {
  @Input() shopsWithStories:{
    shop:Shop,
    stories:Story[]
  }[];
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  swiperConfig: SwiperOptions = {
    direction:'horizontal',
    slidesPerView:4.5
  };
  constructor(
    public assetLinksService:AssetlinksService,
  ) { 
   
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  async ngOnInit() {
  
  }


    onSwiper(params) {
      const [swiper] = params;
      console.log(swiper);
    }
    
    onBeforeTransitionStart(params) {
      const [swiper, speed, internal] = params;
      console.log('beforeTransitionStart, speed: ' + speed);
    }
    
    onSlideChange() {
      console.log('slide change');
    }
    
    slideNext(){
      this.swiper.swiperRef.slideNext(100);
    }
    
    slidePrev(){
     this.swiper.swiperRef.slidePrev(100);
    }

}
