import { Component, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import { SignupPage } from '../auth/signup/signup.page';
import { LoginPage } from '../auth/login/login.page';
import { AuthService } from 'src/app/services/auth.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Capacitor } from '@capacitor/core';
import { UserChangeService } from 'src/app/services/user-change.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { VouchersService } from 'src/app/services/vouchers.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnInit {
  @ViewChild(IonicSlides, { static: false }) slider: SwiperComponent;
  agreesToTerms = true;
  isIosPlatform: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private auth: AuthService,
    private toasts: ToastServiceService,
    private userChangeService: UserChangeService,
    public assetLinksService: AssetlinksService,
    private loadingService: LoadingService,
    public loyaltyCardsService: LoyaltyCardService,
    public voucherService: VouchersService,
    private alertService: AlertService,
    private ts: TranslationService
  ) {

  }

  isSafeToLogIn() {
    return this.loyaltyCardsService.loyaltyCards.length == 0 && this.voucherService.vouchers.length == 0
  }

  ngOnInit() {
    this.isIosPlatform = Capacitor.getPlatform() == 'ios';
  }

  maySignIn(e) {
    this.agreesToTerms = e.detail.checked
  }

  logInInsteadAlert(platform: 'Google' | 'Apple') {
    this.alertService.yumDealzAlertWithCustomButtons(
      this.ts.getLocalizedValue('ERRORS.THIS_ACCOUNT_ALREADY_EXISTS'),
      this.ts.getLocalizedValue('INFO.DO_YOU_WANT_TO_LOGIN_INSTEAD'),
      [
        {
          text: this.ts.getLocalizedValue('LANDING_PAGE.CANCEL'),
          role: 'cancel'
        },
        {
          text: this.ts.getLocalizedValue('LANDING_PAGE.LOGIN'),
          handler: async () => {
            switch (platform) {
              case 'Google':
                await this.googleSignIn();
                break;
              case 'Apple':
                await this.appleSignIn();
                break;

              default:
                console.log('You need to specify a platform');
                break;
            }
          }
        }
      ],
      false,
      true
    )
  }


  ionViewDidEnter() {
    try {
      this.slider.swiperRef.update();
    } catch (error) {
      // TODO always leaves an error on startup
    }
  }

  async openSignUp() {
    const modal = await this.modalCtrl.create({
      component: SignupPage
    });
    await modal.present();
    modal.onDidDismiss().then(async (context) => {
      if (context.data && context.data.loggedIn) {
        this.close();
      }
      if (context.data && context.data.wantsToLogIn) {
        await this.openSignIn();
      }
    });
  }

  async openSignIn() {
    const modal = await this.modalCtrl.create({
      component: LoginPage
    });
    await modal.present();
    modal.onDidDismiss().then(async (context) => {
      if (context.data && context.data.loggedIn) {
        this.close();
      }
      if (context.data && context.data.wantsToSignUp) {
        await this.openSignUp();
      }
    });
  }

  async googleSignIn() {
    try {
      await this.loadingService.showOrContinueShowingLoading();
      this.auth.googleSignIn().then(async (cred) => {
        await this.loadingService.hideLoading();
        await this.close()
      },
        async err => {
          await this.loadingService.hideLoading();
          this.auth.handleAuthErrors(err)
        })
    } catch (error) {
      console.log(error);
      await this.loadingService.hideLoading();
      this.toasts.showErrorToast(error.message);
    }
  }

  async appleSignIn() {
    try {
      await this.loadingService.showOrContinueShowingLoading();
      this.auth.appleSignIn().then(async (cred) => {
        await this.loadingService.hideLoading();
        await this.close()
      },
        async err => {
          await this.loadingService.hideLoading();
          this.auth.handleAuthErrors(err)
        })
    } catch (error) {
      console.log(error);
      await this.loadingService.hideLoading();
      this.toasts.showErrorToast(error.message);
    }
  }

  async linkWithApple() {
    try {
      await this.loadingService.showOrContinueShowingLoading()
      this.auth.linkWithApple().then(async (cred) => {
        await this.loadingService.hideLoading()
        this.close()
      },
        async err => {
          await this.loadingService.hideLoading();
          if (err.code == 'auth/provider-already-linked' || err.code == 'auth/credential-already-in-use') {
            this.logInInsteadAlert('Apple')
          }
          this.auth.handleAuthErrors(err)
        })
    } catch (error) {
      await this.loadingService.hideLoading()
      console.log(error);
    }
  }

  async linkWithGoogle() {
    try {
      await this.loadingService.showOrContinueShowingLoading()
      this.auth.linkWithGoogle().then(async (cred) => {
        await this.loadingService.hideLoading()
        this.close()
      },
        async err => {
          await this.loadingService.hideLoading();
          if (err.code == 'auth/provider-already-linked' || err.code == 'auth/credential-already-in-use') {
            this.logInInsteadAlert('Google');
          }
          this.auth.handleAuthErrors(err)
        })
    } catch (error) {
      await this.loadingService.hideLoading()
      console.log(error);
    }
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

}
