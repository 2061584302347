import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { IProduct, Story } from '../models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(
    private database:DatabaseService,
  ) { }

  async fetchShopStories(shopId:string){
      return this.database.getShopActiveStories(shopId);
  }

  async clickedStory(latLng: {
    lat: number;
    lng: number;
  }, geohash: string, story: Story, outletId: string){
    return this.database.engageProduct('click',latLng,geohash,story,outletId);
  }

  async viewedStory(latLng: {
    lat: number;
    lng: number;
}, geohash: string, story: Story, outletId: string){
    return this.database.engageProduct('view',latLng,geohash,story,outletId);
  }

  async likeStory(latLng: {
    lat: number;
    lng: number;
}, geohash: string, story: Story, outletId: string){
    return this.database.engageProduct('like',latLng,geohash,story,outletId);
  }
}
