import { Injectable } from '@angular/core';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, Outlet, ScanType, WalletLoyaltyCard } from '../models/interfaces';
import { DatabaseService } from './database.service';
import { User } from '@firebase/auth';
import { NetworkStatusService } from './network-status.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserChangeService } from './user-change.service';
import { PushNotificationsService } from './pushnotifications.service';
import { LoyaltyProgrammeVariants } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyCardService {
  userLoyaltyCards$:BehaviorSubject<WalletLoyaltyCard[]> = new BehaviorSubject([])
  private _loyaltyCards:WalletLoyaltyCard[]=[]
  get loyaltyCards(){
    return this._loyaltyCards
  }
  private loyaltyCardSubscription:Subscription=null
  constructor(
    private database:DatabaseService,
    public networkStatusService:NetworkStatusService,
    private userChangeService:UserChangeService,
    private pushNotificationsService:PushNotificationsService

    ) { 
      this.userChangeService.user$.subscribe((user) => {
        if(!user) return;
        this.listenToUserLoyaltyCards(true);
      });
    }

  async listenToUserLoyaltyCards(force=false) {
    if(force && this.loyaltyCardSubscription){
      this.loyaltyCardSubscription.unsubscribe();
    }
    if(!this.loyaltyCardSubscription||this.loyaltyCardSubscription.closed){
      this.database.listenToYumDealzUserIncompleteLoyaltyCards(this.userLoyaltyCards$);
      this.loyaltyCardSubscription = this.userLoyaltyCards$.subscribe((loyaltyCards)=>{
        this._loyaltyCards = loyaltyCards
      })
    }else{
      console.log('Already listening. subscribe to the active subject instead');
    }
  }


  giveUserLoyaltyCardsAtShop(shopId:string){
    return this.loyaltyCards.filter((card)=>{
      return card.restaurantId == shopId
    });
  }

  giveCardInWalletForThisSpecial(spesh:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme){
    let foundspesh = this._loyaltyCards.filter(special=>{
      return special.special_id==spesh.id;
    })[0]
    return foundspesh;
  }

  isSpecialInUserWallet(special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme){
    return this._loyaltyCards.filter(spesh=>{
      return spesh.special_id==special.id
    }).length > 0
 }

 joinLoyalty(special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme,fbUser:User,outletId:string) {
      const successResult= {
          status:'success',
      }
      const errorResult={
          status:'error',
          message:''
      }
     return new Promise<{status:string}>(async (resolve, reject) => {
        try {
            const uid = fbUser.uid;
            
            if(!uid){
                errorResult.message='user uid in context was null or undefined'
                reject(errorResult);
                return;
            }

            if(!special){
                errorResult.message='no special passed to function'
                reject(errorResult);
                return;
            }

            if(!special.user_uid || !special.id || !special.restaurant_id || !outletId){
                errorResult.message='Must supply outletId, resUserId, special_id and restaurantId. One of those were undefined or 0'
                reject(errorResult);
                return;
            }

            let restaurantOwnerUserDoc = await this.database.fetchRestaurantOwnerDocData(special.user_uid) // TODO shop owner user needs a type
            
            if(!restaurantOwnerUserDoc){
                errorResult.message='Can not join. The restaurant user id did not produce a doc when trying to fetch'
                reject(errorResult);
                return;
            }

            const date = new Date();
            const thisDay = date.getUTCDate();
            const thisYear = date.getUTCFullYear();
            const thisMonth = date.getUTCMonth();
            const utc_end_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 23, 59, 59, 999);
            const utc_start_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 0, 0, 0, 0);
  
            switch (special.variant) {
              case LoyaltyProgrammeVariants.INDEFINITE:
                special = special as IndefiniteLoyaltyProgramme;

                if(!special.expiresAfter || !special.voucherExpiresAfter){
                    errorResult.message='Must supply expiresAfter and voucherExpiresAfter. One of those were undefined or 0'
                    reject(errorResult);
                    return;
                }
                
                await this.database.newLoyaltyCard(
                  special.user_uid,
                  special.restaurant_id,
                  special.id,
                  utc_end_of_day_unix + special.expiresAfter,
                  outletId,
                )
                resolve(successResult);
              case LoyaltyProgrammeVariants.CAMPAIGN:
                special = special as CampaignLoyaltyProgramme;
                
                if( !special.campaign_end || !special.campaign_start){
                  errorResult.message='Must supply campaign_end and campaign_start. One of those were undefined or 0'
                  reject(errorResult);
                  return;
                }
  
                const expiresAfter = special.campaign_end - utc_end_of_day_unix;

                if(!expiresAfter || expiresAfter < 0){
                  errorResult.message='The campaign loyalty programme has ended'
                  reject(errorResult);
                  return;
                }
  
                if(!special.campaign_start || utc_start_of_day_unix < special.campaign_start){
                  errorResult.message='The campaign loyalty programme has not started yet'
                  reject(errorResult);
                  return;
                }
  
                await this.database.newLoyaltyCard(
                  special.user_uid,
                  special.restaurant_id,
                  special.id,
                  special.campaign_end,
                  outletId,
                );

                resolve(successResult);
              default:
                errorResult.message='Invalid LoyaltyProgrammeVariant';
                reject(errorResult);
                return;
            }
        } catch (error) {
            console.log(error);
            errorResult.message = `Could not join ${!this.networkStatusService.isOnline()?'(You are offline)':'(Are you offline?)'}`
            reject(errorResult);
        }
     })
  };

  async receiveStamp(
    loyaltyCard:WalletLoyaltyCard,
    deal:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme,
    location:{lat:number,lng:number,gps_accuracy:number},
    outlet:Outlet,
    puckId:string,
    scanType:ScanType
  ){
      if(loyaltyCard.stickers_count == null) {
          throw 'All stamps was collected'
      }
      let newStickersCount = await this.database.getStampTransaction(deal,loyaltyCard,location.lat,location.lng,location.gps_accuracy,outlet,puckId,scanType)
      return newStickersCount
  };
}
