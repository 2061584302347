<div mode="ios" class="card" *ngIf="!loading">
  <div class="card-content" *ngIf="special; else nothing">
    <div  id="title-container">
      <h2>{{special.descriptive_tag}}</h2>
    </div>
    <ion-item  style="text-align: center;">
      <ion-label color="secondary">
        <strong>{{special.restaurant_name}} | {{special.name}}</strong>
        <p>{{voucher.expirationDate_unix_utc | daysLeft}}</p>
      </ion-label>
    </ion-item>
    <div id="img-container" (click)="viewRestaurant(special)"class="fade-in">
      <img  
      id="background-image"
      [defaultImage]="assetLinksService.noImage" 
      [lazyLoad]="special.picture" 
      [errorImage]="assetLinksService.noImage"
      onerror="this.src='assets/favicon.png'">
      <!-- <ion-spinner  name="dots"></ion-spinner> -->
    </div>

      <div id="redeem-button-container">
        <ion-item lines="none"  style="text-align: center;">
          <ion-label color="secondary">
            <strong>Redeem voucher</strong>
            <p>Choose a redemption method</p>
          </ion-label>
        </ion-item>
        <ion-grid fixed>
          <ion-row>
            <ion-col >
              <ion-button [disabled]="dateTimeService.isExpired(voucher.expirationDate_unix_utc) || isButtonLoading" expand="block" (click)="redeemVoucher('QR')" >
                {{isButtonLoading? '' : 'QR' }} 
                <ion-icon *ngIf="!isButtonLoading"  slot="end" name="scan"></ion-icon>
                <ion-spinner *ngIf="isButtonLoading" name="dots"></ion-spinner>
              </ion-button>
            </ion-col>
            <ion-col *ngIf="scanService.isNfcSupported" >
              <ion-button [disabled]="dateTimeService.isExpired(voucher.expirationDate_unix_utc) || isButtonLoading" expand="block" (click)="redeemVoucher('NFC')" >
                {{isButtonLoading? '' : 'NFC' }} 
                <ion-icon *ngIf="!isButtonLoading"  slot="end" name="radio"></ion-icon>
                <ion-spinner *ngIf="isButtonLoading" name="dots"></ion-spinner>
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button [disabled]="dateTimeService.isExpired(voucher.expirationDate_unix_utc) || isButtonLoading" expand="block" (click)="showRedemptionQrCode()" >
                {{isButtonLoading? '' : 'SHOW' }} 
                <ion-icon *ngIf="!isButtonLoading" slot="end"  name="qr-code"></ion-icon>
                <ion-spinner *ngIf="isButtonLoading" name="dots"></ion-spinner>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
  </div>
  <ng-template #nothing>
    <div class="card-content">
      <div  id="title-container">
        <h2>Deleted</h2>
      </div>
      <div id="img-container" class="fade-in">
        <img  
        id="background-image"
        [defaultImage]="assetLinksService.noImage" 
        [lazyLoad]="assetLinksService.favicon" 
        [errorImage]="assetLinksService.noImage"
        onerror="this.src='assets/favicon.png'">
        <!-- <ion-spinner  name="dots"></ion-spinner> -->
      </div>
        <ion-item  style="text-align: center;">
          <ion-label color="secondary">
            <strong>Deleted</strong>
            <p>{{voucher.expirationDate_unix_utc | daysLeft}}</p>
          </ion-label>
        </ion-item>
        <div id="redeem-button-container">
   
        </div>
    </div>
  </ng-template>
</div>

<div
style="height: 40vh; margin: 0.5em;"
mode="ios"
*ngIf="loading">
<ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
</div>