import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StoryService } from 'src/app/services/story.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Virtual,Pagination,EffectCards  } from 'swiper';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { Shop, Story } from 'src/app/models/interfaces';
import { Browser } from '@capacitor/browser';
// install Swiper modules
SwiperCore.use([Virtual,Pagination]);


@Component({
  selector: 'app-story-viewer',
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss'],
})
export class StoryViewerComponent  implements OnInit {
  @Input() shop:Shop;
  @Input() stories:Story[];
  @Input() userLocation:{lat:number,lng:number,geohash:string}|null;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  linkClickThinking=false
  constructor(
    public assetLinksService:AssetlinksService,
    private modalController: ModalController,
    private storyService:StoryService,
  ) { }

  async ngOnInit() {

  }

  log(e){
    console.log(e);
    
  }

  return(data = null) {
    this.modalController.dismiss(data);
  }

  onSwiper(params) {
    const [swiper] = params;
    
  }

  clickLink(){
  
  }

  async openLink(story:Story) {
    if(!story.link){
      return
    }
    try {
      this.linkClickThinking = true
      try {
        await this.storyService.clickedStory(
          this.userLocation ? {lat:this.userLocation.lat,lng:this.userLocation.lng} : null,
          this.userLocation? this.userLocation.geohash : null,
          story,
          null
        )
      } catch (error) {
        console.log('Tried to register link click',error);
      }
      this.linkClickThinking = false;
      Browser.open({
        url:story.link
      })
    } catch (error) {
      this.linkClickThinking = false
      console.log(error);
    }
  }

  async onSwiperInit(e){
   try {
    await this.storyService.viewedStory(
      this.userLocation?{lat:this.userLocation.lat,lng:this.userLocation.lng}:null,
      this.userLocation?this.userLocation.geohash:null,
      this.stories[0],
      null
    )
   } catch (error) {
    console.log(error);
   }
  }
    
    onBeforeTransitionStart(params) {
    const [swiper, speed, internal] = params;
    console.log('beforeTransitionStart, speed: ' + speed);
    }
    
    async onSlideChange(e) {
      if(e && this.stories[e.activeIndex]){
        try {
          await this.storyService.viewedStory(
            this.userLocation?{lat:this.userLocation.lat,lng:this.userLocation.lng}:null,
            this.userLocation?this.userLocation.geohash:null,
            this.stories[e.activeIndex],
            null
          )
        } catch (error) {
          console.log(error);
        }
      }
    }
    
    slideNext(){
    this.swiper.swiperRef.slideNext(100);
    }
    
    slidePrev(){
    this.swiper.swiperRef.slidePrev(100);
    }

}
