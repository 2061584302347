import { Injectable } from '@angular/core';
import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { YumDealzUser, Voucher, WalletLoyaltyCard, Reward, Outlet, IndefiniteLoyaltyProgramme, CampaignLoyaltyProgramme, KeyRing } from '../models/interfaces';
import { FirebaseService } from './firebase.service';
import { YumdealzUserService } from './yumdealz-user.service';
import { AuthService } from './auth.service';

export interface Response {
  status: boolean;
  message?: string;
  data:{success:boolean};
}

export interface VoucherAwardedResponse extends Omit<Response, 'data'>{
  data: {
    voucher_id: string
  }
}

export interface VoucherClaimedResponse extends Omit<Response, 'data'> {
  data:{
    voucher:Voucher
  }
}

interface KeyringClaimedResponse {
  status: boolean,
  message: string,
  data: {
      keyring:KeyRing
  }
}

@Injectable({
  providedIn: 'root'
})
export class CloudfunctriggersService {
  functions:Functions
  constructor(
    private firebaseService:FirebaseService,
    private userService:YumdealzUserService,
    private authService:AuthService
  ) {
    this.functions = this.firebaseService.getFunctions();
   }

  async likeSpecial(special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme,user:YumDealzUser){
    const likeSpecial = httpsCallable(this.functions,'likeSpecial');
    await likeSpecial({special:{
      id:special.id,
      restaurant_id:special.restaurant_id,
      user_uid:special.user_uid,
      likes:special.likes
    },user:{
      likedSpecials:user.likedSpecials,
      uniqueDeviceId:user.uniqueDeviceId
    }});
  }

  async tryAwardYumPoint(outletId:string): Promise<Response>{
    try {
      const func: HttpsCallable<{ outletId:string,userId:string},Response> = httpsCallable(this.functions, 'awardYumPoint');
      const result = await func({ outletId, userId:this.authService.giveCurrentUser().uid });
      this.userService.refreshUser();
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }
  

  async assignTakeAlotVoucherToUser(reward:Reward){
      const func: HttpsCallable<{ userId: string, rewardId: string },VoucherAwardedResponse> = httpsCallable(this.functions, 'assignTakeAlotVoucherToUser');
      const result = await func({ rewardId:reward.id, userId:this.authService.giveCurrentUser().uid });
      this.userService.refreshUser()
      return result.data;
  }

  async triggerStampSpamAlert(outlet:Outlet){
    const func: HttpsCallable<{ outlet:Outlet },Response> = httpsCallable(this.functions, 'triggerStampSpamAlert');
    const result = await func({ outlet });
    return result.data;
}

async claimVoucher(voucher_id:string){
  const func: HttpsCallable<{ voucher_id:string },VoucherClaimedResponse> = httpsCallable(this.functions, 'claimVoucher');
  const result = await func({ voucher_id });
  return result.data;
}

async claimKeyring(keyring_id:string){
  const func: HttpsCallable<{ keyring_id:string },KeyringClaimedResponse> = httpsCallable(this.functions, 'claimKeyring');
  const result = await func({ keyring_id });
  return result.data;
}


  async increaseSpecialClickCount(special:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme){
    const increaseSpecialClickCount = httpsCallable(this.functions,'increaseSpecialClickCount');
    await increaseSpecialClickCount({special});
   }

}
