<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-avatar>
        <img [defaultImage]="assetLinksService.noImage" [lazyLoad]="outlet.image"
          [errorImage]="assetLinksService.noImage" />
      </ion-avatar>
    </ion-buttons>
    
    <ion-title *ngIf="mode != 'previewWithSession'" >
      {{'LOYALTY_CARD_PREVIEW_COMPONENT.YOUR_CARD' | translate}}
    </ion-title>
    <ion-title *ngIf="mode == 'previewWithSession'" >
      <ion-label color="primary">
        <h1>
          {{loyaltyCard.participant_id.substring(0,5)}}
        </h1>
        <p>
          Present this code
        </p>
      </ion-label>

    </ion-title>

    <ion-buttons slot="end" *ngIf="userService.user$| async as user">
      <ion-button *ngIf="outlet.yum_points">
        {{user.yum_points}}
        <ion-icon slot="end" name="ribbon"></ion-icon>
       </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-progress-bar *ngIf="loyaltyCardUpdateCount <= 1 && mode=='previewWithSession'" type="indeterminate"></ion-progress-bar>

</ion-header>

<ion-content>
  <p *ngIf="loyaltyCardUpdateCount <= 1 && mode=='previewWithSession'" style="margin: 1em; text-align: center;">
    Please wait for staff to award your stamps
  </p>
  <app-wallet-loyalty-card 
    id="previewCard" 
    *ngIf="loyaltyCard" 
    [loyaltyCard]="loyaltyCard" 
    [inMemorySpecial]="deal"
    [cardHeight]="cardHeight" 
    [mode]="'cardPreview'">
  </app-wallet-loyalty-card>


  <ion-fab vertical="bottom" horizontal="center" slot="fixed">
    <ion-fab-button color="dark" (click)="return()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ion-footer *ngIf="mode == 'previewWithScan'" class="ion-no-border ion-no-padding">
  <ion-toolbar>
        <ion-button [disabled]="showScanInstructions || loading" mode="ios" (click)="scan()" expand="block" shape="round">
          <ion-icon *ngIf="(!showScanInstructions && !loading)" slot="start" name="radio"></ion-icon>
          <ion-spinner *ngIf="showScanInstructions || loading" name="dots"></ion-spinner> &nbsp;
          <!-- TODO translate these -->
          {{showScanInstructions ? 'Scanning' : loading ? 'Loading' : loyaltyCard.stickers_count == 0 ? 'Get a stamp' : 'Get another stamp'}}
        </ion-button>
  </ion-toolbar>
</ion-footer>


