import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
})
export class NotificationPage implements OnInit {
  @Input() title: string
  @Input() note: string
  @Input() message: string
  @Input() imgUrl?: string
  constructor(
    private modalController: ModalController,
    public assetLinksService:AssetlinksService
  ) { }

  ngOnInit() {
    if(!this.imgUrl){
      this.imgUrl = this.assetLinksService.story
    }
  }

  return(data = null) {
    this.modalController.dismiss(data);
  }

}
