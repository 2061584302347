import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sendEmailVerification } from 'firebase/auth';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, Outlet } from 'src/app/models/interfaces';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { AuthService } from 'src/app/services/auth.service';
import { CloudfunctriggersService } from 'src/app/services/cloudfunctriggers.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { HapticsService } from 'src/app/services/haptics.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { PushNotificationsService } from 'src/app/services/pushnotifications.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { TranslationService } from 'src/app/services/translation.service';
import { LoyaltyCardPreviewComponent } from 'src/app/shared/components/loyalty-card-preview/loyalty-card-preview.component';

@Component({
  selector: 'app-deal-lane-card',
  templateUrl: './deal-lane-card.component.html',
  styleUrls: ['./deal-lane-card.component.scss'],
})
export class DealLaneCardComponent implements OnInit {
  @Input() deal: CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme
  @Input() outlet: Outlet
  @Input() cardHeight = '50vh'
  @Input() mode: 'dealSelect' | 'loyaltyJoin' = 'loyaltyJoin';
  @Output() onDealSelectEmit: EventEmitter<CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme> = new EventEmitter();
  @Output() onWantsToFlip: EventEmitter<any> = new EventEmitter();
  isButtonLoading
  searchLocation
  constructor(
    private modalCtrl: ModalController,
    private hapticsService: HapticsService,
    private cloudTriggers: CloudfunctriggersService,
    private loyaltyCardService: LoyaltyCardService,
    private authService: AuthService,
    private toasts: ToastServiceService,
    public assetLinksService: AssetlinksService,
    private dateTimeService: DateTimeService,
    private alertService: AlertService,
    private pushNotificationsService: PushNotificationsService,
    private ts: TranslationService,
  ) {

  }

  ngOnInit() {

  }

  explainPoints(){
    this.toasts.showToast(
      "Stores displaying this icon provide you with YumPoints along with the stamps you earn. YumPoints can be redeemed for a variety of rewards available in the YumPoints Exchange.",
      'YumPoints'
    )
  }

  flip() {
    this.onWantsToFlip.emit()
  }

  async cardClick() {
    switch (this.mode) {
      case 'dealSelect':

        break;

      default:
        //  await this.viewRestaurant()
        break;
    }
  }

  private async registerClick() {
    await this.cloudTriggers.increaseSpecialClickCount(this.deal)
  }

  giveUserSpecialProgress() {
    try {
      let foundCard = this.loyaltyCardService.giveCardInWalletForThisSpecial(this.deal);
      // This is a dummy object when failed only intended for knowing when to display certain ui elements
      // TODO make more elegant
      return foundCard ? foundCard : {
        id: null,
        userId: this.deal.user_uid,
        complete: false,
        special_id: this.deal.id,
        stickers_count: null,
        restaurantId: this.deal.restaurant_id
      };
    } catch (error) {
      console.log(error);
    }
  }

  async actionButtonClick() {
    switch (this.mode) {
      case 'dealSelect':

        this.onDealSelectEmit.emit(this.deal)
        console.log('emitted from cardcomponent', this.deal);

        break;

      default:
        await this.JoinLoyaltyProgramme()
        break;
    }
  }

  async JoinLoyaltyProgramme() {
    try {
      if (this.isButtonLoading) {
        return
      }
      await this.hapticsService.hapticsImpactLight()
      const user = this.authService.giveCurrentUser();
      this.isButtonLoading = true;
      await this.loyaltyCardService.joinLoyalty(this.deal, user,this.outlet.id);
      this.isButtonLoading = false;
    } catch (error) {
      console.log(error);
      this.isButtonLoading = false;
      this.toasts.showErrorToast(error.message)
    }
  }

  async viewUserCard() {
    await this.hapticsService.hapticsImpactLight()
    const card = this.loyaltyCardService.giveCardInWalletForThisSpecial(this.deal)
    const modal = await this.modalCtrl.create({
      component: LoyaltyCardPreviewComponent,
     mode:'ios',
     initialBreakpoint: 1,
     breakpoints: [0,1],
     handle:false,
      componentProps:{
        loyaltyCard:card,
        deal:this.deal,
        outlet:this.outlet,
        today:this.dateTimeService.giveLocalNowDay()
      }
    });
    await modal.present();
  }
}
