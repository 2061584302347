import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class ToastServiceService {

  constructor(
    public toastCrtl: ToastController,
    private ts: TranslationService
  ) { }

  async closeToasts(data?) {
    try {
      await this.toastCrtl.dismiss(data);
    } catch (error) {

    }
  }

  async showToast(msg: string, header: string, okFunction?: () => any, okButtonText = this.ts.getLocalizedValue('TOAST_SERVICE.OK')) {
    await this.closeToasts();
    let toast = await this.toastCrtl.create({
      header: header,
      message: msg,
      mode: 'ios',
      cssClass: 'yumdealz-toast',
      position: 'bottom',
      buttons: [{
        text: okButtonText,
        role: 'cancel',
        handler: okFunction
      }
      ]
    })
    await toast.present();
  }

  async showAndroidNfcScanningToast(msg: string, header: string, stopButtonText = this.ts.getLocalizedValue('TOAST_SERVICE.STOP')) {
    await this.closeToasts();

    let toastResolve: ((value: any) => void) | null = null;

    const toastPromise = new Promise<boolean>((resolve) => {
      toastResolve = resolve;
    });

    let toast = await this.toastCrtl.create({
      header: header,
      message: msg,
      mode: 'ios',
      cssClass: 'yumdealz-toast',
      position: 'top',
      buttons: [{
        text: stopButtonText,
        handler: () => {
          if (toastResolve) {
            toastResolve(true);
          }
        }
      }
      ]
    })
    return {
      toast,
      toastPromise
    }
  }

  async showErrorToast(msg: string) {
    await this.closeToasts();
    let toast = await this.toastCrtl.create({
      header: 'Oops..',
      message: msg,
      cssClass: 'yumdealz-toast',
      mode: 'ios',
      position: 'bottom',
      buttons: [{
        text: this.ts.getLocalizedValue('TOAST_SERVICE.OK'),
        role: 'cancel',
      }
      ]
    })
    await toast.present();
  }

  async showInfoAffirmationToast(msg: string) {
    await this.closeToasts();
    let toast = await this.toastCrtl.create({
      header: this.ts.getLocalizedValue('TOAST_SERVICE.NOTE') + ':',
      message: msg,
      cssClass: 'yumdealz-toast',
      mode: 'ios',
      position: 'middle',
      buttons: [{
        text: 'Ok',
        role: 'cancel',
      }
      ]
    })
    await toast.present();
  }

  async showNoteToast(msg: string, duration: number, position: 'bottom' | 'top' = 'bottom') {
    await this.closeToasts();
    let toast = await this.toastCrtl.create({
      header: '',
      message: msg,
      cssClass: 'yumdealz-toast',
      mode: 'ios',
      position,
      duration
    })
    await toast.present();
  }
}
