import { Timestamp } from "firebase/firestore";
import { LoyaltyProgrammeVariants, NOTIFICATION_TYPES, PictureFieldNames, REWARD_TYPES, TAKEALOT_VOUCHER_VALUES, TextBool, supportedProductTypes } from "./enums";

export interface Filter {
    radius:number;
}

export interface StampSession {
  session_start_unix_utc: number;
  yum_dealz_user_id:string;
  loyaltyProgramme_id:string;
  shop_user_id:string;
  card_id:string;
  outlet_id:string;
  shop_id:string;
}

export interface FilterStrings {
    radius:string;
}

export interface WalletLoyaltyCard {
  id:string;
  userId:string;
  restaurantId:string;
  complete:boolean;
  expiryDate:number;
  date_added_unix_utc_timestamp?:number;
  special_id:string;
  stickers_count:number;
  outlet_id:string;
  participant_id:string;
  };

  export interface KeyRing {
    id:string,
    user_id:string|null;
    user_name:string|null;
    contact_number:string|null;
    email:string|null;
    claimed_on:number|null;
    keyring_nr:number;
  }

  export interface KeyRingStamp extends Omit<Stamp, 'user_id'|'puck_id'> {
    keyring_id:string
  }

  export interface KeyRingLoyaltyCard extends Omit<WalletLoyaltyCard, 'participant_id'>   {
    keyring_id:string;
  };

  export interface KeyRingVoucher extends Omit<Voucher, 'claimerUId'> {
    keyring_id:string
  }
  
  export interface UserAvgEngagementDoc {
    id: string,
    user_id: string,
    merchant_id: string,
    shop_id: string,
    outlet_id: string,
    deal_id: string,
    start_date: number,
    avg_daily_visits: number,
    avg_weekly_visits: number,
    avg_monthly_visits: number,
    avg_yearly_visits: number,
    avg_daily_stamps: number,
    avg_weekly_stamps: number,
    avg_monthly_stamps: number,
    avg_yearly_stamps: number 
  }

  interface EngagementStatsDoc {
    id: string,
    user_id: string,
    merchant_id: string,
    shop_id: string,
    outlet_id: string,
    deal_id: string,
    visits: number,
    stamps: number
  }

  export interface DailyEngagementStatsDoc extends EngagementStatsDoc{
    end_of_day_unix_utc: number,
    start_of_day_unix_utc: number,
  }

  export interface WeeklyEngagementStatsDoc extends EngagementStatsDoc{
    end_of_week_unix_utc: number,
    start_of_week_unix_utc: number,
  }

  export interface MonthlyEngagementStatsDoc extends EngagementStatsDoc{
    end_of_month_unix_utc: number,
    start_of_month_unix_utc: number,
  }

  export interface YearlyEngagementStatsDoc extends EngagementStatsDoc{
    end_of_year_unix_utc: number,
    start_of_year_unix_utc: number,
  }

export interface Reward {
 id:string;
 type:REWARD_TYPES,
 yum_points_cost:number,
 image:string
}

export interface TakealotVoucherReward extends Omit<Reward, 'type'>{
    type:REWARD_TYPES.TAKEALOT_VOUCHERS,
    name:string,
    description:string,
    value:TAKEALOT_VOUCHER_VALUES
}
export interface TakealotVoucher {
    id:string,
    user_id:string;
    claimed:boolean;
    issued:boolean;
    issued_on_unix_utc_date:number|null;
    value:TAKEALOT_VOUCHER_VALUES;
    code:string|null;
}
export interface Stamp {
    lat:number;
    lng:number;
    geohash:string;
    timestamp_unix_utc:number;
    gps_accuracy:number|null;
    fifty_meters_from_outlet?:boolean | 'UNDETERMINED';
    scanType:ScanType;
    card_id:string;
    deal_id:string;
    puck_id:string;
    shop_id:string;
    shop_user_id:string;
    user_id:string;
    outlet_id?:string;
}

export interface NotificationFCM {
    message:string;
    title:string;
    imageURL?:string|null;
    type:NOTIFICATION_TYPES;
    id:string;
    scheduled_for_unix_utc:number;
    scheduled_on_unix_utc:number;
}

export interface UserNotification extends NotificationFCM {
    userId:string;
    read:boolean;
}

export interface UserReminderNotification  extends UserNotification {
  refId:string
}

export interface VoucherExpiryNotification extends UserNotification {
  voucherId:string
}

export interface LoyaltyCardExpiryNotification extends UserNotification {
  cardId:string
}
export interface TopicNotification extends NotificationFCM {
    topic:string;
}

export interface StoryTopicNotification extends TopicNotification {
  storyId:string;
}




export interface YumDealzUser {
uniqueDeviceId:string;
likedSpecials:string[];
pushToken:string;
suspicious?:boolean;
notificationArea:{
    geohash:string;
    radius:number;
    lat:number;
    lng:number;
},
lastSearchLocation:Location
lastTimeVisited:Timestamp;
installedVersion:string;
displayName:string;
email:string;
emailVerified:boolean;
providerId:string;
phoneNumber:string;
scanPreference?:ScanType
photoURL:string;
yum_points:number
platform:any;
}

export interface DayTradingHours{
from:string,
day:"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday",
activated:boolean,
abbreviation:string,
to:string
}

export interface Feature{
type: "Feature",
geometry: {
type: "Point",
coordinates:number[];
},
properties: {
[key:string]:any
}
}

export interface SpecialLayer{
layer:any,
special:any
}

export interface Location {
lat:number;
lng:number;
readableAddress:string;
accuracy:number;
radius:number;
timestamp_unix_utc:Timestamp;
}

export interface LocationStringValues {
lat:string,
lng:string,
accuracy:string,
readableAddress:string,
radius:string
}

export interface TextPageContent{
heading:string,
sections:{
heading:string,
paragraphs:{
text:string
listItems:TextPageContentListItem[]
}[]
}[],
expanded:boolean
}

export interface TextPageContentListItem{
text:string,
type:'text'|'bullet'
}

export interface MealType {
name:string;
id:string;
cssClass:'deactivated'|'activated';
}

export interface RestaurantIdAndName {
id:string,
name:string
}

export interface MealType {
name:string;
cssClass:'deactivated'|'activated';
}

export interface Shop {
    name:string;
    address:string;
    whatsapp:string,
    facebook:string,
    instagram:string,
    website:string,
    revision:number,
    holiday_trading_from:string;
    holiday_trading_to:string;
    saturday_trading_to:string;
    saturday_trading_from:string;
    sunday_trading_to:string;
    sunday_trading_from:string;
    logo:string;
    trading_to:string;
    trading_from:string;
    normal_trading_days:Array<"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday">;
    contact_number:string;
    lat: number,
    lng: number,
    geohash:string;
    id:string;
    email:string;
    verified:boolean;
    user_uid:string;
}

export interface WalletCardInfo{
    id:string,
    kmFromSpecial:number,
    lat:number,
    lng:number,
    specialId:string,
    shopName:string
}

export interface IProduct {
    name:string;
    description:string;
    verified:boolean,
    active:boolean;
    id:string;
    likes:number;
    clicks:number;
    [PictureFieldNames.PRODUCTS]?:string;
    restaurant_id:string;
    restaurant_name:string;
    user_uid:string;
    type:supportedProductTypes
  }


export interface Story  extends IProduct  {
    [PictureFieldNames.PRODUCTS]:string;
    views:number;
    date_updated_unix_utc:number;
    link:string;
  }

export interface LoyaltyProgramme extends IProduct {
  descriptive_tag:string,
  stamps_required:number;
  days:Array<"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday">;
  saturday_trading_from:string;
  saturday_trading_to:string;
  sunday_trading_from:string;
  sunday_trading_to:string;
  trading_from:string;
  trading_to:string;
  lat: number,
  lng: number,
  geohash:string,
  reward_cost:number,
  stamp_cost:number,
  variant:LoyaltyProgrammeVariants
}

export interface IndefiniteLoyaltyProgramme extends LoyaltyProgramme {
  expiresAfter:number,
  voucherExpiresAfter:number,
  variant:LoyaltyProgrammeVariants.INDEFINITE
}

export interface CampaignLoyaltyProgramme extends LoyaltyProgramme {
  campaign_start:number,
  campaign_end:number,
  voucher_days_active_post_campaign:number,
  variant:LoyaltyProgrammeVariants.CAMPAIGN
}



export interface SpecialWithVouchers extends LoyaltyProgramme{
vouchers?:Voucher[]
}

export interface ShopWithDealz extends Shop {
  dealz: (CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[];
}


  export interface Outlet extends Omit<Shop,'whatsapp' | 'instagram' | 'facebook'| 'website' | 'logo'> {
    id:string;
    shop_id:string;
    name:string;
    image:string;
    yum_points:number;
    alerts_email:string;
    receive_stamp_spam_alerts:TextBool;
    stamp_spam_alerts_threshold:number;
    [supportedProductTypes.LOYALTY_PROGRAMMES]?: boolean;
    [supportedProductTypes.STORIES]?: boolean;
  }


export interface ProductOffer{
    name:string,
    description:string,
    id:supportedProductTypes,
    image:string,
    video:string,
    type:supportedProductTypes,
    price:number
  }

export interface OutletWithDealz extends Outlet {
    dealz:(CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[];
}

        
export interface RestaurantWithSpecials extends Shop {
loyaltySpecials?:(CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[];
normalSpecials?:(CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme)[];
}

export interface PushTokenDoc {
    timestamp:Timestamp,
    userId:string,
    token:string,
    notificationsEnabled:boolean
}

export interface Puck{
    id:string,
    shop_user_id:string;
    shop_id:string;
    outlet_id:string;
    tag_serial_nr:string;
    legacy?:boolean;
    active:boolean;
    claimed:boolean;
    name:string;
    ready:boolean;
    qr_disabled?:boolean;
    prefer_stamp_session?:boolean;
}

export interface ProductEngagement {
    id:string;
    lat: number,
    lng: number,
    geohash:string,
    product_type:supportedProductTypes;
    product_id:string;
    user_id:string;
    shop_id:string;
    outlet_id:string
    timestamp_unix_utc:number;
  }

  export interface KeyRing {
    id:string,
    user_id:string|null;
    user_name:string|null;
    contact_number:string|null;
    email:string|null;
    claimed_on:number|null;
    keyring_nr:number;
  }

  export interface Voucher {
    activationDate:{
      dayEnd_unix_utx:number;
      dayStart_unix_utx:number;
    };
    claimed:boolean;
    claimerUId:string;
    restaurantUid:string,
    userId:string,
    redeemed:boolean,
    redeemed_on:number | null,
    redeemed_at_outlet_id:string | null,
    redemption_lat:number,
    redemption_lng:number,
    redemption_geohash:string,
    activation_lat:number,
    activation_lng:number,
    activation_geohash:string,
    expirationDate_unix_utc:number;
    notificationRadius:number;
    specialLat:number;
    specialLng:number;
    specialUid:string;
    outlet_id:string;
    uid:string;
    }

export type ScanType = 'NFC'|'QR'

export interface ScanError {
  scanType:ScanType
  app:'YumDealz'
  msg:string,
  action:string, 
  appVersion:string,
  scannedKey:string,
  scannedValue:string,
  timestamp: Timestamp,
  userId:string
}

// export interface VoucherWithKmFromSpecial extends Voucher{
//     kmFromSpecial:number
// }

export interface ABBREVIATED_DAYS_OF_WEEK {
0:'SUN'
1:'MON'
2:'TUE'
3:'WED'
4:'THU'
5:'FRI'
6:'SAT'
}


