import { Injectable } from '@angular/core';
import { Reward, TakealotVoucher } from '../models/interfaces';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from './database.service';
import { UserChangeService } from './user-change.service';


@Injectable({
  providedIn: 'root'
})
export class RewardsService {
  rewards$:BehaviorSubject<Reward[]> = new BehaviorSubject([])
  private _rewards:Reward[] = []
  get rewards(){
    return this._rewards
  }
  constructor(
    private database: DatabaseService,
    private userChangeService:UserChangeService
  ) { 
    this.userChangeService.user$.subscribe(async (user) => {
      if(!user) return;
      await this.fetchRewards();
    });
  }

  async fetchRewards(){
    try {
      this._rewards = await this.database.fetchRewards();
    } catch (error) {
      console.log(error);
      this._rewards = []
    }
    this.rewards$.next(this._rewards)
  }
}
