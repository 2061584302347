import { Injectable } from '@angular/core';
import { startOfDay, endOfDay, getUnixTime} from 'date-fns'
import { utcToZonedTime} from 'date-fns-tz'
import { DAYS_OF_WEEK } from '../models/enums';
import { ABBREVIATED_DAYS_OF_WEEK } from '../models/interfaces';


/**
 * FOR INTERNATIONAL SUPPORT IS IS IMPORTANT THAT ALL DATE HANDLING HAPPENS VIA THIS SERVICE.
 */


@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  currentTime:{h:number,m:number,s:number}={
    h:null,
    m:null,
    s:null
  };

  ABBREVIATED_DAYS_OF_WEEK:ABBREVIATED_DAYS_OF_WEEK= {
    0:'SUN',
    1:'MON',
    2:'TUE',
    3:'WED',
    4:'THU',
    5:'FRI',
    6:'SAT',
  }
  trackingTime:boolean=false;

  constructor() { 
    this.startToTrackTime();
  }

  private getLocalNowTime() {
    var d = new Date(); // for now
    let h = d.getHours(); 
    let m = d.getMinutes(); 
    let s = d.getSeconds(); 
    return {h,m,s}
  };

  refreshCurrentTime(){
    this.currentTime = this.getLocalNowTime();
  }

  startToTrackTime() {
    this.refreshCurrentTime();
   if(!this.trackingTime){
    setInterval(()=>{
      this.refreshCurrentTime();
    },60000);
    this.trackingTime=true
   }else{
     console.log('already tracking time. get this.currentTime instead');
   }
  }

  giveLocalNowDay(){
    let daynum = new Date().getDay();
    return DAYS_OF_WEEK[daynum] as "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
     
  }

  giveLocalNowDayAbreviated(){
    let daynum = new Date().getDay();
    let dayAbrv:'MON'|'TUE'|'WED'|'THU'|'FRI'|'SAT'|'SUN' = this.ABBREVIATED_DAYS_OF_WEEK[daynum]
    return dayAbrv;
  }

  giveFullDayTextFromAbbreviatedDayText(abbreviation){
    const abbreviations = ['MON','TUE','WED','THU','FRI','SAT','SUN']
    if(!abbreviations.includes(abbreviation)){
      throw new Error("Only 'MON','TUE','WED','THU','FRI','SAT','SUN' are accepted");
    }
    switch (abbreviation) {
      case 'MON':
        return 'Monday'
      case 'TUE':
        return 'Tuesday'
      case 'WED':
        return 'Wednesday'
      case 'THU':
        return 'Thursday'
      case 'FRI':
        return 'Friday'
      case 'SAT':
        return 'Saturday'
      case 'SUN':
        return 'Sunday'
    }
  }
  
  
  isExpired(futureUtcTimestamp:number){
    const now = this.todayNowUtcUnixMilisecs()
    const diff = futureUtcTimestamp - now;
    const daysLeft = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return daysLeft < 0;
  }

  // Converts a number of days to milliseconds
  dayAmountToMillis(days) {
    const millisecondsInDay = 86400000; // 24 * 60 * 60 * 1000
    return days * millisecondsInDay;
  }

  // Converts a number of milliseconds to days
  dayAmountFromMillis(milliseconds) {
    const millisecondsInDay = 86400000; // 24 * 60 * 60 * 1000
    return Math.floor(milliseconds / millisecondsInDay);
  }

  getEndOfDayInUTC() {
    let date = new Date();
    let thisDay = date.getUTCDate();
    let thisYear = date.getUTCFullYear();
    let thisMonth = date.getUTCMonth();
    let utc_end_of_day_unix = Date.UTC(thisYear,thisMonth,thisDay,23,59,59,999)
    return utc_end_of_day_unix
  }

  todayNowUtcUnixMilisecs(){
    const now = new Date(); // Get the current date and time in the user's timezone
    const nowUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds()); // Get the current date and time in UTC
    return nowUTC; // Return the UTC timestamp in milliseconds
  }

  // if time is in HH:mm format eg: 23:59
  giveTimeInMin(time:string){
    if(!time){
      return
    }
    return +time.split(':')[0]*60 + +time.split(':')[1] 
  }

  isCurrentTimeBetween(fromTime:string,toTime:string){
   if(
    this.currentTime.h*60 + this.currentTime.m >= this.giveTimeInMin(fromTime)
    && this.currentTime.h*60 + this.currentTime.m <= this.giveTimeInMin(toTime)
   ){
     return true;
   }else{
     return false;
   }
  }

  isCurrentTimeAfter(toTime:string){
    if(this.currentTime.h*60 + this.currentTime.m
     > this.giveTimeInMin(toTime)
    ){
      return true;
    }else{
      return false;
    }
  }

  isCurrentTimeBefore(fromTime:string){
  if(
    this.currentTime.h*60 + this.currentTime.m
    < this.giveTimeInMin(fromTime)
  ){
    return true;
  }else{
    return false;
  }
  }

  whereIsCurrentTimeRelativeTo(fromTime:string,toTime:string):'before'|'after'|'between'|null {
    if(this.isCurrentTimeBetween(fromTime,toTime)){
    return 'between'
    } else if (this.isCurrentTimeAfter(toTime)){
    return 'after'
    } else if (this.isCurrentTimeBefore(fromTime)){
    return 'before'
    } else {
      console.log('error');
      return null
    }
  }

  giveStartAndEndofDayUnixUtc(){
    const now = new Date();
    const timeZone = 'UTC';
    const start = getUnixTime(startOfDay(utcToZonedTime(now, timeZone)));
    const end = getUnixTime(endOfDay(utcToZonedTime(now, timeZone)));
    return { start, end };
  }

  isDayOver(now:number){
    let {end} = this.giveStartAndEndofDayUnixUtc();
    return now>end?true:false;
  }


}
