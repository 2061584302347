import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FIRESTORE_ROOT_COLLECTIONS } from 'src/app/models/enums';
import { CampaignLoyaltyProgramme, IndefiniteLoyaltyProgramme, Outlet, Puck, ScanType, Shop, StampSession, WalletLoyaltyCard } from 'src/app/models/interfaces';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { ConfettiService } from 'src/app/services/confetti.service';
import { DatabaseService } from 'src/app/services/database.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { PreferencesService } from 'src/app/services/preferences.service';
import { PushNotificationsService } from 'src/app/services/pushnotifications.service';
import { ScanService } from 'src/app/services/scan.service';
import { StampSessionsService } from 'src/app/services/stamp-sessions.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';

@Component({
  selector: 'app-loyalty-card-preview',
  templateUrl: './loyalty-card-preview.component.html',
  styleUrls: ['./loyalty-card-preview.component.scss'],
})
export class LoyaltyCardPreviewComponent  implements OnInit,OnDestroy{
  @Input() deal:CampaignLoyaltyProgramme | IndefiniteLoyaltyProgramme
  @Input() outlet:Outlet
  @Input() loyaltyCard:WalletLoyaltyCard
  @Input() today
  @Input() mode:'previewOnly'|'previewWithScan'|'previewWithSession'='previewOnly'
  cardHeight = '60vh'
  private userLoyaltyCardsSubscription:Subscription;
  showScanInstructions = false;
  loading = true;
  stampCount = null;
  private sessionUnsub:Unsubscribe = undefined
  loyaltyCardUpdateCount = 0
  constructor(
    private modalController:ModalController,
    public assetLinksService:AssetlinksService,
    private loyaltyCardService:LoyaltyCardService,
    private pushNotificationsService:PushNotificationsService,
    public scanService:ScanService,
    private stampSessionsService:StampSessionsService,
    public userService:YumdealzUserService,
    private preferencesService:PreferencesService,
    private confettiService:ConfettiService,
    private firebaseService: FirebaseService,
  ) { }

  async ngOnInit() {
    if(this.mode == 'previewWithSession'){
      try {
        const fb = this.firebaseService.getDb();
        this.sessionUnsub = onSnapshot(doc(fb,`${FIRESTORE_ROOT_COLLECTIONS.STAMP_SESSIONS}/${this.loyaltyCard.participant_id}`),async (snap)=>{
          if(!snap.exists()){
            this.sessionUnsub();
            await this.return();
          }
          const sesh = snap.data() as StampSession
          if(sesh.card_id != this.loyaltyCard.id){
            this.sessionUnsub();
            await this.return();
          }
        },
        async (error)=>{
          console.log(error);
          this.sessionUnsub();
          await this.return();
        })
      } catch (error) {
        console.log(error);
        await this.return();
      }
    }
    this.setupVoucherAndLoyaltyCardsSubscriptions();
  }

  ngOnDestroy(): void {
    this.userLoyaltyCardsSubscription.unsubscribe();
    if(this.mode == 'previewWithSession' && this.sessionUnsub){
      this.sessionUnsub();
    }
  }

  // sometimes when the app was left in background multiple sessions could have occurred.
  // when this happens the theory is that there could be a missmatch between the loyaltyCard id and the card id in the latest session
  // so we can possibly fetch the correct card based on the session
  private fetchSessionLoyaltyCard(){
    //todo
  }

  private async tryToEndStampSession(){
    try {
      await this.stampSessionsService.endStampSession();
    } catch (error) {
      console.log(error);
    }
  }

  async return() {
    if(this.mode == 'previewWithSession'){
      this.tryToEndStampSession();
    }
    await this.modalController.dismiss();
  }

  private reactToCardUpdates(){
    if(this.mode != 'previewWithSession') return;
    this.loyaltyCardUpdateCount = this.loyaltyCardUpdateCount + 1;
    if(this.loyaltyCardUpdateCount > 1 && this.loyaltyCard.stickers_count !== this.stampCount){
      this.showGetStampAlert();
    }
  }


  private async askNotificationPermissions(shopId:string) {
    const notificationsEnabled = await this.pushNotificationsService.askForPermissions(false);
    if (notificationsEnabled) {
      try {
       await this.pushNotificationsService.subscribeToShopTopic(shopId);
      } catch (error) {
        console.log(error);
      }
    }
  }

  private async showGetStampAlert() {
    try {
      const stampElement = document.getElementById('nextStampSlot_0')
      let stamp = null;
      let busrtPos = null;
      if (stampElement) {
        stamp = document.getElementById('nextStampSlot_0').getBoundingClientRect();
        let busrtPos = {
          x: (stamp.x / window.innerWidth) * 1,
          y: (stamp.y / window.innerHeight) * 1
        }
        console.log(busrtPos);
      }

      this.confettiService.confettiCannonBurst(4, 1, busrtPos);
      //this.confettiService.fireWorksWithAudio()
    } catch (error) {
      console.log(':( no confetti', error);
    }
  }

  private setupVoucherAndLoyaltyCardsSubscriptions(){
    if(!this.userLoyaltyCardsSubscription || this.userLoyaltyCardsSubscription.closed){
      this.userLoyaltyCardsSubscription = this.loyaltyCardService.userLoyaltyCards$.subscribe(async(loyaltyCards)=>{
        this.loading=false;

        const newLoyaltyCard = loyaltyCards.find(card=>{return card.id == this.loyaltyCard.id})

        if(newLoyaltyCard){
       //   await this.askNotificationPermissions(this.loyaltyCard.restaurantId);
        }

        this.loyaltyCard = newLoyaltyCard;

        if(!this.loyaltyCard){
          if(this.mode =='previewWithSession'){
            this.scanService.celebrateVoucherReceived();
          }
          await this.return();
        }
        this.reactToCardUpdates();
        this.stampCount == this.loyaltyCard.stickers_count;
      })
    }
  }

  async scan(){
    if(this.mode != 'previewWithScan') return;
    this.loading = true;
    const scanningSubscription = this.scanService.isScanning$.subscribe((bool)=>{
      this.showScanInstructions=bool;
    })
    let scanType:ScanType
    try {
      scanType = await this.preferencesService.retrieveScanType();
    } catch (error) {
      console.log(error);
    }
    if(!scanType){
      this.scanService.isNfcSupported ? scanType = 'NFC': scanType ='QR';
    }
    const puckId = await this.scanService.scanLoyaltyCard(this.loyaltyCard,scanType,this.outlet);
    if(puckId){
      await this.scanService.letUserGetStamp(this.loyaltyCard,this.deal,this.outlet,puckId,scanType);
    }
    this.loading = false;
    scanningSubscription.unsubscribe()
  }
}
